











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
	name: "fade-render-transition"
})
export default class FadeRenderTransition extends Vue {

	@Prop({ type: Number, default: 300})
	duration: number = 300;

	@Prop({ type: Number, default: 0})
	delay: number = 0;

	beforeAppear(el: HTMLElement) {
		el.style.opacity = "0";
	}

	onAppear(el: HTMLElement) {
		el.style.transform = "translate3d(0, -60px, 0)";
		el.style.transition = `all ${this.duration / 1000}s ease-in`;
		if (this.delay > 0) {
			el.style.transition = `${el.style.transition} ${this.delay / 1000}s`;
		}
	}

	afterAppear(el: HTMLElement) {
		el.style.opacity = "1";
		el.style.transform = "translate3d(0, 0, 0)";
	}
}
