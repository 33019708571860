
/**
 * Documented at: https://www.npmjs.com/package/vuejs-datepicker
 */
export class DatepickerState {
	date!: Date;
	disabledDates!: DisabledDates;
	highlighted!: HighlightedDates;
}

export class DisabledDates {
	to!: Date;
	from!: Date;
	days!: Array<number>;
	daysOfMonth!: Array<number>;
	dates!: Array<Date>;
	ranges!: Array<{ from: Date, to: Date }>;
	customPredictor!: (value: Date) => boolean;
}

export class HighlightedDates {
	to!: Date;
	from!: Date;
	days!: Array<number>;
	daysOfMonth!: Array<number>;
	dates!: Array<Date>;
	customPredictor!: (value: Date) => boolean;
}