import { render, staticRenderFns } from "./GameBlockList.vue?vue&type=template&id=1c93865c&scoped=true&"
import script from "./GameBlockList.vue?vue&type=script&lang=ts&"
export * from "./GameBlockList.vue?vue&type=script&lang=ts&"
import style0 from "./GameBlockList.vue?vue&type=style&index=0&id=1c93865c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c93865c",
  null
  
)

export default component.exports