









































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { IGamePlan as GamePlan, IGame as Game, GameBlock, BlockId } from '@/services/api';


@Component({
	name: 'GamePlanList',
	components: {
		Card
	}
})
export default class GamePlanList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: true
		},
		{
			key: "blockIds",
			label: this.localize("Blocks"),
			formatter: this.blockIdsFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private blockIdsFormatter(value: Array<BlockId>, key: string, item: GamePlan): string {
		if(!value || !Array.isArray(value)) return this.localize("None");
		return value.length + "";
	}

}
