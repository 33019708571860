












































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError, ColorUtilities, TimeSpan } from '@/utilities';
import { LogLevel, IBlock } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { ISheetType as SheetType, IGridType as GridType, IGame as Game, GameBlock, LinkedRoomBlock, BlockType, LoopingGamesBlock } from '@/services/api';

let tinycolor = require("tinycolor2");


@Component({
	name: 'block-picker',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"fg-input": fgInput
	}
})
export default class BlockPicker extends BaseForm {

	protected get loadItem(): (itemId: number) => void {
		return ((itemId: number) => {});	// Prevent loading this.item
	}

	private fields: Array<any> = [
		{
			key: "name",
			label: this.localize("Name"),
			sortable: false
		},
		{
			key: "color",
			label: this.localize("Color"),
			sortable: false
		},
		{
			key: "numberOfGames",
			label: this.localize("Games"),
			formatter: this.numberOfGamesFormatter,
			sortable: false
		},
		{
			key: "duration",
			label: this.localize("Duration"),
			formatter: this.durationFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private numberOfGamesFormatter(value: any, key: string, item: any): string {
		if(item && item.gameIds && Array.isArray(item.gameIds)) return item.gameIds.length + "";
		return "0";
	}

	private durationFormatter(value: Array<number>, key: string, item: GameBlock): string {
		let dur: TimeSpan = new TimeSpan();
		if(item && item.duration) dur = TimeSpan.parse(item.duration);
		return dur.toString("HH:MM:SS");
	}

	private getBlockColor(value: number): string {
		let tColor = tinycolor(ColorUtilities.argbToVueColor(value).rgba);
		return tColor.toHexString();
	}

	/** @method saveItem Saves the the item operated on by this form, then navigates back to the list for this item. */
	protected saveItem() : void{
		this.$emit("addBlock", this.item);
	}

	private pickItem(index: number, item: IBlock): void {
		this.item = item.id;
		this.saveItem();
	}

	private get blockList(): Array<IBlock> {
		let completeList: Array<any> = new Array<any>();
		if(this.gameBlockList && Array.isArray(this.gameBlockList) && this.gameBlockList.length > 0) completeList.push(...this.gameBlockList);
		if(this.linkedRoomBlockList && Array.isArray(this.linkedRoomBlockList) && this.linkedRoomBlockList.length > 0) completeList.push(...this.linkedRoomBlockList);
		if(this.loopingGamesBlockList && Array.isArray(this.loopingGamesBlockList) && this.loopingGamesBlockList.length > 0) completeList.push(...this.loopingGamesBlockList);
		return completeList;
	}

}
