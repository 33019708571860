import { Module, Getter, GetterTree, Mutation, MutationTree, Action, ActionTree, Plugin } from 'vuex';
import { State } from './state';
import Getters from './getters';
import Mutations from './mutations';
import Actions from './actions';

export default class CasheStore implements Module<State, any> {

	public namespaced: boolean = true;

	public state: State;
	public getters: GetterTree<State, any> = Getters;
	public mutations: MutationTree<State> = Mutations;
	public actions: ActionTree<State, any> = Actions;
	public plugins: Plugin<State>[] = [];

	constructor(plugins?: Plugin<State>[]) {
		this.state = new State();
		if(plugins) this.plugins.push(...plugins);
	}

}
