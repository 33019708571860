import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, IPrizePattern, ResponseOfIEnumerableOfPrizePattern, ResponseOfPrizePattern, IResponseOfPrizePattern, PrizePattern, ResponseOfInt32, Response } from './api';


/**
 * @description Service for handling Prize Patterns on the server.
 * @extends BaseWebSocketService
 */
export class PrizePatternService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<IPrizePattern>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfPrizePattern> => {
				return hub.getPrizePatterns();
			})
			.then((result: ResponseOfIEnumerableOfPrizePattern): Array<IPrizePattern> => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "PrizePatternService.getAll() - No PrizePatterns found!");
					return new Array<IPrizePattern>();
				}
				Logger.log(LogLevel.Debug, "PrizePatternService.getAll() - Server Response: " + result.result.length, result.result);
				return result.result;
			});
	}

	public getItem(id: number): Promise<IPrizePattern | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfPrizePattern> => {
				return hub.getPrizePattern(id);
			})
			.then((result: IResponseOfPrizePattern) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "PrizePatternService.getItem(" + id + ") - No PrizePattern with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "PrizePatternService.getItem(" + id + ") - Server Response: ", result.result);
				return result.result;
			});
	}

	public createItem(item: PrizePattern): Promise<number> {
		Logger.log(LogLevel.Debug, "PrizePatternService.createItem(item) -> item:", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createPrizePattern(item);
			})
			.then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "PrizePatternService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "PrizePatternService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: PrizePattern): Promise<void> {
		Logger.log(LogLevel.Debug, "PrizePatternService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updatePrizePattern(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "PrizePatternService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "PrizePatternService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deletePrizePattern(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "PrizePatternService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "PrizePatternService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}
}
