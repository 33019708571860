














import { Vue, Component, Prop } from 'vue-property-decorator';
import * as Chartist from 'chartist';
import Card from './Card.vue';
import { ChartData } from './chartdata';


@Component({
	name: "chart-card",
	components: {
		Card
	}
})
export default class ChartCard extends Vue {
	chartId: string = 'no-id';
	$Chartist: any = null;
	chart: any = null;

	@Prop({ type: String, default: "Line" }) // Line | Pie | Bar
	chartType: string = 'Line';

	@Prop({ type: Object, default: () => {return {}} })
	chartOptions: any;

	@Prop({
		type: Object,
		default: () => {
			return {
				labels: Array,
				series: Array
			}
		}
	})
	chartData: ChartData;

	@Prop([Object, Array])
	responsiveOptions: any | Array<any>;

	async mounted () {
		this.updateChartId();
		//const Chartist = await import('chartist');
		this.$Chartist = Chartist;
		this.initChart();
	}


	/***
	 * Initializes the chart by merging the chart options sent via props and the default chart options
	 */
	initChart() {
		var chartIdQuery = `#${this.chartId}`;
		this.chart = this.$Chartist[this.chartType](chartIdQuery, this.chartData, this.chartOptions, this.responsiveOptions);
		this.$emit('initialized', this.chart);
		if (this.chartType === 'Line') {
			this.animateLineChart();
		}
		if (this.chartType === 'Bar') {
			this.animateBarChart();
		}
	}

	/***
	 * Assigns a random id to the chart
	 */
	updateChartId() {
		const currentTime = new Date().getTime();//.toString();
		const randomInt = this.getRandomInt(0, currentTime);
		this.chartId = `div_${randomInt}`;
	}

	getRandomInt (min: number, max: number) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	animateLineChart() {
		let seq = 0;
		let durations = 500;
		let delays = 80;
		this.chart.on('draw', (data: any) => {
			if (data.type === 'line' || data.type === 'area') {
				data.element.animate({
					d: {
						begin: 600,
						dur: 700,
						from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
						to: data.path.clone().stringify(),
						easing: this.$Chartist.Svg.Easing.easeOutQuint
					}
				});
			} else if (data.type === 'point') {
				seq++;
				data.element.animate({
					opacity: {
						begin: seq * delays,
						dur: durations,
						from: 0,
						to: 1,
						easing: 'ease'
					}
				});
			}
		})
		seq = 0;
	}

	animateBarChart() {
		let seq = 0;
		let durations = 500;
		let delays = 80;
		this.chart.on('draw', (data: any) => {
			if (data.type === 'bar') {
				seq++;
				data.element.animate({
					opacity: {
						begin: seq * delays,
						dur: durations,
						from: 0,
						to: 1,
						easing: 'ease'
					}
				});
			}
		})
	}

}
