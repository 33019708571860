import { Logger } from '@/utilities';
import { LogLevel } from '@/models';


//#region Constants

export const ticksPerMillisecond = 10000;
export const millisecondsPerTick = 1.0 / ticksPerMillisecond;

export const ticksPerSecond = ticksPerMillisecond * 1000;   // 10,000,000
export const secondsPerTick = 1.0 / ticksPerSecond;         // 0.0000001

export const ticksPerMinute = ticksPerSecond * 60;         // 600,000,000
export const minutesPerTick = 1.0 / ticksPerMinute; // 1.6666666666667e-9

export const ticksPerHour = ticksPerMinute * 60;        // 36,000,000,000
export const hoursPerTick = 1.0 / ticksPerHour; // 2.77777777777777778e-11

export const ticksPerDay = ticksPerHour * 24;          // 864,000,000,000
export const daysPerTick = 1.0 / ticksPerDay; // 1.1574074074074074074e-12

export const millisPerSecond = 1000;
export const millisPerMinute = millisPerSecond * 60; //     60,000
export const millisPerHour = millisPerMinute * 60;   //  3,600,000
export const millisPerDay = millisPerHour * 24;      // 86,400,000

export const maxSeconds = Number.MAX_VALUE / ticksPerSecond;
export const minSeconds = Number.MIN_VALUE / ticksPerSecond;

export const maxMilliseconds = Number.MAX_VALUE / ticksPerMillisecond;
export const minMilliseconds = Number.MIN_VALUE / ticksPerMillisecond;

export const ticksPerTenthSecond = ticksPerMillisecond * 100;

//#endregion

export class TimeSpan {
	private readonly _ticks: number;


	constructor(args?: number | [number, number, number] | [number, number, number, number] | [number, number, number, number, number]) {
		if (args === undefined) {
			this._ticks = 0;
		}
		else if (typeof args === 'number') {
			this._ticks = args;
		} else if (args.length === 3) {
			const [hours, minutes, seconds] = args;
			this._ticks = (hours * 3600 + minutes * 60 + seconds) * ticksPerSecond
		} else if (args.length === 4) {
			const [days, hours, minutes, seconds] = args;
			this._ticks = (days * 3600 * 24 + hours * 3600 + minutes * 60 + seconds) * ticksPerSecond;
		} else {
			const [days, hours, minutes, seconds, milliseconds] = args;
			this._ticks = (days * 3600 * 24 + hours * 3600 + minutes * 60 + seconds) * ticksPerSecond + milliseconds * ticksPerMillisecond;
		}
	}

	public static fromDays(value: number): TimeSpan {
		return new TimeSpan(value * ticksPerDay);
	}

	public static fromHours(value: number): TimeSpan {
		return new TimeSpan(value * ticksPerHour);
	}

	public static fromMilliseconds(value: number): TimeSpan {
		return new TimeSpan(value * ticksPerMillisecond);
	}

	public static fromMinutes(value: number): TimeSpan {
		return new TimeSpan(value * ticksPerMinute);
	}

	public static fromSeconds(value: number): TimeSpan {
		return new TimeSpan(value * ticksPerSecond);
	}

	//#region Properties

	public static readonly zero: TimeSpan = new TimeSpan(0);
	public static readonly maxValue: TimeSpan = new TimeSpan(Number.MAX_VALUE);
	public static readonly minValue: TimeSpan = new TimeSpan(Number.MIN_VALUE);

	public get ticks(): number {
		return this._ticks;
	}
	public get days(): number {
		return Math.floor(this._ticks / ticksPerDay);
	}
	public get hours(): number {
		return Math.floor((this._ticks / ticksPerHour) % 24);
	}
	public get milliseconds(): number {
		return Math.floor((this._ticks / ticksPerMillisecond) % 1000);
	}
	public get minutes(): number {
		return Math.floor((this._ticks / ticksPerMinute) % 60);
	}
	public get seconds(): number {
		return Math.floor((this._ticks / ticksPerSecond) % 60);
	}

	public get totalDays(): number {
		return this._ticks * daysPerTick;
	}
	public get totalHours(): number {
		return this._ticks * hoursPerTick;
	}
	public get totalMilliseconds(): number {
		return this._ticks * millisecondsPerTick;
	}
	public get totalMinutes(): number {
		return this._ticks * minutesPerTick;
	}
	public get totalSeconds(): number {
		return this._ticks * secondsPerTick;
	}

	//#endregion

	//#region Methods

	/**
	 * Parses a string and returns it as a TimeSpan
	 * @param value string value to be parsed
	 * @param format string defining the value format DD:HH:MM:ss.fff (). Default HH:MM:ss
	 */
	public static parse(value: string, format: string = "HH:MM:SS", ignoreFormatDifference: boolean = true): TimeSpan {
		let parsed: ParseResult = TimeSpan.tryParse(value, format, ignoreFormatDifference);
		if (!parsed) throw new Error(`Failed to parse ${value} as a TimeSpan!`);
		if (!parsed.sucess) throw new Error(`Failed to parse ${value} as a TimeSpan! ${parsed.errorMessage}`);
		return parsed.value || new TimeSpan();
	}

	/**
	 * Parses a string and returns it as a TimeSpan
	 * @param value 
	 * @param format string defining the value format DD:HH:MM:ss.fff (). Default HH:MM:SS. Single character will not introduce 0-padding on single digit values.
	 */
	private static tryParse(value: string, format: string = "HH:MM:SS", ignoreFormatDifference: boolean = true): ParseResult {
		let result = new ParseResult();
		if(!value || value.length < 1) {
			result.sucess = false;
			result.errorMessage = "Parameter value (" + value + ") is incorrect!";
			return result;
		}
		let valueParts: Array<string> = (value + "").replace(".",":").split(":");
		if(!valueParts || !Array.isArray(valueParts)) {
			result.sucess = false;
			result.errorMessage = "Parameter value is incorrect!";
			return result;
		}
		let formatParts: Array<string> = (format.toLocaleUpperCase()).replace(".",":").split(":");
		if(!formatParts || !Array.isArray(formatParts)) {
			result.sucess = false;
			result.errorMessage = "Parameter format is incorrect!";
			return result;
		}
		if(!ignoreFormatDifference && valueParts.length != formatParts.length) {
			result.sucess = false;
			result.errorMessage = "The value does not match the format! (value: " + value + ", format: " + format + ")";
			return result;
		}
		let daysIndex = formatParts.findIndex((element: string): boolean => element == "DD");
		if(daysIndex < 0) daysIndex = formatParts.findIndex((element: string): boolean => element == "D");

		let hoursIndex = formatParts.findIndex((element: string): boolean => element == "HH");
		if(hoursIndex < 0) hoursIndex = formatParts.findIndex((element: string): boolean => element == "H");

		let minutesIndex = formatParts.findIndex((element: string): boolean => element == "MM");
		if(minutesIndex < 0) minutesIndex = formatParts.findIndex((element: string): boolean => element == "M");

		let secondsIndex = formatParts.findIndex((element: string): boolean => element == "SS");
		if(secondsIndex < 0) secondsIndex = formatParts.findIndex((element: string): boolean => element == "S");

		let millisecondsIndex = formatParts.findIndex((element: string): boolean => element == "FFF");
		if(millisecondsIndex < 0) millisecondsIndex = formatParts.findIndex((element: string): boolean => element == "FF");
		if(millisecondsIndex < 0) millisecondsIndex = formatParts.findIndex((element: string): boolean => element == "F");
		let days: number = 0;
		let hours: number = 0;
		let minutes: number = 0;
		let seconds: number = 0;
		let milliseconds: number = 0;
		if(daysIndex >= 0 && daysIndex < valueParts.length) days = parseInt(valueParts[daysIndex]);
		if(hoursIndex >= 0 && hoursIndex < valueParts.length) hours = parseInt(valueParts[hoursIndex]);
		if(minutesIndex >= 0 && minutesIndex < valueParts.length) minutes = parseInt(valueParts[minutesIndex]);
		if(secondsIndex >= 0 && secondsIndex < valueParts.length) seconds = parseInt(valueParts[secondsIndex]);
		if(millisecondsIndex >= 0 && millisecondsIndex < valueParts.length) milliseconds = parseFloat("0." + valueParts[millisecondsIndex]) * 1000;
		result.value = new TimeSpan([days, hours, minutes, seconds, milliseconds]);
		return result;
	}

	public add(ts: TimeSpan): TimeSpan {
		return new TimeSpan(this._ticks + ts._ticks);
	}

	public subtract(ts: TimeSpan): TimeSpan {
		return new TimeSpan(this._ticks - ts._ticks);
	}

	public multiply(factor: number): TimeSpan {
		return new TimeSpan(this._ticks * factor);
	}

	public divide(divisor: number | TimeSpan): TimeSpan {
		if (divisor instanceof TimeSpan) {
			return new TimeSpan(this._ticks / divisor._ticks);
		}
		return new TimeSpan(this._ticks / divisor);
	}

	public valueOf(): number {
		return this._ticks;
	}

	public duration(): TimeSpan {
		return new TimeSpan(this._ticks >= 0 ? this._ticks : -this._ticks);
	}

	public negate(): TimeSpan {
		return new TimeSpan(-this._ticks);
	}

	/**
	 * 
	 * @param format string defining the output format DD:HH:MM:ss.fff (). Default HH:MM:SS. Single character will not introduce 0-padding on single digit values.
	 */
	public toString(format: string = "HH:MM:SS"): string {
		const days = this.days >= 10 ? this.days + "" : '0' + this.days;
		const hours = this.hours >= 10 ? this.hours + "" : '0' + this.hours;
		const minutes = this.minutes >= 10 ? this.minutes + "" : '0' + this.minutes;
		const seconds = this.seconds >= 10 ? this.seconds + "" : '0' + this.seconds;
		const milliseconds = this.milliseconds >= 100 ? this.milliseconds + "" : this.milliseconds >= 100 ? '0' + this.milliseconds : '00' + this.milliseconds;
		return format.toLocaleUpperCase()
			.replace("DD", days).replace("D", this.days + "")
			.replace("HH", hours).replace("H", this.hours + "")
			.replace("MM", minutes).replace("M", this.minutes + "")
			.replace("SS", seconds).replace("S", this.seconds + "")
			.replace("FFF", (milliseconds + "").substring(0,3))
			.replace("FF", (milliseconds + "").substring(0,2))
			.replace("F", (milliseconds + "").substring(0,1));
	}

	//#endregion

}

export class ParseResult {
	sucess: boolean = true;
	value: TimeSpan;
	errorMessage: string;
}