









































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { IPrizePatternGroup as PrizePatternGroup, IPrizePattern as PrizePattern } from '@/services/api';


@Component({
	name: 'PrizePatternGroupList',
	components: {
		Card
	}
})
export default class PrizePatternGroupList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: false
		},
		{
			key: "requiredPrizePatternsToTrigger",
			label: this.localize("Required"),
			formatter: this.requiredPrizePatternsToTriggerFormatter,
			sortable: false
		},
		{
			key: "prizePatternIds",
			label: this.localize("Patterns"),
			formatter: this.prizePatternIdsFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private prizePatternIdsFormatter(value: Array<number>, key: string, item: PrizePatternGroup): string {
		return this.prizePatternList
			.filter((pattern: PrizePattern) => value.includes(pattern.id))
			.map((pattern: PrizePattern) => pattern.name)
			.join(',');
	}


	private requiredPrizePatternsToTriggerFormatter(value: number, key: string, item: PrizePatternGroup): string {
		return value + " " + this.localize("of") + " " + item.prizePatternIds.length + " " + this.localize("Patterns");
	}

}
