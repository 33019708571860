import { LogLevel } from '@/models';

//var moment = require('moment');
var consoleShim2 = require("console-shim2");


/** @description Utility for logging all types of events in the application.
 */
export class Logger {

	/** @description Writes the entry to the appropriate logs.
	 * @param {LogLevel} level The severity of the event to be logged.
	 * @param {string} entry The message to put into the log.
	 * @param {any?} object An object relevant for logging, typically used for debug logging to provide more detail.
	 */
	static log(level: LogLevel, entry: string, object?: any): void {
		let todoEnabled: boolean = true;
		let traceEnabled: boolean = true;
		let infoEnabled: boolean = true;
		let debugEnabled: boolean = true;
		let traceTimeStampEnabled: boolean = false;
		// if (ConfigurationService.configuration){
		// 	traceEnabled = ConfigurationService.configuration.logging.traceLoggingEnabled;
		// 	infoEnabled = ConfigurationService.configuration.logging.infoLoggingEnabled;
		// 	debugEnabled = ConfigurationService.configuration.logging.debugLoggingEnabled;
		// 	traceTimeStampEnabled = ConfigurationService.configuration.logging.traceTimeStampEnabled;
		// }
		switch (level) {
			case LogLevel.Fatal: {
				console.error("FATAL: " + entry, object);
				//TODO: report event to logging API.
				break;
			}
			case LogLevel.Error: {
				console.error(entry, object);
				//TODO: report event to logging API.
				break;
			}
			case LogLevel.Warn: {
				console.warn(entry, object);
				//TODO: report event to logging API.
				break;
			}
			case LogLevel.Info: {
				if (!infoEnabled) return;
				console.info("%cINFO: " + entry, "color:blue;padding-left:0px");
				//TODO: report event to logging API.
				break;
			}
			case LogLevel.Debug: {
				if (!debugEnabled) return;
				if (object) {
					consoleShim2.groupCollapsed("DEBUG: " + entry);
					if (console.dir) {
						console.dir(object);
					} else {
						console.log("Data: %c" + JSON.stringify(object), "font-weight:bold;font-style:italic;padding-left:0px");
					}
					consoleShim2.groupEnd();
				} else {
					console.log("%cDEBUG: " + entry, "font-weight:bold;padding-left:0px");
				}
				break;
			}
			case LogLevel.ToDo: {
				if (!todoEnabled) return;
				let message: string = "TODO: ";
				message += entry;
				if (object) {
					console.log("%c" + message, "color:lightgrey;font-style:italic;float:right;padding-left:24px");
					if (console.dir) {
						console.dir(object);
					} else {
						console.log("Data: %c" + JSON.stringify(object), "color:lightgrey;font-style:italic;padding-left:0px");
					}
				} else {
					console.log("%c" + message, "color:lightgrey;font-style:italic;float:right;padding-left:24px");
				}
				break;
			}
			case LogLevel.Trace:
			default: {
				if (!traceEnabled) return;
				let message: string = "TRACE: ";
				//if (traceTimeStampEnabled) message += "(" + moment().format("x") + ") ";
				message += entry;
				if (object) {
					console.log("%c" + message, "color:green;font-style:italic;float:right;padding-left:6px");
					if (console.dir) {
						console.dir(object);
					} else {
						console.log("Data: %c" + JSON.stringify(object), "color:green;font-style:italic;padding-left:0px");
					}
				} else {
					console.log("%c" + message, "color:green;font-style:italic;float:right;padding-left:6px");
				}
				break;
			}
		}

		// axios.post("http://localhost:5000", {logLevel: level, entry: entry, object: object})
		// 	.then(
		// 		(response: AxiosResponse): void => {

		// 		}
		// 	)
		// 	.catch(
		// 		(reason: any): void =>{}
		// 	);

	}

}
