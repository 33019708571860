









































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel, IUser as User } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';


@Component({
	name: 'UserList',
	components: {
		Card
	}
})
export default class UserList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "username",
			label: this.localize("Username"),
			sortable: false
		},
		{
			key: "role",
			label: this.localize("Role"),
			sortable: false
		},
		{
			key: "email",
			label: this.localize("Email"),
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

}
