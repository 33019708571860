import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, IGameBlock, ResponseOfIEnumerableOfGamePlan, ResponseOfGamePlan, IResponseOfGamePlan, GamePlan, ResponseOfInt32, Response, ResponseOfIEnumerableOfGameBlock, IResponseOfGameBlock, ResponseOfGameBlock, GameBlock } from './api';


/**
 * @description Service for handling GameBlocks on the server.
 * @extends BaseWebSocketService
 */
export class GameBlockService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<IGameBlock>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfGameBlock> => {
				return hub.getGameBlocks();
			})
			.then((result: ResponseOfIEnumerableOfGameBlock): Array<IGameBlock> => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "GameBlockService.getAll() - No Game Blocks found!");
					return new Array<IGameBlock>();
				}
				Logger.log(LogLevel.Debug, "GameBlockService.getAll() - Server Response: " + result.result.length, result.result);
				return result.result;
			});
	}

	public getItem(id: number): Promise<IGameBlock | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfGameBlock> => {
				return hub.getGameBlock(id);
			}).then((result: IResponseOfGameBlock) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "GameBlockService.getItem(" + id + ") - No Game Block with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "GameBlockService.getItem(" + id + ") - Server Response: ", result.result);
				return result.result;
			});
	}

	public createItem(item: GameBlock): Promise<number> {
		Logger.log(LogLevel.Debug, "GameBlockService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createGameBlock(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "GameBlockService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "GameBlockService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: GameBlock): Promise<void> {
		Logger.log(LogLevel.Debug, "GameBlockService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updateGameBlock(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "GameBlockService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "GameBlockService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteGameBlock(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "GameBlockService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "GameBlockService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}
}
