import Vue from 'vue';
import * as SignalR from '@aspnet/signalr';
import { AdminHub } from './api';
import { Logger, Authenticator } from '@/utilities';
import { LogLevel } from '@/models';
import { HubConnection } from '@aspnet/signalr';


/**
 * @description Service for handling Products on the server.
 */
export class BaseWebSocketService {

	private adminHub!: AdminHub;
	private adminHubPromise: Promise<AdminHub>;

	public constructor() {
		this.initSocket();
	}

	protected initSocket(): Promise<AdminHub> {
		let token: string = Authenticator.getToken();
		if(!token || token === "") {
			Authenticator.handleUnAuthorized();
			return Promise.reject("Unauthorized!");
		}
		let connection: HubConnection = new SignalR.HubConnectionBuilder()
			.withUrl(
				process.env.VUE_APP_API_URL + "admin",
				{ accessTokenFactory: () => token }	//"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOiIxNTU3NDc0MTYyIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOjIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkFkbWluIiwiaXNzIjoiVmV4dGEuQmluZ28uU2VydmVyIn0.-tclEGuQ7YQjWzt0Xjc3pqouR-1DPJEtAosA1t-fFD0"
			)
			.configureLogging(SignalR.LogLevel.None)	//Disable verbose console logging.
			.build();
		connection.on("messageReceived", (username: string, message: string) => {
			Logger.log(LogLevel.Trace, "BaseWebSocketService.initSocket() - " + username + ": " + message);
		});
		this.adminHubPromise = connection.start()
			.then(() => {
				this.adminHub = new AdminHub(connection);
				return this.adminHub;
			})
			.catch((reason: any) => {
				if(reason && reason.statusCode && reason.statusCode == 401) {
					Logger.log(LogLevel.Warn, "Unauthorized! Not logged in or token expired!", reason.statusCode);
					Authenticator.handleUnAuthorized();
				}
				return this.adminHub;
			});
		return this.adminHubPromise;
	}

	public get AdminHub(): Promise<AdminHub> {
		if(this.adminHub) return Promise.resolve(this.adminHub);
		if(this.adminHubPromise) return this.adminHubPromise;
		return Promise.resolve(this.initSocket());
	}

}
