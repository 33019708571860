






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
	name: "breadcrumb-item"
})
export default class Switch extends Vue {

	@Prop({ type: Boolean, default: false })
	active: boolean;

}
