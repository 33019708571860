















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option, Input } from 'element-ui';
import { Swatches } from 'vue-color';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError, ColorUtilities, TimeSpan } from '@/utilities';
import { LogLevel, VueColor } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import GameList from './GameBlockForm/GameList.vue';
import { IGamePlan, GamePlan, IGame as Game, LoopingGamesBlock } from '@/services/api';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';


@Component({
	name: 'LoopingGamesBlockForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"el-input": Input,
		"fg-input": fgInput,
		GameList,
		'swatches-picker': Swatches,
		VueTimepicker
	}
})
export default class LoopingGamesBlockForm extends BaseForm {
	private gameIds: Array<number> = new Array<number>();	//Reactive property for item property that is not a value type (and are not automatically reactive).
	private color: VueColor = new VueColor();				//Reactive property for item property that is not a value type (and are not automatically reactive).

	public get title(): string {
		let title: string = this.isNew ? "New Looping Games Block" : "Edit Looping Games Block";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get itemReactivePropertyExtractor(): (item: any) => void {
		return ((item: LoopingGamesBlock) => {
			this.gameIds =  item.gameIds;
			this.color =  ColorUtilities.argbToVueColor(item.color);
		});
	}

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			let blockDuration: number = TimeSpan.parse(this.duration).ticks;
			let gamesDuration: number = this.gameList
				.filter(element => this.gameIds.includes(element.id))
				.map(element => TimeSpan.parse(element.maxDuration).ticks)
				.reduce((a, b) => a + b);
			if(blockDuration < gamesDuration) return false;	//TODO: provide feedback!
			return true;
		});
	}

	protected get itemReactivePropertyInserter(): (item: any) => void {
		return ((item: LoopingGamesBlock) => {
			item.gameIds = this.gameIds;
			item.color = ColorUtilities.toArgb(this.color.rgba);
		});
	}

//#endregion

	private get duration(): string {
		let dur: TimeSpan = new TimeSpan();
		if(!this.item || !this.item.duration) return "-- : --";
		dur = TimeSpan.parse(this.item.duration);
		return dur.toString("HH:MM:SS");
	}
	private set duration(value: string) {
		let dur: TimeSpan = TimeSpan.parse(value);
		if(dur.ticks > 0) {
			this.item.duration = dur.toString();
		}else{
			this.item.duration = "00";
		}
	}

	private listChanged(value: Array<number>): void {
		this.gameIds = value;
	}

	private pickColor(value: VueColor): void {
		this.item.color = ColorUtilities.toArgb(value.rgba);
		Logger.log(LogLevel.Trace, "GameForm.pickColor() - Picked: " + JSON.stringify(value));
	}

}
