
























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option, Input } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel, TicketColor } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { IPot, Pot } from '@/services/api';


@Component({
	name: 'PotForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"el-input": Input,
		"fg-input": fgInput
	}
})
export default class PotForm extends BaseForm {

	public get title(): string {
		let title: string = this.isNew ? "New Pot" : "Edit Pot";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			return true;
		});
	}

//#endregion

}
