






import { Vue, Component } from 'vue-property-decorator';
import Card from '@/components/lbd/Cards/Card.vue';

@Component({
  components: {
    Card
  }
})
export default class Overview extends Vue {

}
