





























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel, Matrix } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import GridDesigner from '@/components/pages/forms/GridDesigner.vue';
import { IGridType, GridType, IGridSize, GridSize } from '@/services/api';


@Component({
	name: 'GridTypeForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"fg-input": fgInput,
		GridDesigner
	}
})
export default class GridTypeForm extends BaseForm {
	private gridSize: GridSize = new GridSize();	//Reactive property for item property that is not a value type (and are not automatically reactive).
	private blanksPerRow: number = 0;				//Reactive property for item property that is not a value type (and are not automatically reactive).

	public created() {
		this.item.gridSize = this.gridSize;			// Attach reactive field to initialized item object.
	}

	public get title(): string {
		let title: string = this.isNew ? "New Grid Type" : "Edit Grid Type";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get itemReactivePropertyExtractor(): (item: any) => void {
		return ((item: GridType) => {
			this.gridSize = item.gridSize;
			this.blanksPerRow = item.blanksPerRow;
		});
	}

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			return true;
		});
	}

	protected get itemReactivePropertyInserter(): (item: any) => void {
		return ((item: GridType) => {
			item.gridSize = this.gridSize;
			item.blanksPerRow = this.blanksPerRow;
		});
	}

//#endregion

	private get hasBlanks() {
		if(this.blanksPerRow && this.blanksPerRow > 0) return true;
		return false;
	}
	private set hasBlanks(value: boolean) {
		this.item.freeplayFields = [];
		if(value) {
			if(this.blanksPerRow == undefined || this.blanksPerRow == 0) this.blanksPerRow = 1;
		} else {
			this.blanksPerRow = 0;
		}
		Logger.log(LogLevel.Trace, "GridTypeForm.setHasBlanks() - GridTypeForm.blanksPerRow: " + this.blanksPerRow + ", GridTypeForm.hasBlanks: " + this.hasBlanks);
	}

	protected freeplayTypeChanged(): void {
		if(this.hasBlanks) return;
		this.item.freeplayFields = this.item.freeplayFields.filter((item: number) => item <= this.gridSize.columns * this.gridSize.rows);
	}

	public gridChanged(matrix: Matrix): void {
		this.gridSize.rows = matrix.rows;
		this.gridSize.columns = matrix.columns;
		this.item.gridSize.rows = matrix.rows;
		this.item.gridSize.columns = matrix.columns;
		this.item.freeplayFields = matrix.pattern;
		if(this.hasBlanks) this.item.freeplayFields = null;
	}

}
