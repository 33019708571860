import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, ResponseOfInt32, Response, ResponseOfIEnumerableOfBingoBoosterHall, IResponseOfBingoBoosterHall, ResponseOfBingoBoosterHall, BingoBoosterHall } from './api';


/**
 * @description Service for handling Halls on the server.
 * @extends BaseWebSocketService
 */
export class BingoBoosterHallService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<BingoBoosterHall>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfBingoBoosterHall> => {
				return hub.getBingoBoosterHalls();
			})
			.then((result: ResponseOfIEnumerableOfBingoBoosterHall): Array<BingoBoosterHall> => {
				let returnValue = new Array<BingoBoosterHall>();
				if(!result.result) {
					Logger.log(LogLevel.Debug, "BingoBoosterHallService.getAll() - No BingoBooster Halls found!");
					return returnValue;
				}
				returnValue = result.result.map((element: BingoBoosterHall): BingoBoosterHall => new BingoBoosterHall(element));
				Logger.log(LogLevel.Debug, "BingoBoosterHallService.getAll() - Server Response: " + result.result.length, returnValue);
				return returnValue;
			});
	}

	public getItem(id: number): Promise<BingoBoosterHall | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfBingoBoosterHall> => {
				return hub.getBingoBoosterHall(id);
			}).then((result: IResponseOfBingoBoosterHall) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "BingoBoosterHallService.getItem(" + id + ") - No BingoBooster Hall with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "BingoBoosterHallService.getItem(" + id + ") - Server Response: ", result.result);
				return new BingoBoosterHall(result.result);
			});
	}

	public createItem(item: BingoBoosterHall): Promise<number> {
		Logger.log(LogLevel.Debug, "BingoBoosterHallService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createBingoBoosterHall(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "BingoBoosterHallService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "BingoBoosterHallService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: BingoBoosterHall): Promise<void> {
		Logger.log(LogLevel.Debug, "BingoBoosterHallService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updateBingoBoosterHall(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "BingoBoosterHallService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "BingoBoosterHallService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteBingoBoosterHall(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "BingoBoosterHallService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "BingoBoosterHallService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}

}
