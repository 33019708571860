


















import { Vue, Component, Prop, Model } from 'vue-property-decorator';
import { Color } from './color';

@Component({
	name: "l-switch"
})
export default class Switch extends Vue {

	@Prop(String)
	onText: string;

	@Prop(String)
	offText: string;

	@Prop(Color)
	color: Color = new Color("blue");

	@Model('input', { type: [Boolean, Array] })
	value: boolean | Array<any>;

	get switchClass() {
		let base = 'bootstrap-switch-'
		let state = this.model ? 'on' : 'off'
		let classes = base + state
		if (this.color) {
			classes = `${classes} ${base}${this.color}`;
		}
		return classes;
	}

	get model() {
		return this.value;
	}
	set model(value: boolean | Array<any>) {
		this.$emit('input', value);
	}

	triggerToggle() {
		this.model = !this.model;
	}

}

//Vue.component('l-switch', Switch);
