import { Language } from '@/models';

export let languages = new Array<Language>();
languages.push({ languageCode: "ab", name: "Abkhazian" });
languages.push({ languageCode: "aa", name: "Afar" });
languages.push({ languageCode: "af", name: "Afrikaans" });
languages.push({ languageCode: "ak", name: "Akan" });
languages.push({ languageCode: "sq", name: "Albanian" });
languages.push({ languageCode: "am", name: "Amharic" });
languages.push({ languageCode: "ar", name: "Arabic" });
languages.push({ languageCode: "an", name: "Aragonese" });
languages.push({ languageCode: "hy", name: "Armenian" });
languages.push({ languageCode: "as", name: "Assamese" });
languages.push({ languageCode: "av", name: "Avaric" });
languages.push({ languageCode: "ae", name: "Avestan" });
languages.push({ languageCode: "ay", name: "Aymara" });
languages.push({ languageCode: "az", name: "Azerbaijani" });
languages.push({ languageCode: "bm", name: "Bambara" });
languages.push({ languageCode: "ba", name: "Bashkir" });
languages.push({ languageCode: "eu", name: "Basque" });
languages.push({ languageCode: "be", name: "Belarusian" });
languages.push({ languageCode: "bn", name: "Bengali (Bangla)" });
languages.push({ languageCode: "bh", name: "Bihari" });
languages.push({ languageCode: "bi", name: "Bislama" });
languages.push({ languageCode: "bs", name: "Bosnian" });
languages.push({ languageCode: "br", name: "Breton" });
languages.push({ languageCode: "bg", name: "Bulgarian" });
languages.push({ languageCode: "my", name: "Burmese" });
languages.push({ languageCode: "ca", name: "Catalan" });
languages.push({ languageCode: "ch", name: "Chamorro" });
languages.push({ languageCode: "ce", name: "Chechen" });
languages.push({ languageCode: "ny", name: "Chichewa, Chewa, Nyanja" });
languages.push({ languageCode: "zh", name: "Chinese" });
languages.push({ languageCode: "cv", name: "Chuvash" });
languages.push({ languageCode: "kw", name: "Cornish" });
languages.push({ languageCode: "co", name: "Corsican" });
languages.push({ languageCode: "cr", name: "Cree" });
languages.push({ languageCode: "hr", name: "Croatian" });
languages.push({ languageCode: "cs", name: "Czech" });
languages.push({ languageCode: "da", name: "Danish" });
languages.push({ languageCode: "dv", name: "Divehi, Dhivehi, Maldivian" });
languages.push({ languageCode: "nl", name: "Dutch" });
languages.push({ languageCode: "dz", name: "Dzongkha" });
languages.push({ languageCode: "en", name: "English" });
languages.push({ languageCode: "eo", name: "Esperanto" });
languages.push({ languageCode: "et", name: "Estonian" });
languages.push({ languageCode: "ee", name: "Ewe" });
languages.push({ languageCode: "fo", name: "Faroese" });
languages.push({ languageCode: "fj", name: "Fijian" });
languages.push({ languageCode: "fi", name: "Finnish" });
languages.push({ languageCode: "fr", name: "French" });
languages.push({ languageCode: "ff", name: "Fula, Fulah, Pulaar, Pular" });
languages.push({ languageCode: "gl", name: "Galician" });
languages.push({ languageCode: "gd", name: "Gaelic (Scottish)" });
languages.push({ languageCode: "gv", name: "Gaelic (Manx)" });
languages.push({ languageCode: "ka", name: "Georgian" });
languages.push({ languageCode: "de", name: "German" });
languages.push({ languageCode: "el", name: "Greek" });
languages.push({ languageCode: "kl", name: "Greenlandic" });
languages.push({ languageCode: "gn", name: "Guarani" });
languages.push({ languageCode: "gu", name: "Gujarati" });
languages.push({ languageCode: "ht", name: "Haitian Creole" });
languages.push({ languageCode: "ha", name: "Hausa" });
languages.push({ languageCode: "he", name: "Hebrew" });
languages.push({ languageCode: "hz", name: "Herero" });
languages.push({ languageCode: "hi", name: "Hindi" });
languages.push({ languageCode: "ho", name: "Hiri Motu" });
languages.push({ languageCode: "hu", name: "Hungarian" });
languages.push({ languageCode: "is", name: "Icelandic" });
languages.push({ languageCode: "io", name: "Ido" });
languages.push({ languageCode: "ig", name: "Igbo" });
languages.push({ languageCode: "in", name: "Indonesian" });
languages.push({ languageCode: "ia", name: "Interlingua" });
languages.push({ languageCode: "ie", name: "Interlingue" });
languages.push({ languageCode: "iu", name: "Inuktitut" });
languages.push({ languageCode: "ik", name: "Inupiak" });
languages.push({ languageCode: "ga", name: "Irish" });
languages.push({ languageCode: "it", name: "Italian" });
languages.push({ languageCode: "ja", name: "Japanese" });
languages.push({ languageCode: "jv", name: "Javanese" });
languages.push({ languageCode: "kl", name: "Kalaallisut, Greenlandic" });
languages.push({ languageCode: "kn", name: "Kannada" });
languages.push({ languageCode: "kr", name: "Kanuri" });
languages.push({ languageCode: "ks", name: "Kashmiri" });
languages.push({ languageCode: "kk", name: "Kazakh" });
languages.push({ languageCode: "km", name: "Khmer" });
languages.push({ languageCode: "ki", name: "Kikuyu" });
languages.push({ languageCode: "rw", name: "Kinyarwanda (Rwanda)" });
languages.push({ languageCode: "rn", name: "Kirundi" });
languages.push({ languageCode: "ky", name: "Kyrgyz" });
languages.push({ languageCode: "kv", name: "Komi" });
languages.push({ languageCode: "kg", name: "Kongo" });
languages.push({ languageCode: "ko", name: "Korean" });
languages.push({ languageCode: "ku", name: "Kurdish" });
languages.push({ languageCode: "kj", name: "Kwanyama" });
languages.push({ languageCode: "lo", name: "Lao" });
languages.push({ languageCode: "la", name: "Latin" });
languages.push({ languageCode: "lv", name: "Latvian (Lettish)" });
languages.push({ languageCode: "li", name: "Limburgish ( Limburger)" });
languages.push({ languageCode: "ln", name: "Lingala" });
languages.push({ languageCode: "lt", name: "Lithuanian" });
languages.push({ languageCode: "lu", name: "Luga-Katanga" });
languages.push({ languageCode: "lg", name: "Luganda, Ganda" });
languages.push({ languageCode: "lb", name: "Luxembourgish" });
languages.push({ languageCode: "gv", name: "Manx" });
languages.push({ languageCode: "mk", name: "Macedonian" });
languages.push({ languageCode: "mg", name: "Malagasy" });
languages.push({ languageCode: "ms", name: "Malay" });
languages.push({ languageCode: "ml", name: "Malayalam" });
languages.push({ languageCode: "mt", name: "Maltese" });
languages.push({ languageCode: "mi", name: "Maori" });
languages.push({ languageCode: "mr", name: "Marathi" });
languages.push({ languageCode: "mh", name: "Marshallese" });
languages.push({ languageCode: "mo", name: "Moldavian" });
languages.push({ languageCode: "mn", name: "Mongolian" });
languages.push({ languageCode: "na", name: "Nauru" });
languages.push({ languageCode: "nv", name: "Navajo" });
languages.push({ languageCode: "ng", name: "Ndonga" });
languages.push({ languageCode: "nd", name: "Northern Ndebele" });
languages.push({ languageCode: "ne", name: "Nepali" });
languages.push({ languageCode: "no", name: "Norwegian" });
languages.push({ languageCode: "nb", name: "Norwegian bokmål" });
languages.push({ languageCode: "nn", name: "Norwegian nynorsk" });
languages.push({ languageCode: "ii", name: "Nuosu" });
languages.push({ languageCode: "oc", name: "Occitan" });
languages.push({ languageCode: "oj", name: "Ojibwe" });
languages.push({ languageCode: "cu", name: "Old Church Slavonic, Old Bulgarian" });
languages.push({ languageCode: "or", name: "Oriya" });
languages.push({ languageCode: "om", name: "Oromo (Afaan Oromo)" });
languages.push({ languageCode: "os", name: "Ossetian" });
languages.push({ languageCode: "pi", name: "Pāli" });
languages.push({ languageCode: "ps", name: "Pashto, Pushto" });
languages.push({ languageCode: "fa", name: "Persian (Farsi)" });
languages.push({ languageCode: "pl", name: "Polish" });
languages.push({ languageCode: "pt", name: "Portuguese" });
languages.push({ languageCode: "pa", name: "Punjabi (Eastern)" });
languages.push({ languageCode: "qu", name: "Quechua" });
languages.push({ languageCode: "rm", name: "Romansh" });
languages.push({ languageCode: "ro", name: "Romanian" });
languages.push({ languageCode: "ru", name: "Russian" });
languages.push({ languageCode: "se", name: "Sami" });
languages.push({ languageCode: "sm", name: "Samoan" });
languages.push({ languageCode: "sg", name: "Sango" });
languages.push({ languageCode: "sa", name: "Sanskrit" });
languages.push({ languageCode: "sr", name: "Serbian" });
languages.push({ languageCode: "sh", name: "Serbo-Croatian" });
languages.push({ languageCode: "st", name: "Sesotho" });
languages.push({ languageCode: "tn", name: "Setswana" });
languages.push({ languageCode: "sn", name: "Shona" });
languages.push({ languageCode: "ii", name: "Sichuan Yi" });
languages.push({ languageCode: "sd", name: "Sindhi" });
languages.push({ languageCode: "si", name: "Sinhalese" });
languages.push({ languageCode: "ss", name: "Siswati" });
languages.push({ languageCode: "sk", name: "Slovak" });
languages.push({ languageCode: "sl", name: "Slovenian" });
languages.push({ languageCode: "so", name: "Somali" });
languages.push({ languageCode: "nr", name: "Southern Ndebele" });
languages.push({ languageCode: "es", name: "Spanish" });
languages.push({ languageCode: "su", name: "Sundanese" });
languages.push({ languageCode: "sw", name: "Swahili (Kiswahili)" });
languages.push({ languageCode: "ss", name: "Swati" });
languages.push({ languageCode: "sv", name: "Swedish" });
languages.push({ languageCode: "tl", name: "Tagalog" });
languages.push({ languageCode: "ty", name: "Tahitian" });
languages.push({ languageCode: "tg", name: "Tajik" });
languages.push({ languageCode: "ta", name: "Tamil" });
languages.push({ languageCode: "tt", name: "Tatar" });
languages.push({ languageCode: "te", name: "Telugu" });
languages.push({ languageCode: "th", name: "Thai" });
languages.push({ languageCode: "bo", name: "Tibetan" });
languages.push({ languageCode: "ti", name: "Tigrinya" });
languages.push({ languageCode: "to", name: "Tonga" });
languages.push({ languageCode: "ts", name: "Tsonga" });
languages.push({ languageCode: "tr", name: "Turkish" });
languages.push({ languageCode: "tk", name: "Turkmen" });
languages.push({ languageCode: "tw", name: "Twi" });
languages.push({ languageCode: "ug", name: "Uyghur" });
languages.push({ languageCode: "uk", name: "Ukrainian" });
languages.push({ languageCode: "ur", name: "Urdu" });
languages.push({ languageCode: "uz", name: "Uzbek" });
languages.push({ languageCode: "ve", name: "Venda" });
languages.push({ languageCode: "vi", name: "Vietnamese" });
languages.push({ languageCode: "vo", name: "Volapük" });
languages.push({ languageCode: "wa", name: "Wallon" });
languages.push({ languageCode: "cy", name: "Welsh" });
languages.push({ languageCode: "wo", name: "Wolof" });
languages.push({ languageCode: "fy", name: "Western Frisian" });
languages.push({ languageCode: "xh", name: "Xhosa" });
languages.push({ languageCode: "yi", name: "Yiddish" });
languages.push({ languageCode: "yo", name: "Yoruba" });
languages.push({ languageCode: "za", name: "Zhuang, Chuang" });
languages.push({ languageCode: "zu", name: "Zulu" });
