import { BaseWebSocketService } from './base-websocket.service';
import { LogLevel, IUser, User } from '@/models';
import { Logger } from '@/utilities';
import { AdminHub, ResponseOfIEnumerableOfAccount, ResponseOfAccount, IResponseOfAccount, Account, ResponseOfInt32, Response } from './api';


/**
 * @description Service for handling Users on the server.
 * @extends BaseWebSocketService
 */
export class UserService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<IUser>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfAccount> => {
				return hub.getAccounts();
			})
			.then((result: ResponseOfIEnumerableOfAccount): Array<IUser> => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "UserService.getAll() - No Users found!");
					return new Array<IUser>();
				}
				Logger.log(LogLevel.Debug, "UserService.getAll() - Server Response: " + result.result.length, result.result);
				return result.result;
			});
	}

	public getItem(id: number): Promise<IUser | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfAccount> => {
				return hub.getAccount(id);
			}).then((result: IResponseOfAccount) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "UserService.getItem(" + id + ") - No User with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "UserService.getItem(" + id + ") - Server Response: ", result.result);
				return result.result;
			});
	}

	public createItem(item: User): Promise<number> {
		Logger.log(LogLevel.Debug, "UserService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfAccount> => {
				return hub.createAccount(item);
			}).then((result: ResponseOfAccount) => {
				if(!result) {
					Logger.log(LogLevel.Error, "UserService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "UserService.createItem(item) - Server Response: " + result.result, result);
				return result.result.id;
			});
	}

	public updateItem(item: User): Promise<void> {
		Logger.log(LogLevel.Debug, "UserService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updateAccount(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "UserService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "UserService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteAccount(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "UserService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "UserService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}
}
