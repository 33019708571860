

export class VueColor {
	public hsl!: hslColor;
	public hex!: string;
	public hex8!: string;
	public rgba!: rgbaColor;
	public hsv!: hsvColor;
	public oldHue!: number
	public source!: string;
	public a!: number;
}

export class rgbaColor {
	public r: number = 0;
	public g: number = 0;
	public b: number = 0;
	public a: string = "";
}

export class hslColor{
	public h: number
	public s: number;
	public l: number
	public a: number
}

export class hsvColor{
	public h: number
	public s: number;
	public v: number
	public a: number
}
