










































































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError, ColorUtilities, TimeSpan } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { IGame as Game, IPrizePatternGroup as PrizePatternGroup, ISheetType as SheetType, IGridType as GridType, IGridSize as GridSize } from '@/services/api';

let tinycolor = require("tinycolor2");


@Component({
	name: 'GameList',
	components: {
		Card
	}
})
export default class GameList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: true
		},
		{
			key: "color",
			label: this.localize("Color"),
			sortable: false
		},
		{
			key: "type",
			label: this.localize("Type"),
			formatter: this.typeFormatter,
			sortable: false
		},
		{
			key: "sheetTypeIds",
			label: this.localize("Sheets"),
			sortable: false
		},
		//TODO: list prizes here??
		// {
		// 	key: "prizeIds",
		// 	label: this.localize("Prizes"),
		// 	formatter: this.prizeIdsFormatter,
		// 	sortable: false
		// },
		{
			key: "showLetters",
			label: this.localize("Show Letters"),
			sortable: false
		},
		{
			key: "drawDelay",
			label: this.localize("Draw Delay"),
			formatter: this.drawDelayFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	// private prizeIdsFormatter(value: Array<number>, key: string, item: Game): string {
	// 	return this.prizeList.filter((item: Prize) => value.includes(item.id)).map((item: Prize): string => item.name).join(', ');
	// }

	private drawDelayFormatter(value: string, key: string, item: Game): string {
		let dur: TimeSpan = new TimeSpan();
		if(item && item.drawInfo && item.drawInfo.normalDrawDelay) dur = TimeSpan.parse(item.drawInfo.normalDrawDelay, "HH:MM:SS.ff");
		return dur.toString("S.f");
	}

	private typeFormatter(value: any, key: string, item: Game): string {
		let sheet: SheetType = this.sheetTypeList.find((elemenmt: SheetType) => elemenmt.id == item.sheetTypeIds[0]);
		if(!sheet) return "";
		let type: GridType = this.gridTypeList.find((element: GridType) => element.id == sheet.gridTypeId);
		if(!type || !type.gridSize) return "";
		return this.numberOfBallsEnum[type.gridSize.numberOfBalls]
	}

	private sheetTypeName(value: number): string {
		let type: SheetType = this.sheetTypeList.find((item: SheetType) => item.id == value);
		if(!type) return "";
		return type.gridsPerSheet + ' ' + this.localize(type.gridsPerSheet > 1 ? 'grids': 'grid') + ' ' + '(' + this.getGridTypeName(type.gridTypeId) + ')'+ ' ' + this.ticketColorEnum[type.color] + ' @ ' + type.price + ',-';
	}

	private getSortedSheetList(value: Array<number>) : Array<SheetType> {
		 let list: Array<SheetType> = this.sheetTypeList
			.filter(
				(item: SheetType) => 
				value.includes(item.id)
			)
			.sort(
				(a: SheetType, b: SheetType) =>  
				a.gridsPerSheet < b.gridsPerSheet ? -1 : 1
			);
		return list;
	}

	private getNumberOfRows(sheet: SheetType) : number {
		let type: GridType = this.gridTypeList.find((item: GridType) => item.id == sheet.gridTypeId);
		if(!type || !type.gridSize) return 0;
		return type.gridSize.rows;
	}

	private getNumberOfColumns(sheet: SheetType) : number {
		let type: GridType = this.gridTypeList.find((item: GridType) => item.id == sheet.gridTypeId);
		if(!type || !type.gridSize) return 0;
		return type.gridSize.columns;
	}

	private getColorCss(sheet: SheetType): string {
		return ColorUtilities.colorNumber2cssRgbaString(sheet.color);
	}

	public getGridTypeName(id: number): string {
		let type: GridType = this.gridTypeList.find((item: GridType) => item.id == id);
		if(!type || !type.gridSize) return id + "";
		return type.gridSize.rows + " x " + type.gridSize.columns;
	}

	private getGameColor(value: number): string {
		let tColor = tinycolor(ColorUtilities.argbToVueColor(value).rgba);
		return tColor.toHexString();
	}

	private formatTime(timeSpan: string): string {
		return timeSpan.substring(0, 5);
	}

}
