












































import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';
import AuthLayout from '@/components/layout/AuthLayout.vue';
import { Logger, Authenticator } from '@/utilities';
import { LogLevel, Login } from '@/models';
import { Checkbox as LCheckbox, FadeRenderTransition } from '@/components/lbd/index';
import Card from '@/components/lbd/Cards/Card.vue';


@Component({
	components: {
		Card,
		LCheckbox,
		FadeRenderTransition,
		AuthLayout
	}
})
export default class LoginComponent extends BaseComponent {
	private model: Login = new Login();

	public beforeDestroy() {
		this.closeMenu();
	}

	private logIn(email: string, password: string) {
		Authenticator.logIn(email, password);
	}

	private toggleNavbar() {
		document.body.classList.toggle("nav-open");
	}

	private closeMenu() {
		document.body.classList.remove("nav-open");
		document.body.classList.remove("off-canvas-sidebar");
	}

}
