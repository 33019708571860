import { DirectiveOptions, CreateElement, VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import { icon, Icon } from '@fortawesome/fontawesome-svg-core';
import { VBTooltip, BTooltip } from 'bootstrap-vue';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';


/** @description Directive for adding help icon on form fields. Uses BootstrapVue Tooltip and passes value, expression, arguments and modifiers to that directive. Defines extra argument for long and extra long tooltips (long-tooltip | very-long-tooltip). Defines extra modifier nosplit to prevent content to split onto newline at period (.)
 *  @example <label v-bingo-help:long-tooltip.hover.right="some long tooltip text.">Some label</label>. Note that tooltip text will automatically be evaluated to ensure wide and extra wide tooltip buble, but this may be overridden by this argument manually. */
class HelpDirective implements DirectiveOptions {

	/** @description called only once, when the directive is first bound to the element. This is where you can do one-time setup work. */
	public bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode): void {
		if(!binding.value) return;
		if(HelpDirective.hasHelpIcon(el)) return;
		let helpParent: HTMLElement = HelpDirective.getEnclosingNode(el);
		let help: HTMLElement = HelpDirective.createHelpIcon(binding, vnode, oldVnode);
		helpParent.appendChild(document.createTextNode(" "));
		helpParent.appendChild(help);
		HelpDirective.bindTooltip(help, binding, vnode, oldVnode);
	}

	/** @description called when the bound element has been inserted into its parent node (this only guarantees parent node presence, not necessarily in-document). */
	public inserted(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode): void {}

	/** @description called after the containing component’s VNode has updated, but possibly before its children have updated. The directive’s value may or may not have changed, but you can skip unnecessary updates by comparing the binding’s current and old values (see below on hook arguments). */
	public update(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode): void {}

	/** @description called after the containing component’s VNode and the VNodes of its children have updated. */
	public componentUpdated(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode): void {
		vnode.context.$nextTick(() => {
			if(!binding.value) return;
			if(HelpDirective.hasHelpIcon(el)) return;
			let helpParent: HTMLElement = HelpDirective.getEnclosingNode(el);
			let help: HTMLElement = HelpDirective.createHelpIcon(binding, vnode, oldVnode);
			el.appendChild(document.createTextNode(" "));
			el.appendChild(help);
			HelpDirective.updateTooltip(el, binding, vnode, oldVnode);
		});
	}

	/** @description called only once, when the directive is unbound from the element. */
	public unbind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode): void {
		let helpers: HTMLCollectionOf<Element>	= el.getElementsByClassName("fa-question-circle");
		for(let i = helpers.length; i >= 0; i--) {
			let help: HTMLElement = <HTMLElement>helpers.item(i);
			if(!help) continue;	// Element may have been removed from DOM already
			VBTooltip.unbind(help, null, null, null);
		}
	}


	private static hasHelpIcon(el: HTMLElement): boolean {
		if(el.classList.contains("fa-question-circle")) return true;
		let icons = el.getElementsByClassName("fa-question-circle");
		if(icons.length > 0) return true;
		return false;
	}

	private static getEnclosingNode(el: HTMLElement): HTMLElement {
		if(el.nodeName == "label") return el;
		if(el.nodeName == "legend") return el;
		let labels: HTMLCollectionOf<HTMLElement> = el.getElementsByTagName('legend');
		if(labels.length > 0) return labels[0];
		labels = el.getElementsByTagName('label');
		if(labels.length > 0) return labels[0];
		return el;
	}

	private static createHelpIcon(binding: DirectiveBinding, vnode: VNode, oldVnode: VNode): HTMLElement {
		let question: Icon = icon({ prefix: 'far', iconName: 'question-circle' });
		let node = <HTMLElement>question.node.item(0);
		node.classList.add("help");
		return node;
	}

	private static bindTooltip(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode): void {
		let tooltipBinding = HelpDirective.mapBinding(binding)
		if(tooltipBinding.arg == "long-tooltip"){
			HelpDirective.addTooltipContainer("long-tooltip");
		} else if(tooltipBinding.arg == "very-long-tooltip"){
			HelpDirective.addTooltipContainer("very-long-tooltip");
		}
		if((tooltipBinding.value + "").length > 500) {
			HelpDirective.addTooltipContainer("very-long-tooltip");
		} else if((tooltipBinding.value + "").length > 250) {
			HelpDirective.addTooltipContainer("long-tooltip");
		}
		VBTooltip.bind(el, tooltipBinding, vnode, oldVnode);
	}

	private static updateTooltip(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode): void {
		let toolTipBinding = HelpDirective.mapBinding(binding)
		let helpers: HTMLCollectionOf<Element> = el.getElementsByClassName("fa-question-circle");
		for(let i = helpers.length; i >= 0; i--) {
			let help: HTMLElement = <HTMLElement>helpers.item(i);
			if(!help) continue;
			VBTooltip.componentUpdated(help, toolTipBinding, vnode, null);
		}
	}

	private static mapBinding(binding: DirectiveBinding): DirectiveBinding {
		let arg: string;
		if((binding.value + "").length > 500) {
			arg = "very-long-tooltip";
		} else if((binding.value + "").length > 250) {
			arg = "long-tooltip";
		}
		let value: string;
		let isHTML: boolean = false;
		let noSplit = (binding.modifiers && binding.modifiers["nosplit"] == true) ? true: false;
		if(!noSplit && (binding.value + "").indexOf(". ") > -1) {	// Split sentences into lines
			isHTML = true;
			value = (binding.value + "")
				.split(". ")
				.join(".<br/>");
		}
		let modifiers: { [key: string]: boolean; } = { ...binding.modifiers };
		delete modifiers["nosplit"];
		modifiers["html"] = modifiers["html"] || isHTML;
		let tooltipBinding: DirectiveBinding = <DirectiveBinding>{
			name: "b-tooltip",
			value: value || binding.value,
			expression: binding.expression,
			arg: binding.arg || arg,
			modifiers: modifiers
		};
		let placements: Array<string> = ["top", "bottom", "left", "right", "auto", "topleft", "topright", "bottomleft", "bottomright", "lefttop", "leftbottom", "righttop", "rightbottom"];
		if(	// No placement defined: default to right (b-tooltip has top as default)
			!tooltipBinding.modifiers ||
			!placements
				.map((key: string): boolean => tooltipBinding.modifiers[key] != undefined)
				.reduce((a: boolean, b: boolean): boolean => a || b, false)
		) {
			tooltipBinding.modifiers["right"] = true;	// Set default placement for b-tooltip
		}
		let events: Array<string> = ["click", "hover", "focus", "blur"];
		if(	// No event defined: default to hover (b-tooltip has hover & focus as default)
			!tooltipBinding.modifiers ||
			!events
				.map((key: string): boolean => tooltipBinding.modifiers[key] != undefined)
				.reduce((a: boolean, b: boolean): boolean => a || b, false)
		) {
			tooltipBinding.modifiers["focus"] = false;	// Remove default focus modifier for b-tooltip
			tooltipBinding.modifiers["hover"] = true;	// Set default hover modifier for b-tooltip
		}
		return tooltipBinding;
	}

	private static addTooltipContainer(containerElementId: string): void {
		if(document.getElementById(containerElementId)) return;
		let containerElement: HTMLDivElement = document.createElement("div");
		containerElement.id = containerElementId;
		document.body.appendChild(containerElement);
	}

}

const helpDirective = new HelpDirective();

export default helpDirective;
