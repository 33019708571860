import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, IGamePlan, ResponseOfIEnumerableOfGamePlan, ResponseOfGamePlan, IResponseOfGamePlan, GamePlan, ResponseOfInt32, Response } from './api';


/**
 * @description Service for handling GamePlans on the server.
 * @extends BaseWebSocketService
 */
export class GamePlanService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<IGamePlan>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfGamePlan> => {
				return hub.getGamePlans();
			})
			.then((result: ResponseOfIEnumerableOfGamePlan): Array<IGamePlan> => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "GamePlanService.getAll() - No GamePlans found!");
					return new Array<IGamePlan>();
				}
				Logger.log(LogLevel.Debug, "GamePlanService.getAll() - Server Response: " + result.result.length, result.result);
				return result.result;
			});
	}

	public getItem(id: number): Promise<IGamePlan | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfGamePlan> => {
				return hub.getGamePlan(id);
			}).then((result: IResponseOfGamePlan) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "GamePlanService.getItem(" + id + ") - No GamePlan with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "GamePlanService.getItem(" + id + ") - Server Response: ", result.result);
				return result.result;
			});
	}

	public createItem(item: GamePlan): Promise<number> {
		Logger.log(LogLevel.Debug, "GamePlanService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createGamePlan(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "GamePlanService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "GamePlanService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: GamePlan): Promise<void> {
		Logger.log(LogLevel.Debug, "GamePlanService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updateGamePlan(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "GamePlanService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "GamePlanService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteGamePlan(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "GamePlanService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "GamePlanService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}
}
