import { Language } from '@/models/datepicker-language';

export let english: Dictionary<string> = {};

english["decimal-separator"] =	".";									//Units																			//Used: Global!
english["year-plural"] =	"years";									//Units																			//Used: Global!
english["month-plural"] =	"months";									//Units																			//Used: Global!
english["day-plural"] =	"days";										//Units																			//Used: Global!
english["hour-plural"] =	"hours";									//Units																			//Used: Global!
english["minute-plural"] = "minutes";									//Units																			//Used: Global!
english["second-plural"] = "seconds";									//Units																			//Used: Global!
english["NOK"] =	",-";												//Currency																		//Used: Global!
english["nok"] =	",-";												//Currency																		//Used: Global!
english["nok-with-decimal"] =	" ";									//Currency																		//Used: Global!
english["NOK-with-decimal"] =	" ";									//Currency																		//Used: Global!

english["menu-linked-room-blocks"] =	"with Linked Room";
english["menu-looping-games-blocks"] =	"with Looping Games";
english["menu-game-blocks"] =	"with Games";

export let englishDatepicker: Language = {
	language: 'English',
	months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	monthsAbbr: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
	days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
	daysLong: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
};