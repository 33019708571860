






































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import PatternDesigner from '@/components/pages/forms/PatternDesigner.vue';
import { IPrizePattern, PrizePattern, IGridType as GridType, IGridSize, GridSize } from '@/services/api';


@Component({
	name: 'PrizePatternForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"fg-input": fgInput,
		PatternDesigner
	}
})
export default class PrizePatternForm extends BaseForm {

	public get title(): string {
		let title: string = this.isNew ? "New Prize Pattern" : "Edit Prize Pattern";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			return true;
		});
	}

//#endregion

	private gridTypeOnChange(value: number) {
		//Logger.log(LogLevel.Info, 'User choose Sheet Type:' + this.gridTypeList.find((item: GridType) => item.id == value).name);
	}

	public get rows(): number {
		let type = this.gridTypeList.find((type: GridType): boolean => type.id == this.item.gridTypeId);
		if(!type) return 0;
		let size: GridSize =  type.gridSize;
		if(!size) return 0;
		return size.rows;
	}

	public get columns(): number {
		let type = this.gridTypeList.find((type: GridType): boolean => type.id == this.item.gridTypeId);
		if(!type) return 0;
		let size: GridSize =  type.gridSize;
		if(!size) return 0;
		return size.columns;
	}

	public get pattern(): Array<number> {
		//if (!this.item.pattern) return [];
		return this.item.pattern;
	}

	private getGridTypeName(type: GridType): string {
		let name: string = "";
		if(!type) return name;
		let size: GridSize =  type.gridSize;
		if(size) name = this.localize(this.numberOfBallsEnum[size.numberOfBalls]) + " " + " (" + size.rows + " x " + size.columns + ")";
		if(type.freeplayFields && Array.isArray(type.freeplayFields)) name += " " + this.localize("with Freeplay");
		if(type.blanksPerRow && type.blanksPerRow > 0) name += " " + this.localize("with") + " " + type.blanksPerRow + " " +  this.localize("blanks / row");
		return name;
	}

	public patternChanged(value: Array<number>): void {
		//if(!this.isDataLoaded) return;
		if(!value || value.length == 0) return;
		this.item.pattern = value;
		//Logger.log(LogLevel.Trace, "PrizePatternForm.patternChanged(value) - Received new pattern:", this.item.pattern);
	}

}
