import Handlebars from 'handlebars';
import { LogLevel } from '@/models';
import { Logger } from '@/utilities';
import { localizations, datepickerLocalizations } from '@/assets/localization';
import { Language } from '@/models/datepicker-language';


export namespace Localizer {

	export let language: string;

	language = getUserAgentLanguage();
	let languages: Array<string> = new Array<string>();
	for(let key in localizations){
		if (key)languages.push(key);
	}
	Logger.log(LogLevel.Trace, "Localizer - Loaded languages: " + JSON.stringify(languages) + ", Language set to: " + language);

	export function getUserAgentLanguage(): string {
		let nav:any = <any>navigator;
		let lang: string = nav.language || nav.userLanguage || nav.browserLanguage || nav.systemLanguage || nav.languages && nav.languages[0];
		if (lang.indexOf('-') !== -1)
			lang = lang.split('-')[0];
		if (lang.indexOf('_') !== -1)
			lang = lang.split('_')[0];
		//if (!lang || !(lang in localizations))
		// 	lang = ConfigurationService.configuration && ConfigurationService.configuration.gui.defaultLanguage;
		if (process.env.NODE_ENV == "development"){
			Logger.log(LogLevel.Warn, "Localizer.getUserAgentLanguage() - Running in mode: " + process.env.NODE_ENV + ". Forcing Norwegian translations!");
			lang = "no";
		}
		if (!lang) lang = "en";
		return lang;
	}

	export function get(key: string, isPlural: boolean = false, hasDecimal: boolean = false, args: any = undefined) {
		let returnValue: string = key;
		try {
			let evaluatedKey = key;
			if(isPlural) evaluatedKey += "-plural"; // TODO(kasperc): Fallback if *-plural key doesn't exist (to non-plural?)
			if(hasDecimal) evaluatedKey += "-with-decimal"; // TODO(kasperc): Fallback if *-with-decimal key doesn't exist
			let translatedKey = localizations[language][evaluatedKey];
			if (!translatedKey) Logger.log(LogLevel.ToDo, "Missing Translation (" + language + "): norwegian[\"" + evaluatedKey + "\"] = \"FIX\";");
			if (args && translatedKey) {
				var template = Handlebars.compile(translatedKey);
				return template({ ...args });
			}
			return translatedKey || key;
		}
		catch (e) {
		    if (e instanceof TypeError){
				Logger.log(LogLevel.Error, "Localizer.get(\"" + key + "\") - Language not defined for " + language + ".");
		    }
			Logger.log(LogLevel. ToDo, "Missing Translation (" + language + "): norwegian[\"" + key + "\"] = \"FIX\";");
			return key;
		}
	}

	export function formatAmount(amount: number): string {
		let value: string = amount + "";
		return value.replace(".", localizations[language]["decimal-separator"]);
	}

	export function getDatepickerLanguage(): Language {
		if(Localizer.firstDayOfWeek() == "Monday") {
			let lang: Language = Object.assign({}, datepickerLocalizations[language]);
			lang.days = Object.assign([], datepickerLocalizations[language].days);
			lang.daysLong = Object.assign([], datepickerLocalizations[language].daysLong);
			lang.days.push(lang.days.shift());
			lang.daysLong.push(lang.daysLong.shift());
			return lang;
		}
		return datepickerLocalizations[language];
	}

	export function firstDayOfWeek(): string {
		switch(language) {
			case "en":
				return "Sunday";
			default:
				return "Monday";
		}
	}

	export function currentCulture(): string {
		switch(language) {
			case "sv":
				return "sv-SE";
			case "da":
				return "da-DK";
			case "en":
				return "en-GB";
			case "no":
			case "nb":
			default:
				return "nb-NO";
		}
	}

}
