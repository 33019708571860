



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
	name: "l-table"
})
export default class Table extends Vue {

	@Prop([String])
	columns: Array<string>;

	@Prop([])
	data: Array<any>;

	hasValue(item: any, column: string) {
		return item[column.toLowerCase()] !== "undefined";
	}

	itemValue(item: any, column: string) {
		return item[column.toLowerCase()];
	}
}
