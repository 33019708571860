import Vue from 'vue';
import Router from 'vue-router';
import { Localizer } from '@/utilities/localizer';

import DashboardLayout from '@/components/layout/DashboardLayout.vue';
import NotFound from '@/components/pages/NotFound.vue';
import Overview from '@/components/pages/Overview.vue';
import ScheduleList from '@/components/pages/ScheduleList.vue';
import ScheduleForm from '@/components/pages/forms/ScheduleForm.vue';
import BingoBoosterRoomList from '@/components/pages/BingoBoosterRoomList.vue';
import BingoBoosterRoomForm from '@/components/pages/forms/BingoBoosterRoomForm.vue';
import BingoBoosterHallList from '@/components/pages/BingoBoosterHallList.vue';
import BingoBoosterHallForm from '@/components/pages/forms/BingoBoosterHallForm.vue';
import RoomList from '@/components/pages/RoomList.vue';
import RoomForm from '@/components/pages/forms/RoomForm.vue';
import PrizePatternGroupList from '@/components/pages/PrizePatternGroupList.vue';
import PrizePatternGroupForm from '@/components/pages/forms/PrizePatternGroupForm.vue';
import PrizePatternList from '@/components/pages/PrizePatternList.vue';
import PrizePatternForm from '@/components/pages/forms/PrizePatternForm.vue';
import PotList from '@/components/pages/PotList.vue';
import PotForm from '@/components/pages/forms/PotForm.vue';
import GameList from '@/components/pages/GameList.vue';
import GameForm from '@/components/pages/forms/GameForm/GameForm.vue';
import GamePlanList from '@/components/pages/GamePlanList.vue';
import GamePlanForm from '@/components/pages/forms/GamePlanForm/GamePlanForm.vue';
import GameBlockList from '@/components/pages/GameBlockList.vue';
import GameBlockForm from '@/components/pages/forms/GameBlockForm/GameBlockForm.vue';
import LoopingGamesBlockList from '@/components/pages/LoopingGamesBlockList.vue';
import LoopingGamesBlockForm from '@/components/pages/forms/LoopingGamesBlockForm.vue';
import LinkedRoomBlockList from '@/components/pages/LinkedRoomBlockList.vue';
import LinkedRoomBlockForm from '@/components/pages/forms/LinkedRoomBlockForm.vue';
import SheetTypeList from '@/components/pages/SheetTypeList.vue';
import SheetTypeForm from '@/components/pages/forms/SheetTypeForm.vue';
import GridTypeList from '@/components/pages/GridTypeList.vue';
import GridTypeForm from '@/components/pages/forms/GridTypeForm.vue';
import UserList from '@/components/pages/UserList.vue';
import UserForm from '@/components/pages/forms/UserForm.vue';
import Login from '@/components/pages/Login.vue';
import Help from '@/components/pages/Help.vue';


Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			component: DashboardLayout,
			redirect: "/overview",
			children: [
				{
					path: "/overview",
					name: "Overview",
					component: Overview,
					meta: { title: Localizer.get("Overview") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/bingo-booster-rooms",
					name: "BingoBooster Rooms",
					component: BingoBoosterRoomList,
					meta: { title: Localizer.get("BingoBooster Rooms") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/bingo-booster-rooms/create",
					name: "Create BingoBooster Room",
					component: BingoBoosterRoomForm,
					meta: { title: Localizer.get("New BingoBooster Room") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/bingo-booster-rooms/:id",
					name: "Update BingoBooster Room",
					component: BingoBoosterRoomForm,
					meta: { title: Localizer.get("Edit BingoBooster Room") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/bingo-booster-halls",
					name: "BingoBooster Halls",
					component: BingoBoosterHallList,
					meta: { title: Localizer.get("BingoBooster Halls") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/bingo-booster-halls/create",
					name: "Create BingoBooster Hall",
					component: BingoBoosterHallForm,
					meta: { title: Localizer.get("New BingoBooster Hall") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/bingo-booster-halls/:id",
					name: "Update BingoBooster Hall",
					component: BingoBoosterHallForm,
					meta: { title: Localizer.get("Edit BingoBooster Hall") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/schedules",
					name: "Schedules",
					component: ScheduleList,
					meta: { title: Localizer.get("Schedules") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/schedules/create",
					name: "Create Schedule",
					component: ScheduleForm,
					meta: { title: Localizer.get("New Schedule") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/schedules/:id",
					name: "Update Schedule",
					component: ScheduleForm,
					meta: { title: Localizer.get("Edit Schedule") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/rooms",
					name: "Rooms",
					component: RoomList,
					meta: { title: Localizer.get("Rooms") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/rooms/create",
					name: "Create Room",
					component: RoomForm,
					meta: { title: Localizer.get("New Room") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/rooms/:id",
					name: "Update Room",
					component: RoomForm,
					meta: { title: Localizer.get("Edit Room") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/prize-pattern-groups",
					name: "Prize Pattern Groups",
					component: PrizePatternGroupList,
					meta: { title: Localizer.get("Prize Pattern Groups") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/prize-pattern-groups/create",
					name: "Create Prize Pattern Group",
					component: PrizePatternGroupForm,
					meta: { title: Localizer.get("New Prize Pattern Group") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/prize-pattern-groups/:id",
					name: "Update Prize Pattern Group",
					component: PrizePatternGroupForm,
					meta: { title: Localizer.get("Edit Prize Pattern Group") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/prize-patterns",
					name: "Prize Patterns",
					component: PrizePatternList,
					meta: { title: Localizer.get("Prize Patterns") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/prize-patterns/create",
					name: "Create Prize Pattern",
					component: PrizePatternForm,
					meta: { title: Localizer.get("New Prize Pattern") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/prize-patterns/:id",
					name: "Update Prize Pattern",
					component: PrizePatternForm,
					meta: { title: Localizer.get("Edit Prize Pattern") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/pots",
					name: "Pots",
					component: PotList,
					meta: { title: Localizer.get("Pots") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/pots/create",
					name: "Create Pot",
					component: PotForm,
					meta: { title: Localizer.get("New Pot") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/pots/:id",
					name: "Update Pot",
					component: PotForm,
					meta: { title: Localizer.get("Edit Pot") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/games",
					name: "Games",
					component: GameList,
					meta: { title: Localizer.get("Games") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/games/create",
					name: "Create Game",
					component: GameForm,
					meta: { title: Localizer.get("New Game") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/games/:id",
					name: "Update Game",
					component: GameForm,
					meta: { title: Localizer.get("Edit Game") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/game-plans",
					name: "Game Plans",
					component: GamePlanList,
					meta: { title: Localizer.get("Game Plans") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/game-plans/create",
					name: "Create GamePlan",
					component: GamePlanForm,
					meta: { title: Localizer.get("New Game Plan") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/game-plans/:id",
					name: "Update GamePlan",
					component: GamePlanForm,
					meta: { title: Localizer.get("Edit Game Plan") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/game-blocks",
					name: "Game Blocks",
					component: GameBlockList,
					meta: { title: Localizer.get("Game Blocks") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/game-blocks/create",
					name: "Create Game Block",
					component: GameBlockForm,
					meta: { title: Localizer.get("New Game Block") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/game-blocks/:id",
					name: "Update Game Block",
					component: GameBlockForm,
					meta: { title: Localizer.get("Edit Game Block") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/looping-games-blocks",
					name: "Looping Games Blocks",
					component: LoopingGamesBlockList,
					meta: { title: Localizer.get("Looping Games Blocks") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/looping-games-blocks/create",
					name: "Create Looping Games Block",
					component: LoopingGamesBlockForm,
					meta: { title: Localizer.get("New Looping Games Block") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/looping-games-blocks/:id",
					name: "Update Looping Games Block",
					component: LoopingGamesBlockForm,
					meta: { title: Localizer.get("Edit Looping Games Block") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/linked-room-blocks",
					name: "Linked Room Blocks",
					component: LinkedRoomBlockList,
					meta: { title: Localizer.get("Linked Room Blocks") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/linked-room-blocks/create",
					name: "Create Linked Room Block",
					component: LinkedRoomBlockForm,
					meta: { title: Localizer.get("New Linked Room Block") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/linked-room-blocks/:id",
					name: "Update Linked Room Block",
					component: LinkedRoomBlockForm,
					meta: { title: Localizer.get("Edit Linked Room Block") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/sheet-types",
					name: "Sheet Types",
					component: SheetTypeList,
					meta: { title: Localizer.get("Sheet Types") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/sheet-types/create",
					name: "Create Sheet Type",
					component: SheetTypeForm,
					meta: { title: Localizer.get("New Sheet Type") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/sheet-types/:id",
					name: "Update Sheet Type",
					component: SheetTypeForm,
					meta: { title: Localizer.get("Edit Sheet Type") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/grid-types",
					name: "Grid Types",
					component: GridTypeList,
					meta: { title: Localizer.get("Grid Types") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/grid-types/create",
					name: "Create Grid Type",
					component: GridTypeForm,
					meta: { title: Localizer.get("New Grid Type") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/grid-types/:id",
					name: "Update Grid Type",
					component: GridTypeForm,
					meta: { title: Localizer.get("Edit Grid Type") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/users",
					name: "Users",
					component: UserList,
					meta: { title: Localizer.get("Users") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/users/create",
					name: "Create User",
					component: UserForm,
					meta: { title: Localizer.get("New User") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/users/:id",
					name: "Update User",
					component: UserForm,
					meta: { title: Localizer.get("Edit User") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/settings",
					name: "Settings",
					component: NotFound,
					meta: { title: Localizer.get("Settings") + " - " + Localizer.get("Admin - bingo.no") }
				},
				{
					path: "/help",
					name: "Help",
					component: Help,
					meta: { title: Localizer.get("Help") + " - " + Localizer.get("Admin - bingo.no") }
				}

			]
		},
		{
			path: "/login",
			component: Login,
			name: "Log in",
			meta: { title: Localizer.get("Log in") + " - " + Localizer.get("Admin - bingo.no") }
		},
		{
			path: "*",
			component: DashboardLayout,
			children: [
				{
					path: "",
					name: "Not Found",
					component: NotFound,
					meta: { title: Localizer.get("Not Found") + " - " + Localizer.get("Admin - bingo.no") }
				}
			]
		}
	]
});
