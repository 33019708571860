






































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Select, Option, Input } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError, Localizer } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { BingoBoosterRoom } from '@/services/api';


@Component({
	name: 'BingoBoosterRoomForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"el-input": Input,
		"fg-input": fgInput
	}
})
export default class BingoBoosterRoomForm extends BaseForm {
	private bingoBoosterHallIds: Array<number> = new Array<number>();

	/** @method itemReactivePropertyExtractor alows child components to inject property extractors used when loading the item into the form */
	protected get itemReactivePropertyExtractor(): (item: BingoBoosterRoom) => void {
		return ((item: BingoBoosterRoom) => {
			if(!item.bingoBoosterHallIds || !Array.isArray(item.bingoBoosterHallIds)) item.bingoBoosterHallIds = new Array<number>();
			this.bingoBoosterHallIds = item.bingoBoosterHallIds;
		});
	}

	/** @method itemReactivePropertyInserter alows child components to inject property initializers used when saving the item from the form */
	protected get itemReactivePropertyInserter(): (item: BingoBoosterRoom) => void {
		return ((item: BingoBoosterRoom) => {
			item.bingoBoosterHallIds = this.bingoBoosterHallIds;
		});
	}

	public get title(): string {
		let title: string = this.isNew ? "New BingoBooster Room" : "Edit BingoBooster Room";
		return this.localize(title);
	}

}
