import { Language } from '@/models/datepicker-language';

export let norwegian: Dictionary<string> = {};

norwegian["decimal-separator"] = ",";   //Units                   //Used: Global!
norwegian["year"] = "år";         //Units                   //Used: Global!
norwegian["year-plural"] = "år";     //Units                   //Used: Global!
norwegian["month"] =    "måned";           //Units                   //Used: Global!
norwegian["month-plural"] =    "måneder";   //Units                   //Used: Global!
norwegian["day"] = "dag";                //Units                   //Used: Global!
norwegian["day-plural"] = "dager";    //Units                   //Used: Global!
norwegian["hour"] = "time";      //Units                   //Used: Global!
norwegian["hour-plural"] = "timer";    //Units                   //Used: Global!
norwegian["hours"] = "timer";     //Unit for tanning bed running time.           //Used: product-list.component
norwegian["minute"] = "minutt";     //Units                   //Used: Global!
norwegian["minute-plural"] = "minutter";   //Units                   //Used: Global!
norwegian["second"] = "sekund";     //Units                   //Used: Global!
norwegian["second-plural"] = "sekunder";   //Units                   //Used: Global!
norwegian["sec"] = "sek";       //Units                   //Used: Global!
norwegian["sec-plural"] = "sek";     //Units                   //Used: Global!
norwegian["duration"] = "varighet";    //Quantity type                 //Used: Global!
norwegian["quantity"] = "antall";    //Quantity type                 //Used: Global!
norwegian["Amount"] = "Beløp";
norwegian["amount"] = "beløp";        //Amount type                 //Used: Global!
norwegian["length "] = "lengde";     //Quantity type                 //Used: Global!
norwegian["NOK"] = ",-";       //Currency                  //Used: Global!
norwegian["nok"] = ",-";       //Currency                  //Used: Global!
norwegian["nok-with-decimal"] = " ";   //Currency                  //Used: Global!
norwegian["NOK-with-decimal"] = " ";   //Currency                  //Used: Global!
norwegian["Cancel"] = "Avbryt";     //Cancel current operation/purchase.           //Used: Global!
norwegian["Back"] =  "Tilbake";    //Navigation, go back to previous page/operation.           //Used: Global!
norwegian["Next"] =  "Neste";    //Navigation, go to next page/operation.             //Used: Global!
norwegian["Yes"] = "Ja";       //Require receipt                  //Used: Global!
norwegian["No"] = "Nei";       //Require receipt                  //Used: Global!
norwegian["Save"] = "Lagre";
norwegian["Error"] = "Feil";                          //Used: Notifications/Settings.vue/
norwegian["VAT"] = "MVA";
norwegian["Date"] = "Dato";
norwegian["Price"] = "Pris";
norwegian["Currency"] = "Valuta";
norwegian["number"] = "nummer";
norwegian["Unit"] = "Enhet";
norwegian["New"] = "Nytt";
norwegian["Edit"] = "Rediger";
norwegian["Delete"] = "Slett";
norwegian["Edit item"] = norwegian["Edit"];
norwegian["Delete item"] = norwegian["Delete"];
norwegian["Add"] = "Legg til";
norwegian["Choose"] = "Velg";
norwegian["Start"] = "Start";
norwegian["Stop"] = "Stop";
norwegian["Total"] = "Total";
norwegian["Color"] = "Farge";
norwegian["Username"] = "Brukernavn";
norwegian["Email"] = "E-post";
norwegian["Password"] = "Passord";
norwegian["Address"] = "Adresse";
norwegian["Turn on"] = "Skru på";
norwegian["Turn off"] = "Skru av";
norwegian["Failed to get data!"] = "Klarte ikke å hente datane!";
norwegian["Failed to save data!"] = "Klarte ikke å lagre!";
norwegian["There are no items to show"] = "Ingen data å vise";
norwegian["Click to sort Ascending"] = "Klikk for stigende sortering";
norwegian["Click to sort Descending"] = "Klikk for synkende sortering";
norwegian["There are no items matching your request"] = "Ingen data å vise";
norwegian["Are you sure you want to delete this item?"] = "Er du sikker på at du vil slette dette elementet?";
norwegian["Actions"] = "Handlinger";
norwegian["Add new Item"] = "Legg til";
norwegian["Id"] = "Id";
norwegian["Name"] = "Navn";
norwegian["Description"] = "Beskrivelse";
norwegian["Total"] = "Total";
norwegian["Schedule"] = "Kjøreplan";
norwegian["Hours"] = "Timer";
norwegian["Minutes"] = "Minutter";
norwegian["Seconds"] = "Sekunder";
norwegian["<number>"] = "<tall>";
norwegian["<seconds>"] = "<sekunder>";
norwegian["<%>"] = "<%>";

/* Suggested Vocabulary changes: */
norwegian["Admin - bingo.no"] = "Admin - bingo.no";
norwegian["Overview"] = "Oversikt";
norwegian["Schedules"] = "Kjøreplaner";
norwegian["New Schedule"] = "Ny Kjøreplan";
norwegian["Edit Schedule"] = "Rediger Kjøreplan";
norwegian["Prize Pattern Groups"] = "Mønstergrupperinger";
norwegian["New Prize Pattern Group"] = "Ny Mønstergruppering";
norwegian["Edit Prize Pattern Group"] = "Rediger Mønstergruppering";
norwegian["Prize Patterns"] = "Gevinstmønstre";
norwegian["New Prize Pattern"] = "Nytt Gevinstmønster";
norwegian["Edit Prize Pattern"] = "Rediger Gevinstmønster";
norwegian["Pots"] = "Potter";
norwegian["New Pot"] = "Ny Pott";
norwegian["Edit Pot"] = "Rediger Pott";
norwegian["Games"] = "Spill";
norwegian["New Game"] = "Nytt Spill";
norwegian["Edit Game"] = "Rediger Spill";
norwegian["Game Plans"] = "Spilleplaner";
norwegian["New Game Plan"] = "Ny Spilleplan";
norwegian["Edit Game Plan"] = "Rediger Spilleplan";
norwegian["Blocks"] = "Bolker";
norwegian["New Block"] = "Ny Bolk";
norwegian["Edit Block"] = "Rediger Bolk";
norwegian["Sheet Types"] = "Arktyper";
norwegian["New Sheet Type"] = "Ny Arktype";
norwegian["Edit Sheet Type"] = "Rediger Arktype";
norwegian["Grid Types"] = "Matriser";
norwegian["New Grid Type"] = "Ny Matrise";
norwegian["Edit Grid Type"] = "Rediger Matrise";
norwegian["Users"] = "Brukere";
norwegian["New User"] = "Ny Bruker";
norwegian["Edit User"] = "Rediger Bruker";
norwegian["Log in"] = "Logg inn";
norwegian["Not Found"] = "Finnes ikke";
norwegian["Admin"] = "Admin";
norwegian["Collapse"] = "Kollaps";
norwegian["Expand"] = "Ekspander";
norwegian["Settings"] = "Innstillinger";
norwegian["menu-linked-room-blocks"] = "med Lenkede Rom";
norwegian["menu-looping-games-blocks"] = "med Repeterende Spill";
norwegian["menu-game-blocks"] = "med Spill";

norwegian["Close"] = "Lukk";
norwegian["Shortcut"] = "Snarvei";

norwegian["Dashboard"] = "Dashboard";
norwegian["© 2019 Vexta"] = "© 2019 Vexta";

// BingoBooster Rooms
norwegian["BingoBooster Rooms"] = "BingoBooster Rom";
norwegian["BingoBooster Room"] = "BingoBooster Rom";
norwegian["New BingoBooster Room"] = "Nytt BingoBooster Rom";
norwegian["Edit BingoBooster Room"] = "Rediger BingoBooster Rom";
norwegian["Enabled"] = "Enabled";
norwegian["Host"] = "Host";
norwegian["<IP>"] = "<IP>";
norwegian["Port"] = "Port";
norwegian["*****"] = "*****";
norwegian["Certificate File"] = "Sertifikatfil";

// BingoBooster Halls
norwegian["BingoBooster Halls"] = "BingoBooster Haller";
norwegian["New BingoBooster Hall"] = "Ny BingoBooster Hall";
norwegian["Edit BingoBooster Hall"] = "Rediger BingoBooster Hall";
norwegian["Mapping"] = "Mapping";
norwegian["Hall Id in BingoBooster"] = "Hallid i BingoBooster";
norwegian["Hall"] = "Hall";
norwegian["maps to BB hall"] = "tilsvarer BB hall";
norwegian["Hall Id in Hall Server"] = "Hallid i Hallserver";
norwegian["HallId in BingoBooster"] = "Hallid i BingoBooster";

// Rooms
norwegian["Room"] = "Rom";
norwegian["Rooms"] = norwegian["Room"];
norwegian["New Room"] = "Nytt Rom";
norwegian["Edit Room"] = "Rediger Rom";
norwegian["Status"] = "Status";
norwegian["Stopped"] = "Stoppet";
norwegian["Force Stop"] = "Tvungen stopp";
norwegian["Restart"] = "Omstart";
norwegian["Stop to enable Edit"] = "Stopp for å kunne redigere";
norwegian["Stop to enable Delete"] = "Stopp for å kunne slette";

// ** Schedules ** //
norwegian["text"] = "tekst";
norwegian["Bulk Assign"] = "Marker utvalg";
norwegian["Assign to dates"] = "Marker datoer";
norwegian["Current Game"] = "Spilles nå";
norwegian["Controls"] = "Kontroller";
norwegian["undefined"] = "udefinert";

// ** GamePlans ** //
norwegian["Game Plan"] = "Spilleplan";
norwegian["blocks"] = "bolker";
norwegian["Start Time"] = "Starttid";
norwegian["hh:mm"] = "hh:mm";
norwegian["Add Block"] = "Legg til Bolk";

// Blocks
norwegian["Block"] = "Bolk";

// Linked Room Blocks
norwegian["Linked Room Blocks"] = "Bolker m/ Lenket Rom";
norwegian["New Linked Room Block"] = "Ny Bolk m/ Lenket Rom";
norwegian["Edit Linked Room Block"] = "Rediger Bolk m/ Lenket Rom";

// Looping Games Block
norwegian["Looping Games Blocks"] = "Bolker m/ Repeterende Spill";
norwegian["New Looping Games Block"] = "Ny Bolk m/ Repeterende Spill";
norwegian["Edit Looping Games Block"] = "Rediger Bolk m/ Repeterende Spill";

// ** Game Blocks ** //
norwegian["Game Blocks"] = "Bolker m/ Spill";
norwegian["New Game Block"] = "Ny Bolk m/ Spill";
norwegian["Edit Game Block"] = "Rediger Bolk m/ Spill"
norwegian["Duration"] = "Varighet";
norwegian["games"] = "spill";
norwegian["Order"] = "Flytt";
norwegian["drag'n drop"] = "dra og slipp";
norwegian["Quantity"] = "Antall";
norwegian["Add Game"] = "Legg til Spill";
norwegian["Number of Games to Add"] = "Antall spill som skal legges til";
norwegian["Pick"] = "Legg til";
norwegian["<HH:MM:SS>"] = "<HH:MM:SS>";

// * Games * //
norwegian["Game"] = "Spill";
norwegian["Type"] = "Type";
norwegian["Sheets"] = "Ark";
norwegian["Show Letters"] = "Vis bokstaver";
norwegian["Draw Delay"] = "Trekketid";
norwegian["build"] = "bygg";
norwegian["of pot"] = "av pott";
norwegian["Total number of draws"] = "Totalt antall trekk";
norwegian["Initial Draws"] = "Vanlige trekk";
norwegian["Number of initial draws"] = "Antall vanlige trekk";
norwegian["Initial draw delay"] = "Trekketid for vanlige trekk";
norwegian["Suspense Draws"] = "Spenningstrekk";
norwegian["Number of suspense draws"] = "Antall spenningstrekk";
norwegian["Suspense draw delay"] = "Trekketid for spenningstrekk";
norwegian["Extra balls"] = "Ekstraballer";
norwegian["Number of extra balls"] = "Antall ekstraballer";
norwegian["Extra ball draw delay"] = "Trekketid for ekstraballer";
norwegian["Extra ball probability"] = "Sannsynlighet for ekstraballer";
norwegian["Add Extra Ball"] = "Legg til ekstraball";
norwegian["Unique"] = "Unikt";
norwegian["High only"] = "Kun høyeste";
norwegian["Probability for Extra ball"] = "Sannsynlighet for ekstraball";
norwegian["Game Type"] = "Type Spill";
norwegian["Revenue based Game"] = "Omsetningsbasert Spill";
norwegian["Probability based Game"] = "Sannsynlighetsbasert Spill";
norwegian["Purchase time"] = "Salgstid";
norwegian["Max Duration"] = "Maksimal varighet";
norwegian["Game Client Communication"] = "Kommunikasjon mot spillklient";
norwegian["Send Game Result immedeatly after sales closes"] = "Send spillresultatet umiddelbart etter salget er lukket";
norwegian["Fixed value prize"] = "Fast premie";
norwegian["Highest prize only"] = "Kun høyeste gevinstmønster";

//Prizes
norwegian["Prizes"] = "Gevinster";
norwegian["Prize Total"] = "Total gevinst";
norwegian["% of revenue"] = "% av omsetning";
norwegian["Extra Rules"] = "Ekstraregler";
norwegian["Prize Id: "] = "Gevinst Id:";
norwegian["of revenue"] = "av omsetning";
norwegian["Extra Rule Type"] = "Type Ekstraregel";
norwegian["None"] = "Ingen";
norwegian["Column"] = "Kolonne";
norwegian["Row"] = "Rad";
norwegian["DividebleBy"] = "Delelig med";
norwegian["ExactNumber"] = "Eksakt tall";
norwegian["PrimeNumber"] = "Primtall";
norwegian["NotPrimeNumber"] = "Ikke primtall";
norwegian["PrizeMultiplicator"] = "Gevinstmultiplikator";
norwegian["LuckyNumber"] = "Lykketall";
norwegian["BallMultiplier"] = "Ballmultiplikasjon";
norwegian["ExactDraw"] = "Eksakt trekk";
norwegian["GoldenNumber"] = "Golden Number";
norwegian["NotDividebleBy"] = "Ikke delelig med";
norwegian["WithinNumber"] = "Innen trekk";
norwegian["Prize"] = "Gevinst";
norwegian["Extra Rule"] = "Ekstraregel";
norwegian["Pot"] = "Pott";
norwegian["Value"] = "Verdi";
norwegian["New Prize"] = "Ny Gevinst";
norwegian["Prize Pattern Group"] = "Mønstergruppe";
// Draw
norwegian["Draw"] = "Trekning";
norwegian["number (seconds)"] = "nummer (sekunder)";
norwegian["Letters"] = "Bokstaver";

// ** Pots ** //
norwegian["Builds"] = "Byggprosent";
norwegian["Prize Cap"] = "Gevinst-tak";
norwegian["Jackpot"] = "Jackpot";
norwegian["Global"] = "Global";
norwegian["Current Size"] = "Beholdning";
norwegian["Build Percent"] = "Byggprosent";

// ** Prize Pattern Group ** //
norwegian["Required"] = "Vinnerkrav";
norwegian["of"] = "av";
norwegian["Patterns"] = "Mønstre";
norwegian["Dependancy Of Other Prizes"] = "Avhenger av andre gevinster";
norwegian["Independant"] = "Uavhengig";
norwegian["patterns"] = "mønstre";
norwegian["pattern"] = "mønster";

// ** Prize Pattern ** //
norwegian["Prize Pattern"] = "Gevinstmønster";
norwegian["Pattern"] = "Mønster";
norwegian["Exact"] = "Eksakt";
norwegian["Exact Pattern"] = "Eksakt mønster";

// ** Sheet Types ** //
norwegian["Sheet Type"] = "Arktype";
norwegian["Grids Per Sheet"] = "Matriser per ark";
norwegian["Green"] = "Grønn";
norwegian["Orange"] = "Oransje";
norwegian["Blue"] = "Blå";
norwegian["Red"] = "Rød";
norwegian["Grid Type"] = "Matrise";
norwegian["Grids per Sheet"] = "Matriser per ark";
norwegian["Grids"] = "Matriser";
norwegian["Grid"] = "Matrise";
norwegian["per Sheet"] = "per ark";

// ** Grids ** //
norwegian["Size"] = "Dimensjoner";
norwegian["Fields"] = "Felter";
norwegian["All included"] = "Alle inkludert";
norwegian["Number of Balls"] = "Antall baller";
norwegian["loading..."] = "laster...";
norwegian["75-ball game"] = "75-talls spill";
norwegian["90-ball game"] = "90-talls spill";
norwegian["30-ball game"] = "30-talls spill";
norwegian["Blanks"] = "Blanke";
norwegian["Blanks per row"] = "Angi per rad";
norwegian["Grid Designer"] = "Matrisedesigner";
norwegian["Grid Size"] = "Dimensjoner";
norwegian["per row"] = "per rad";
norwegian["Select freeplay fields"] = "Velg freeplay felter";
norwegian["Off"] = "Av";
norwegian["On"] = "På";

// ** Users ** //
norwegian["Role"] = "Rolle";
norwegian["*********"] = "*********";

// ** Not Found ** //
norwegian["This is not the page you are looking for, try the menu to get where you want."] = "Dette er ikke siden du ser etter, bruk menyen for å komme dit du vil";

// Help
norwegian["Help"] = "Hjelp";
norwegian["txt-help-description"] = "Dette er en overordnet forklaring av konseptet og begrepene i SBS med målsetning å gi deg som bruker en større forståelse for systemet slik at du lettere kan administrere det. For mer konkret assistanse på spesifikke felter og innstillinger i løsningen hanvises til hjelpeikoner plassert ved hvert enkelt felt:";
norwegian["txt-help-room-description"] = "Et Rom kan sees på som et fysisk sted som kjøreplanene faktisk kjøres. Et Rom kan representere fysiske steder, som èn bingohall eller en samling haller (link). Et Rom er stedet der Spillerne opplever en Kjøreplan og tilbys muligheten til å delta i Spillene som kjøres. Et Rom kan også være nettbasert.";
norwegian["txt-help-schedule-description"] = "En kjøreplan representerer planleggingen av kjøring av Spilleplaner på spesifiserte datoer.";
norwegian["txt-help-game-plan-description"] = "En Spilleplan definerer hva som skal spilles i løpet av en hel dag, fordelt på mindre tidsperioder, Bolker.";
norwegian["txt-help-block-description"] = "En tidsluke som definerer hva som skal spilles i den gitte tidsperioden. En Bolk vil til syvende og sist definere en samling av Spill som skal kjøres i spesifisert rekkefølge, til spesifisert tid i en Spilleplan.";
norwegian["txt-help-game-description"] = "Defenisjonen av regler for hvilke Ark som skal tilbys, antall baller som skal trekkes og hvilke gevinstmønstre og premier som skal inngå i spillet.";
norwegian["txt-help-pot-description"] = "En separat \"saldo\" hvor en andel penger fra omsetningen settes av til ekstraordinære premier som ekstraregler og Jackpot. Det er mulig å bygge potter innenfor enkelte spill, men også på tvers av alle spill. Ved bruk av ekstraordinære premier i et Spill kan man velge hvilken Pot som skal finansiere utbetalingen.";
norwegian["txt-help-prize-pattern-group-description"] = "En gruppering av likestilte Gevinstmønstre som alle representerer samme rett til premie.";
norwegian["txt-help-prize-pattern-description"] = "Et visuelt mønster innenfor en matrise. Dersom mønsteret gjenspeiles av markeringer innen for én tallmatrise på et Ark gir dette Spilleren rett til gevinst i Spillet hvor Arket er kjøpt.";
norwegian["txt-help-sheet-type-description"] = "Matriser fylles med tall og plasseres på Ark som Spillerne da kan kjøpe for å delta i et Spill. Arktypene bestemmer hvordan matrisene skal plasseres på Arkene og hvordan de skal prises.";
norwegian["txt-help-grid-type-description"] = "En matrise representerer et firkantet rutemønster med plass for et tall i hver rute, eller felt. Matriser kan angis i dimensjonene høyde og bredde for a angi størrelse som da gir antall felt. Det kan også spesifiseres tomme felt eller free-play felter på matrisene, de øvrige feltene er tiltenkt å bli fylt av tall for å kunne markeres av tallene som trekkes i et bingo-spill. En Matrise fyllt med tall utgjør ett enkelt Ark.";


export let norwegianDatepicker: Language = {
 language: 'Norsk',
 months: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'],
 monthsAbbr: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
 days: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
 daysLong: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag']
};