












































import { Vue, Component, Watch } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import { BingoBoosterRoomService } from '@/services';
import Card from '@/components/lbd/Cards/Card.vue';
import { BingoBoosterHall, IBingoBoosterRoom as BingoBoosterRoom, Room } from '@/services/api';


@Component({
	name: 'BingoBoosterRoomList',
	components: {
		Card
	}
})
export default class BingoBoosterRoomList extends BaseList {
	private bingoBoosterRoomService: BingoBoosterRoomService = new BingoBoosterRoomService();

	public created() {
	}

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: true
		},
		{
			key: "roomId",
			label: this.localize("Room"),
			formatter: this.roomIdFormatter
		},
		{
			key: "bingoBoosterHallIds",
			label: this.localize("BingoBooster Halls"),
			formatter: this.bingoBoosterHallIdsFormatter
		},
		{
			key: "enabled",
			label: this.localize("Enabled"),
			sortable: true
		},
		{
			key: "actions",
			label: this.localize("Actions")
		}
	];

	private bingoBoosterHallIdsFormatter(value: Array<number>, key: string, item: BingoBoosterRoom): string {
		return (this.bingoBoosterHallList || new Array<BingoBoosterHall>())
			.filter((hall) => value.includes(hall.id))
			.map(hall => hall.name)
			.reduce((a, b) => a + "," + b, "")
			.substring(1);
	}

	private roomIdFormatter(value: number, key: string, item: BingoBoosterRoom): string {
		if(!value) return undefined;
		let room = (this.roomList || new Array<Room>())
			.find(room => room.id == value);
		if(room) return room.name;
		return undefined;
	}


}
