



























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
	name: "drop-down"
})
export default class Dropdown extends Vue {

	@Prop(String)
	title: string;

	@Prop(String)
	icon: string;

	@Prop(String)
	position: string;

	@Prop(Boolean)
	hideArrow: boolean;

	@Prop({ type: String, default: "li"})
	tag: string;

	isOpen: boolean = false;

	toggleDropDown() {
		this.isOpen = !this.isOpen;
		this.$emit('change', this.isOpen);
	}

	closeDropDown() {
		this.isOpen = false;
		this.$emit('change', this.isOpen);
	}
}
