import { IAccount, Account } from '@/services/api';

export interface IUser extends IAccount {

}

export class User extends Account {

}

// export class User implements IUser {
//     public static fromJS(data: any): User {
//         data = typeof data === 'object' ? data : {};
//         let result = new User();
//         result.init(data);
//         return result;
//     }

// 	public id: number;
// 	public username: string;
// 	public role: string;
// 	public email: string;
// 	public password: string;
// 	public token: string;

// 	public init(data?: any) {
//         if (data) {
//             this.id = data["Id"];
//             this.username = data["Username"];
//             this.role = data["Role"];
//             this.email = data["Email"];
//             this.password = data["Password"];
//             this.token = data["Token"];
//         }
//     }

//     public toJSON(data?: any) {
//         data = typeof data === 'object' ? data : {};
//         data["Id"] = this.id;
//         data["Username"] = this.username;
//         data["Role"] = this.role;
//         data["Email"] = this.email;
//         data["Password"] = this.password;
//         data["Token"] = this.token;
//         return data;
//     }

// }
