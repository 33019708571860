




import { Vue, Component } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { Logger, Localizer } from "@/utilities";
import { LogLevel } from "@/models";


@Component({})
export default class BaseComponent extends Vue {

//#region Component Life Cycle Hooks

	/** @method beforeCreate Lifecycle hook */
	public beforeCreate() {}

	/** @method created Lifecycle hook */
	public created() {}

	/** @method beforeMount Lifecycle hook */
	public beforeMount() {}

	/** @method mounted Lifecycle hook */
	public mounted() {}

	/** @method beforeUpdate Lifecycle hook */
	public beforeUpdate() {}

	/** @method updated Lifecycle hook */
	public updated() {}

	/** @method beforeDestroy Lifecycle hook */
	public beforeDestroy() {}

	/** @method destroyed Lifecycle hook */
	public destroyed() {}

	/** @method beforeRouteEnter Lifecycle hook
	 * @description called before the route that renders this component is confirmed.
	 * does NOT have access to `this` component instance, because it has not been created yet when this guard is called! */
	public beforeRouteEnter(to: Route, from: Route, next: Function) {}

	/** @method beforeRouteUpdate Lifecycle hook
	 * @description called when the route that renders this component has changed, but this component is reused in the new route.
	 * For example, for a route with dynamic params `/foo/:id`, when we navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance will be reused, and this hook will be called when that happens.
	 * has access to `this` component instance. */
	public beforeRouteUpdate(to: Route, from: Route, next: Function) {}

	/** @method beforeRouteLeave Lifecycle hook
	 * @description called when the route that renders this component is about to be navigated away from.
	 * has access to `this` component instance. */
	public beforeRouteLeave(to: Route, from: Route, next: Function) {}

	/** @method errorCaptured Lifecycle hook */
	public errorCaptured(err: Error, vm: Vue, info: string): boolean {
		Logger.log(LogLevel.Error, "BaseComponent.errorCaptured() - Captured Error:", { err: err, vm: vm, info: info });
		return true;
	}

//#endregion

	/** @method */
	protected localize(key: string, count: number = 0, hasDecimal: boolean = false): string {
		let isPlural: boolean = (count > 1) ? true : false;
		return(Localizer.get(key, isPlural, hasDecimal));
	}

	/** @method */
	protected localizeAmount(amount: number): string {
		return Localizer.formatAmount(amount);
	}

}

