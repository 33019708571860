






































import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';


@Component({})
export default class Help extends BaseComponent {}
