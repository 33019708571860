












































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { Select, Option } from 'element-ui';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import { Prize, IGridType as GridType, ExtraRuleType, ExtraRule, Pot, PayoutSource, Game } from '@/services/api';


@Component({
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option
	}
})
export default class ExtraRuleForm extends BaseComponent {
	private extraRuleTypeEnum: typeof ExtraRuleType = ExtraRuleType;
	private item: ExtraRule = new ExtraRule({
		type: ExtraRuleType.None,
		value: undefined,
		potId: undefined,
		potPayoutPercent: 0,
		minimumPotPayout:0,
		fixedPayoutValue: 0,
		fixedPayoutSource: PayoutSource.Pot
	});

	public created() {
		this.item = this.prize.extraRule;
	}

	@Prop({ type: Object, required: true })
	public prize: Prize;

	@Prop({ type: Number, required: true })
	public gridTypeId: number;

	@Watch("prize")
	private prizeChanged(value: Prize, oldVal: Prize): void {
		if(!value) {
			this.item = new ExtraRule({
				type: ExtraRuleType.None,
				value: undefined,
				potId: undefined,
				potPayoutPercent: 0,
				minimumPotPayout:0,
				fixedPayoutValue: 0,
				fixedPayoutSource: PayoutSource.Pot
			});
			return;
		}
		this.item = this.prize.extraRule;
	}

	private ruleChanged(): void {
		this.item.fixedPayoutSource = (this.item.potId == 0) ? PayoutSource.None: PayoutSource.Pot;
		this.$emit("change", this.item);
	}

	private getNumberOfColumns(): number {
		let type = (this.gridTypeList || new Array<GridType>())
			.find((element: GridType): boolean => element.id == this.gridTypeId);
		if(!type || !type.gridSize) return 0;
		return type.gridSize.columns;
	}

	private getNumberOfRows(): number {
		let type = (this.gridTypeList || new Array<GridType>())
			.find((element: GridType): boolean => element.id == this.gridTypeId);
		if(!type || !type.gridSize) return 0;
		return type.gridSize.rows;
	}

	private getColumnName(value: number): string {
		if(this.getNumberOfColumns() == 5) return ["B","I","N","G","O"][value - 1];
		return value + "";
	}

	private getMaxNumber(value: ExtraRuleType): number | undefined {
		if(value != ExtraRuleType.ExactDraw && value != ExtraRuleType.ExactNumber) return undefined;
		let type = (this.gridTypeList || new Array<GridType>())
			.find((element: GridType): boolean => element.id == this.gridTypeId);
		if(type && type.gridSize) return type.gridSize.numberOfBalls;
		return undefined;
	}

	private get gridTypeList(): Array<GridType> {
		return this.$store.state.cacheStore.gridTypeList;
	}

	private get potList(): Array<Pot> {
		return this.$store.state.cacheStore.potList;
	}

	private get emptyResultText(): string {
		return this.localize('There are no items to show');
	}

}

