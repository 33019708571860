























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
	name: "l-progress"
})
export default class Progress extends Vue {

	@Prop({ type: String, default: "default" })
	type: string = 'default';

	@Prop(Boolean)
	striped: boolean;

	@Prop(Boolean)
	showText: boolean;

	@Prop(Boolean)
	animated: boolean;

	@Prop({ type: Number, default: 4 })
	height: number = 4;

	value: State;
	values: Array<State> = [];

	get computedClasses() {
		return [`bg-${this.type}`, {'progress-bar-striped': this.striped}, {'progress-bar-animated': this.animated}];
	}

	progressClasses(progress: Progress) {
		return [`bg-${progress.type}`, {'progress-bar-striped': progress.striped}, {'progress-bar-animated': progress.animated}]
	}

}

export class State {
	type: number = 0;

	validator(value: number): boolean {
		return (value >= 0 && value <= 100);
	}

}
