








































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { IGameSchedule as Schedule, ScheduledGamePlan, IScheduledGamePlan, IGame as Game } from '@/services/api';


@Component({
	name: 'ScheduleList',
	components: {
		Card
	}
})
export default class ScheduleList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: true
		},
		{
			key: "scheduledGamePlansIds",
			label: this.localize("Schedule"),
			formatter: this.scheduledGamePlansIdsFormatter,
			sortable: false
		},
		{
			key: "numerOfGames",
			label: this.localize("Games"),
			formatter: this.numberOfGamesFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private scheduledGamePlansIdsFormatter(value: Array<number>, key: string, item: Schedule): string {
		let list: Array<IScheduledGamePlan> = this.scheduledGamePlanList
			.filter((element: IScheduledGamePlan): boolean => (value.includes(element.id) && element.day && element.day != null && element.day != undefined))
			.sort(
				(a: IScheduledGamePlan, b: IScheduledGamePlan): number => {
					if(!a || !b || !a.day || !b.day) return 0;
					return a.day.getTime() - b.day.getTime()
			});
		let name: string = this.localize("undefined");
		if(list[0] && list[0].day) name = this.formatDate(list[0].day);
		if(list[list.length - 1] && list[list.length - 1].day) name += " - " + this.formatDate(list[list.length - 1].day);
		return name;
	}

	private numberOfGamesFormatter(value: Array<number>, key: string, item: Schedule): string {
		let name: string = this.localize("undefined");
		if(item.scheduledGamePlansIds && Array.isArray(item.scheduledGamePlansIds)) name = item.scheduledGamePlansIds.length + "";
		return name;
	}

	private formatDate(value: Date): string {
		let dd: number = value.getDate();
		let mm: number = value.getMonth() + 1;
		let yyyy: number = value.getFullYear();
		return yyyy + '-' + (mm < 10 ? "0" + mm: mm) + '-' + (dd < 10 ? "0" + dd: dd);		
	}

}
