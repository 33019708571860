





















import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component({
	name: "l-checkbox"
})
export default class Checkbox extends Vue {
	cbId: string = '';

	@Model('input', { type: [Array, Boolean] })
	@Prop({ type: [Array, Boolean] })
	checked!: Array<any> | boolean;

	@Prop({ type: [Boolean, String] })
	disabled: boolean | string;

	@Prop({ type: Boolean })
	inline: boolean;

	get model() {
		return this.checked;
	}
	set model(check: Array<any> | boolean) {
		this.$emit('input', check);
	}

	get inlineClass() {
		if (this.inline) {
			return "checkbox-inline";
		}
	}

	created () {
		this.cbId = Math.random().toString(16).slice(2);
	}
}
