















































import { Vue, Component, Prop, Inject, Provide } from 'vue-property-decorator';
import PerfectScroll from 'perfect-scrollbar';
import {Link } from './link';

import '../../../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';



@Component({
	name: "sidebar"
})
export default class Sidebar extends Vue {

	@Prop({ type: String, default: "" })
	title: string;

	@Prop({ type: String, default: "Collapse" })
	collapse: string;

	@Prop({ type: String, default: "Expand" })
	expand: string;

	@Prop({
		type: String,
		default: "black",
		validator: (value: string): boolean => {
			let acceptedValues = [
				"",
				"blue",
				"azure",
				"green",
				"orange",
				"red",
				"purple",
				"black"
			];
			return acceptedValues.indexOf(value) !== -1;
		}
	})
	backgroundColor: string = "black";

	@Prop({ type: String, default: "" })
	backgroundImage: string = "";

	@Prop({
		type: String,
		default: "success",
		validator: value => {
			let acceptedValues = [
				"primary",
				"info",
				"success",
				"warning",
				"danger"
			];
			return acceptedValues.indexOf(value) !== -1;
		}
	})
	activeColor: string = "success";

	@Prop({ type: String, default: "" })
	logo: string;

	@Prop({ type: Array, default: () => new Array<Link>() })
	sidebarLinks: Array<Link>;

	//@Provide("autoClose")
	@Prop({ type: Boolean, default: true })
	autoClose: boolean = true;

	get sidebarStyle() {
		return {
			backgroundImage: `url(${this.backgroundImage})`
		};
	}

	mounted() {
		this.initScrollBarAsync();
	}

	beforeDestroy() {
		if ((<any>this).$sidebar.showSidebar) {
			(<any>this).$sidebar.showSidebar = false;
		}
	}

	async initScrollBarAsync() {
		//const PerfectScroll: any = await import("perfect-scrollbar");
		let perfectScroll = <any>PerfectScroll;
		if (perfectScroll.initialize) perfectScroll.initialize(this.$refs.sidebarScrollArea);
	}

	minimizeSidebar() {
		(<any>this).$sidebar.toggleMinimize();
	}

}
