import router from '@/router';
import { AuthenticationService } from '@/services';


export namespace Authenticator {
	let accountService: AuthenticationService = new AuthenticationService();
	let token: string = "";

	export function logIn(email: string, password: string): void {
		accountService.logIn(email, password)
			.then((token: string): void => {
				Authenticator.setToken(token);
				router.push("/");
			});
	}

	export function handleUnAuthorized(): void {
		setToken("");
		router.push("/login");
	}

	export function getToken(): string {
		let value: string;
		if (typeof(Storage) !== "undefined") {
			value = localStorage.getItem("bingo.no - token");
		} else {
			value = token;
		}
		return value;
	}
	export function setToken(value: string): void {
		if (typeof(Storage) !== "undefined") {
			localStorage.setItem("bingo.no - token", value);
		} else {
			token = value;
		}
	}

}
