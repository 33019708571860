





















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError, ColorUtilities, TimeSpan } from '@/utilities';
import { LogLevel, IBlock } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import BlockPicker from './BlockPicker.vue';
import { IGamePlan as GamePlan, IGame as Game, IPrizePatternGroup as PrizePatternGroup, ISheetType as SheetType, IGridType as GridType, IGridSize as GridSize, GameBlock, BlockId, BlockType, LoopingGamesBlock } from '@/services/api';
import Sortable from 'sortablejs';

let tinycolor = require("tinycolor2");


@Component({
	components: {
		Card,
		"block-picker": BlockPicker
	},
})
export default class BlockList extends BaseList {
	private showModal: boolean = false;
	private sortableList: Array<IBlock> = new Array<IBlock>();

	@Prop({ type: Array, default: new Array<BlockId>() })
	public blockIds: Array<BlockId>;

	@Watch("blockIds")
	public reapplySortable(val: Array<BlockId>, oldVal: Array<BlockId>): void {
		this.sortableList = new Array<IBlock>();
		val.forEach((element: BlockId): void => {
			let block: IBlock;
			if(element.type as BlockType == BlockType.Game) {
				block = this.gameBlockList.find((gameBlock: IBlock) => gameBlock.id.id == element.id);
			} else if(element.type as BlockType == BlockType.Looping) {
				block = this.loopingGamesBlockList.find((loopBlock: IBlock) => loopBlock.id.id == element.id);
			} else if(element.type as BlockType == BlockType.Link) {
				block = this.linkedRoomBlockList.find((linkBlock: IBlock) => linkBlock.id.id == element.id);
			}
			if(block) this.sortableList.push(block);
		});
	}

	public mounted() {
		this.reapplySortable(this.blockIds, this.blockIds);
		let listElement: HTMLElement = document.getElementById('blockList').querySelector('tbody');
		let self = this;
		self.$nextTick(() => {
			let sortable = Sortable.create(listElement, { onEnd: self.moveItem });
		});
	}

	private fields: Array<any> = [
		{
			key: "name",
			label: this.localize("Name"),
			sortable: false
		},
		{
			key: "color",
			label: this.localize("Color"),
			sortable: false
		},
		{
			key: "numberOfGames",
			label: this.localize("Games"),
			formatter: this.numberOfGamesFormatter,
			sortable: false
		},
		{
			key: "duration",
			label: this.localize("Duration"),
			formatter: this.durationFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private numberOfGamesFormatter(value: string, key: string, item: IBlock): string {
		if(item.id.type == BlockType.Link) return;
		let block: GameBlock | LoopingGamesBlock = item.id.type == BlockType.Game ? <GameBlock>item: <LoopingGamesBlock>item;
		return (block.gameIds || new Array<number>()).length + "";
	}

	private durationFormatter(value: string, key: string, item: number): string {
		let dur: TimeSpan = new TimeSpan();
		if(value) dur = TimeSpan.parse(value);
		return dur.toString("HH:MM:SS");
	}

	private getBlockColor(value: number): string {
		let tColor = tinycolor(ColorUtilities.argbToVueColor(value).rgba);
		return tColor.toHexString();
	}

	/** @method addItem Navigates the user to a form to create a new item for the current list. */
	protected addItem(): void {
		this.showModal = true;
	}

	/** @method deleteItem Deletes the item in the list
	 * @param index The list index of the item to delete. This is used remove the item from locally cached data.
	 * @param item The item to delete. */
	protected deleteItem(index: number, item: { id: number}, type: string = ""): void {
		if (!confirm(this.localize("Are you sure you want to delete this item?"))) return;
		this.blockIds.splice(index, 1);
		Logger.log(LogLevel.Info, "User removed " + (<Game>item).name + " to the Game Plan.");
	}

	private moveItem(e: { newIndex: number, oldIndex:number }): void {
		this.sortableList.splice(e.newIndex, 0, this.sortableList.splice(e.oldIndex, 1)[0]);
		this.$nextTick(() => {
			this.$emit("changed", this.sortableList.map((element: IBlock): BlockId => element.id));
		});		
		Logger.log(LogLevel.Info, "User moved a Block in the Game Plan.");
	}

	private addBlock(value: BlockId): void {
		this.blockIds.push(value);
		this.showModal = false;
		Logger.log(LogLevel.Info, "User added " + value.id + " to the Game Plan.");	//TODO: fix name / type extraction
	}

}
