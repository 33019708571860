












































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { IGridType as GridType, IGridSize as GridSize } from '@/services/api';


@Component({
	name: 'GridTypeList',
	components: {
		Card
	}
})
export default class GridTypeList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "gridSize",
			label: this.localize("Size"),
			formatter: this.gridSizeFormatter,
			sortable: true
		},
		{
			key: "fields",
			label: this.localize("Fields"),
			formatter: this.fieldsFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private fieldsFormatter(value: any, key: string, item: GridType): string {
		let returnValue: string = "";
		if(item.blanksPerRow && item.blanksPerRow > 0) {
			returnValue = item.blanksPerRow + " " + this.localize(item.blanksPerRow > 1 ? "blanks": "blank") + " " + this.localize("per row");
		} else if(item.freeplayFields && item.freeplayFields.length > 0) {
			returnValue = this.localize("Freeplay fields") + ": " + item.freeplayFields.join(',');
		} else {
			returnValue = this.localize("All included");
		}
		return returnValue;
	}

	private gridSizeFormatter(value: GridSize, key: string, item: GridType): string {
		let name: string = "" + value;
		if(value) name = this.numberOfBallsEnum[value.numberOfBalls] + " " + " (" + value.rows + " x " + value.columns + ")";
		return name;
	}

}
