




















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { Prize, IPrizePatternGroup, PrizePatternGroup, ExtraRule, ExtraRuleType, PayoutSource } from '@/services/api';


@Component({
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"fg-input": fgInput
	}
})
export default class PrizePicker extends BaseForm {
	protected item: Prize = new Prize({	// Initializing is required as PrizeList uses extraRule in its presentation.
		id: undefined,
		extraRule: new ExtraRule({
			type: ExtraRuleType.None,
			value: undefined,
			potId: undefined,
			potPayoutPercent: undefined,
			minimumPotPayout: undefined,
			fixedPayoutValue: 0,
			fixedPayoutSource: PayoutSource.Pot
		}),
		turnoverPayoutPercent: undefined,
		fixedPayoutValue: undefined,
		prizePatternGroupId: undefined,
		uniqueForEachGrid: undefined,
		onlyWinHighestPatternPrize: undefined
	});

	public created() {
		this.itemId = undefined;
		this.item.turnoverPayoutPercent = 0;
	}

	protected get loadItem(): (itemId: number) => void {
		return ((itemId: number) => {});
	}

	/** @method saveItem Saves the the item operated on by this form, then navigates back to the list for this item. */
	protected saveItem() : void{
		this.$emit("addPrize", this.item);
	}

}
