import Vue from 'vue';
import { LogLevel } from '@/models';
import { Logger, Authenticator } from '@/utilities';


export function handleError(err: Error, vm: Vue, info: string): boolean {
	if ((<any>err).response && (<any>err).response.status && (<any>err).response.status == 401) {
		Logger.log(LogLevel.Warn, "Global handleError - Unauthorized! - " + info + " -> " + err.message + "\n");
		Authenticator.handleUnAuthorized();
		return false;
	}
	Logger.log(LogLevel.Error, "Global handleError - " + info + " -> " + err.message + "\n" + err.stack);
	return false;
}
