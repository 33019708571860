import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, IPrizePatternGroup, ResponseOfIEnumerableOfPrizePatternGroup, ResponseOfPrizePatternGroup, IResponseOfPrizePatternGroup, PrizePatternGroup, ResponseOfInt32, Response } from './api';


/**
 * @description Service for handling Prize Pattern Groups on the server.
 * @extends BaseWebSocketService
 */
export class PrizePatternGroupService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<IPrizePatternGroup>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfPrizePatternGroup> => {
				return hub.getPrizePatternGroups();
			})
			.then((result: ResponseOfIEnumerableOfPrizePatternGroup): Array<IPrizePatternGroup> => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "PrizePatternGroupService.getAll() - No Prize Pattern Groups found!");
					return new Array<IPrizePatternGroup>();
				}
				Logger.log(LogLevel.Debug, "PrizePatternGroupService.getAll() - Server Response: " + result.result.length, result.result);
				return result.result;
			});
	}

	public getItem(id: number): Promise<IPrizePatternGroup | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfPrizePatternGroup> => {
				return hub.getPrizePatternGroup(id);
			}).then((result: IResponseOfPrizePatternGroup) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "PrizePatternGroupService.getItem(" + id + ") - No Prize Pattern Group with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "PrizePatternGroupService.getItem(" + id + ") - Server Response: ", result.result);
				return result.result;
			});
	}

	public createItem(item: PrizePatternGroup): Promise<number> {
		Logger.log(LogLevel.Debug, "PrizePatternGroupService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createPrizePatternGroup(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "PrizePatternGroupService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "PrizePatternGroupService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: PrizePatternGroup): Promise<void> {
		Logger.log(LogLevel.Debug, "PrizePatternGroupService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updatePrizePatternGroup(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "PrizePatternGroupService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "PrizePatternGroupService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deletePrizePatternGroup(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "PrizePatternGroupService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "PrizePatternGroupService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}
}
