














































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { ISheetType as SheetType, IGridType as GridType, IGridSize as GridSize } from '@/services/api';


@Component({
	name: 'SheetTypeList',
	components: {
		Card
	}
})
export default class SheetTypeList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: true
		},
		{
			key: "color",
			label: this.localize("Color")
		},
		{
			key: "gridTypeId",
			label: this.localize("Sheet Type"),
			formatter: this.gridTypeIdFormatter
		},
		{
			key: "gridsPerSheet",
			label: this.localize("Grids Per Sheet"),
			formatter: this.gridsPerSheetFormatter
		},
		{
			key: "price",
			label: this.localize("Price"),
			formatter: this.priceFormatter
		},
		{
			key: "actions",
			label: this.localize("Actions")
		}
	];

	private gridTypeIdFormatter(value: number, key: string, item: SheetType): string {
		let name: string = "" + value;
		if(!this.gridTypeList || ! Array.isArray(this.gridTypeList)) return name;
		let type: GridType = this.gridTypeList.find((item: GridType) => item.id == value);
		if(!type) return name;
		let size: GridSize =  type.gridSize;
		if(size) name = this.numberOfBallsEnum[size.numberOfBalls] + " " + " (" + size.rows + " x " + size.columns + ")";
		if(type.freeplayFields && Array.isArray(type.freeplayFields)) name += " " + this.localize("with Freeplay");
		if(type.blanksPerRow && type.blanksPerRow > 0) name += " " + this.localize("with") + " " + type.blanksPerRow + " " +  this.localize("blanks / row");
		return name;
	}

	private gridsPerSheetFormatter(value: number, key: string, item: SheetType): string {
		return value + "";
	}

	private priceFormatter(value: number, key: string, item: SheetType): string {
		return value + ",-";
	}

}
