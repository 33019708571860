














import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Color } from './color';


@Component({
	name: "l-pagination"
})
export default class Pagination extends Vue {

	@Prop({ type: Number, default: 5 })
	defaultPagesToDisplay: number = 5;

	@Prop(Color)
	color: Color = new Color("");

	@Prop({ type: Number, default: 0 })
	pageCount: number = 0;

	@Prop({ type: Number, default: 10 })
	perPage: number = 10;

	@Prop({ type: Number, default: 0 })
	total: number = 0;

	@Prop({ type: Number, default: 1 })
	value: number = 1;


	range(min: number, max: number): Array<number> {
		let arr: Array<number> = [];
		for (let i = min; i <= max; i++) {
			arr.push(i);
		}
		return arr;
	}

	changePage(item: number) {
		this.$emit('input', item);
	}

	nextPage() {
		if (this.value < this.totalPages) {
			this.$emit('input', this.value + 1);
		}
	}

	prevPage() {
		if (this.value > 1) {
			this.$emit('input', this.value - 1);
		}
	}

	get paginationClass(): string {
		return `pagination-${this.color}`;
	};

	get totalPages(): number {
		if (this.pageCount > 0) return this.pageCount
		if (this.total > 0) {
			return Math.ceil(this.total / this.perPage);
		}
		return 1;
	};

	get pagesToDisplay(): number {
		if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
			return this.totalPages;
		}
		return this.defaultPagesToDisplay;
	};

	get minPage(): number {
		if (this.value >= this.pagesToDisplay) {
			const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
			const newMaxPage = pagesToAdd + this.value;
			if (newMaxPage > this.totalPages) {
				return this.totalPages - this.pagesToDisplay + 1;
			}
			return this.value - pagesToAdd;
		} else {
			return 1;
		}
	};

	get maxPage(): number {
		if (this.value >= this.pagesToDisplay) {
			const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
			const newMaxPage = pagesToAdd + this.value;
			if (newMaxPage < this.totalPages) {
				return newMaxPage;
			} else {
				return this.totalPages;
			}
		} else {
			return this.pagesToDisplay;
		}
	}


	@Watch('perPage')
	onPageSizeChanged(value: number) {
		this.$emit('input', 1);
	};

	@Watch('total')
	onTotalChanged(value: number) {
		this.$emit('input', 1);
	}

}
