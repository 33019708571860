import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel, RoomWithStatus, IRoomWithStatus } from '@/models';
import { AdminHub, ResponseOfInt32, Response, IRoom, ResponseOfIEnumerableOfRoom, IResponseOfRoom, ResponseOfRoom, Room, RoomStatus, ResponseOfRoomStatus, IAdminHubCallbacks, GameStateUpdate, GameSchedule } from './api';


/**
 * @description Service for handling Rooms on the server.
 * @extends BaseWebSocketService
 */
export class RoomService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<RoomWithStatus>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfRoom> => {
				return hub.getRooms();
			})
			.then((result: ResponseOfIEnumerableOfRoom): Array<RoomWithStatus> => {
				let returnValue = new Array<RoomWithStatus>();
				if(!result.result) {
					Logger.log(LogLevel.Debug, "RoomService.getAll() - No Rooms found!");
					return returnValue;
				}
				returnValue = result.result.map((element: Room): RoomWithStatus => new RoomWithStatus(element));
				Logger.log(LogLevel.Debug, "RoomService.getAll() - Server Response: " + result.result.length, returnValue);
				return returnValue;
			});
	}

	public getItem(id: number): Promise<RoomWithStatus | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfRoom> => {
				return hub.getRoom(id);
			}).then((result: IResponseOfRoom) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "RoomService.getItem(" + id + ") - No Room with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "RoomService.getItem(" + id + ") - Server Response: ", result.result);
				return new RoomWithStatus(result.result);
			});
	}

	public createItem(item: Room): Promise<number> {
		Logger.log(LogLevel.Debug, "RoomService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createRoom(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "RoomService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "RoomService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: Room): Promise<void> {
		Logger.log(LogLevel.Debug, "RoomService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updateRoom(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "RoomService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "RoomService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteRoom(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "RoomService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "RoomService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}

	public roomStatus(id: number): Promise<RoomStatus> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfRoomStatus> => {
				return hub.roomStatus(id);
			}).then((result: ResponseOfRoomStatus): RoomStatus => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "RoomService.roomStatus(" + id + ") - Failed to get room Status!", result);
				}
				Logger.log(LogLevel.Debug, "RoomService.roomStatus(" + id + ") - Server Response: success", result);
				return result.result;
			});
	}

	public openRoom(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.openRoom(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "RoomService.openRoom(" + id + ") - Failed to open Room!", result);
				}
				Logger.log(LogLevel.Debug, "RoomService.openRoom(" + id + ") - Server Response: success", result);
				return;
			});
	}

	public closeRoom(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.closeRoom(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "RoomService.closeRoom(" + id + ") - Failed to close Room!", result);
				}
				Logger.log(LogLevel.Debug, "RoomService.closeRoom(" + id + ") - Server Response: success", result);
				return;
			});
	}

	public forceCloseRoom(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.forceCloseRoom(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "RoomService.forceCloseRoom(" + id + ") - Failed to force close Room!", result);
				}
				Logger.log(LogLevel.Debug, "RoomService.forceCloseRoom(" + id + ") - Server Response: success", result);
				return;
			});
	}

	public restartRoom(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.restartRoom(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "RoomService.restartRoom(" + id + ") - Failed to restart Room!", result);
				}
				Logger.log(LogLevel.Debug, "RoomService.restartRoom(" + id + ") - Server Response: success", result);
				return;
			});
	}

	public subscribeToStateChanges(eventHandler: (roomStatus: RoomStatus) => void): void {
		let eventHandlers: IAdminHubCallbacks = {
			roomStateChanged: eventHandler,
			gameStateUpdate: (gameStateUpdate: GameStateUpdate): void => {},
			gameStarted: (id: number): void => {},
			domainEvent: (eventData: any): void => {}
		};
		this.AdminHub
			.then((hub: AdminHub): void => {
				hub.registerCallbacks(eventHandlers);
			});
	}



}
