
export enum TicketColor {
	//White = -1,
	//Yellow = -5632,
	Green = -16744448,	//Default
	Orange = -23296,
	Blue = -16776961,
	Red = -65536,
	//Purple = -5298945,
	//Black = -16777216
}
