import Vue from 'vue';
//import { Vue } from 'vue-property-decorator';
import { CreateElement } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faTrashAlt, faTimes, faPlus, faCheckSquare, faDoorOpen, faDoorClosed, faSync as farSync, faQuestionCircle as farQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { faHotel, faSync, faLineColumns, faCube, faRepeat, faLink, faGamepad, faCauldron, faLayerGroup, faGameBoard, faLayerPlus, faTh, faUsers, faCog, faHouseUser, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { faPlay, faStop, faBars, faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { faStop as fadStop } from '@fortawesome/pro-duotone-svg-icons';
import {  } from '@fortawesome/pro-regular-svg-icons';
// import { fal } from '@fortawesome/pro-light-svg-icons';
// import { far } from '@fortawesome/pro-regular-svg-icons';
// import { fas } from '@fortawesome/pro-solid-svg-icons';
// import { fad } from '@fortawesome/pro-duotone-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons';
import App from './App.vue';
import router from './router';
import store from './store'
import BootstrapVue, { TooltipPlugin } from 'bootstrap-vue';
import SideBar from './components/lbd/SidebarPlugin';
import { handleError, handleWarnings } from './utilities';
import notifyjs from 'vue-notifyjs';
import VTooltip from "v-b-tooltip.hover";
import helpDirective from '@/directives/help.directive';


// style imports
import 'vue-notifyjs/themes/default.scss';
import './assets/sass/light-bootstrap-dashboard.scss';
import './assets/sass/element_variables.scss';
import './assets/sass/lbd-overrides.scss';
import './assets/sass/custom.scss';


// Font Awesome
Vue.component('icon', FontAwesomeIcon);
library.add(faEdit, faTrashAlt, faTimes, faPlus, faCheckSquare, farSync, farQuestionCircle);	// Regular
library.add(faHotel, faSync, faLineColumns, faCube, faRepeat, faLink, faGamepad, faCauldron, faLayerGroup, faGameBoard, faLayerPlus, faTh, faUsers, faCog, faHouseUser, faQuestionCircle);	//Light
library.add(faPlay, faStop, faBars, faEllipsisV);	// Solid
library.add(fadStop);	// Duotione
//library.add(fal, far, fas, fab);

// global usings
Vue.use(BootstrapVue);
Vue.use(TooltipPlugin);
Vue.use(SideBar);
Vue.use(notifyjs);
//Vue.use(VTooltip);

Vue.directive('bingo-help', helpDirective);

Vue.config.productionTip = false;
Vue.config.errorHandler = handleError;
Vue.config.warnHandler = handleWarnings;


new Vue({
	router,
	store,
	render: (h: CreateElement) => h(App),
}).$mount('#app');
