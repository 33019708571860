import { Store, Action, ActionTree, ActionContext } from 'vuex';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';


export function resetStateForNewCustomer (store: ActionContext<any, any>) {
	Logger.log(LogLevel.Trace, "Vuex.Store.resetStateForNewCustomer() - START");
	store.dispatch('cacheStore/resetStateForNewCustomer');
	Logger.log(LogLevel.Trace, "Vuex.Store.resetStateForNewCustomer() - END");
}

/** @description Clear all data and set application in known state */
export function resetStateToRecoverFromError (store: ActionContext<any, any>) {
	store.dispatch('cacheStore/resetStateToRecoverFromError');
}


export default <ActionTree<any, any>> {
	resetStateForNewCustomer,
	resetStateToRecoverFromError
}
