





















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
	name: "card"
})
export default class Card extends Vue {

	@Prop(String)
	title: string;

	@Prop(String)
	subTitle: string;

}
