
export class Link {

	name: string = "";
	icon: string = "";
	path: string = "";
	isRoute: boolean = false;
	collapsed: boolean;
	children: Array<Link> = [];

	constructor(name: string, path: string, children: Array<Link>) {
		this.name = name;
		this.path = path;
		this.children = children;
	}

}
