import { VNode } from "vue";

'use strict';

var _dom = require('element-ui/lib/utils/dom');

export class Transition {

	beforeEnter(el: any) {
		_dom.addClass(el, 'collapse-transition');
		if (!el.dataset) el.dataset = {};

		el.dataset.oldPaddingTop = el.style.paddingTop;
		el.dataset.oldPaddingBottom = el.style.paddingBottom;

		el.style.height = '0';
		el.style.paddingTop = "0";
		el.style.paddingBottom = "0";
	}

	enter(el: HTMLElement, done: () => void) {
		el.dataset.oldOverflow = el.style.overflow;
		if (el.scrollHeight !== 0) {
			el.style.height = el.scrollHeight + 'px';
			el.style.paddingTop = el.dataset.oldPaddingTop;
			el.style.paddingBottom = el.dataset.oldPaddingBottom;
		} else {
			el.style.height = '';
			el.style.paddingTop = el.dataset.oldPaddingTop;
			el.style.paddingBottom = el.dataset.oldPaddingBottom;
		}

		el.style.overflow = 'hidden';
		done();
	}

	afterEnter(el: HTMLElement) {
		// for safari: remove class then reset height is necessary
		_dom.removeClass(el, 'collapse-transition');
		el.style.height = '';
		el.style.overflow = el.dataset.oldOverflow;
	}

	beforeLeave(el: any) {
		if (!el.dataset) el.dataset = {};
		el.dataset.oldPaddingTop = el.style.paddingTop;
		el.dataset.oldPaddingBottom = el.style.paddingBottom;
		el.dataset.oldOverflow = el.style.overflow;

		el.style.height = el.scrollHeight + 'px';
		el.style.overflow = 'hidden';
	}

	leave(el: HTMLElement, done: () => void) {
		if (el.scrollHeight !== 0) {
			// for safari: add class after set height, or it will jump to zero height suddenly, weired
			_dom.addClass(el, 'collapse-transition');
			el.style.height = "0";
			el.style.paddingTop = "0";
			el.style.paddingBottom = "0";
		}
		done();
	}

	afterLeave(el: HTMLElement) {
		_dom.removeClass(el, 'collapse-transition');
		el.style.height = '';
		el.style.overflow = el.dataset.oldOverflow;
		el.style.paddingTop = el.dataset.oldPaddingTop;
		el.style.paddingBottom = el.dataset.oldPaddingBottom;
	}

}

export default {
	name: 'ElCollapseTransition',
	functional: true,
	render: function render(createElement: (tagName: string, options: any, children: any) => VNode, context: any) {
		let children = context.children;

		let data = {
			on: new Transition()
		};

		return createElement('transition', data, children);
	}
};
