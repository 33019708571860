



import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';
import { Logger, handleError, ColorUtilities } from '@/utilities';
import { LogLevel, TicketColor, NumberOfBalls, RoomWithStatus, IUser as User } from '@/models';
import { IPot as Pot, ExtraRuleType, IGridSize as GridSize, IGridType as GridType, ISheetType as SheetType, IGameSchedule as Schedule, IScheduledGamePlan as ScheduledGamePlan, IPrize as Prize, IPrizePattern as PrizePattern, IPrizePatternGroup as PrizePatternGroup, IGame as Game, IGamePlan as GamePlan, GameState, GameBlock, LinkedRoomBlock, RoomState, Room, LoopingGamesBlock, BlockId, BingoBoosterRoom, BingoBoosterHall } from '@/services/api';


@Component({})
export default class BaseList extends BaseComponent {
//#region Enum Fields

	/** @field numberOfBallsEnum is used to access the NumberOfBalls enum in child templates */
	protected numberOfBallsEnum: typeof NumberOfBalls = NumberOfBalls;

	/** @field ticketColorEnum is used to access the TicketColor enum in child templates */
	protected ticketColorEnum: typeof TicketColor = TicketColor;

	/** @field extraRuleTypeEnum is used to access the ExtraRuleType enum in child templates */
	protected extraRuleTypeEnum: typeof ExtraRuleType = ExtraRuleType;

	/** @field gameStateEnum is used to access the RunningGameState enum in child templates */
	protected gameStateEnum: typeof GameState = GameState;

	/** @field roomStateEnum is used to access the ExtraRuleType enum in child templates */
	protected roomStateEnum: typeof RoomState = RoomState;

//#endregion

	/** field itemType identifies the type og object being handled by the child form. */
	private itemType: string = this.$options.name.replace("List", "");

	public created() {
	}

	public mounted() {
		this.$nextTick(() => {
			window.addEventListener("keydown", this.addItemWithCtrlPluss, false);
		});
	}

	public beforeDestroy() {
		this.$nextTick(() => {
			window.removeEventListener("keydown", this.addItemWithCtrlPluss);
		});
	}

//#region Browser Event Handlers (Ctrl +)

	public addItemWithCtrlPluss(event: KeyboardEvent): boolean {
		if ((window.navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey) && event.key == "+") {
			event.preventDefault();
			this.addItem();
			return false;
		}
	}

//#endregion

//#region Properties

	/** @property isDataLoaded used for determaning when all list items are loaded from server.
	 * Required for property emptyResultText to display text in list identifying no content. */
	protected get isDataLoaded(): boolean {
		return this.$store.state.cacheStore.isDataLoaded(this.itemType);
	}

	/** @property emptyResultText reactive property displaying no content text after (no) data loading is complete. */
	protected get emptyResultText(): string {
		if(this.isDataLoaded) return this.localize('There are no items to show');
		return "";
	}

	protected get bingoBoosterRoomList(): Array<BingoBoosterRoom> {
		return this.$store.state.cacheStore.bingoBoosterRoomList;
	}

	protected get bingoBoosterHallList(): Array<BingoBoosterHall> {
		return this.$store.state.cacheStore.bingoBoosterHallList;
	}

	protected get gamePlanList(): Array<GamePlan> {
		return this.$store.state.cacheStore.gamePlanList;
	}

	protected get linkedRoomBlockList(): Array<LinkedRoomBlock> {
		return this.$store.state.cacheStore.linkedRoomBlockList;
	}

	protected get gameBlockList(): Array<GameBlock> {
		return this.$store.state.cacheStore.gameBlockList;
	}

	protected get loopingGamesBlockList(): Array<LoopingGamesBlock> {
		return this.$store.state.cacheStore.loopingGamesBlockList;
	}

	protected get gameList(): Array<Game> {
		return this.$store.state.cacheStore.gameList;
	}

	protected get potList(): Array<Pot> {
		return this.$store.state.cacheStore.potList;
	}

	protected get prizePatternGroupList(): Array<PrizePatternGroup> {
		return this.$store.state.cacheStore.prizePatternGroupList;
	}

	protected get prizePatternList(): Array<PrizePattern> {
		return this.$store.state.cacheStore.prizePatternList;
	}

	protected get prizeList(): Array<Prize> {
		return this.$store.state.cacheStore.prizeList;
	}

	protected get scheduleList(): Array<Schedule> {
		return this.$store.state.cacheStore.scheduleList;
	}

	protected get roomList(): Array<RoomWithStatus> {
		return this.$store.state.cacheStore.roomList;
	}

	protected get scheduledGamePlanList(): Array<ScheduledGamePlan> {
		return this.$store.state.cacheStore.scheduledGamePlanList;
	}

	protected get sheetTypeList(): Array<SheetType> {
		return this.$store.state.cacheStore.sheetTypeList;
	}

	protected get gridTypeList(): Array<GridType> {
		return this.$store.state.cacheStore.gridTypeList;
	}

	protected get userList(): Array<User> {
		return this.$store.state.cacheStore.userList;
	}

//#endregion

//#region List Actions

	/** @method addItem Navigates the user to a form to create a new item for the current list. */
	protected addItem(): void {
		this.$router.push(this.$route.path + "/create");
	}

	/** @method editItem Navigates the user to a form editing the selected item.
	 * @param item The item to edit, Type: any */
	protected editItem(item: any): void {
		this.$router.push(this.$route.path + "/" + item.id);
	}

	/** @method deleteItem Deletes the item in the list
	 * @param index The list index of the item to delete. This is used remove the item from locally cached data.
	 * @param item The item to delete. */
	protected deleteItem(index: number, item: { id: number | BlockId}, type: string = ""): void {
		if (!confirm(this.localize("Are you sure you want to delete this item?"))) return;
		this.$store.state.cacheStore.deleteItem(this.itemType, item.id)
			.then(() => {})
			.catch((error: Error) => {
				this.$notify({
					title: this.localize("Error"),
					message: this.localize("Failed to delete data!"),
					type: "warning"
				});
				handleError(error, this, "BaseForm.deleteItem() -> " + this.itemType + "Service..deleteItem(" + item.id + ")");
			});
	}

//#endregion

	/** @method mapItem2Color Override in child Components to map paramter sent to getRgba into a color number.
	 * This method is used in getRgba to map number value into CSS rgba() property. */
	protected mapItem2Color(itemId: any): TicketColor {
		return itemId;
	}

	/** @method getRgba Used to map (aRGB) color number value into CSS rgba() property. */
	protected getRgba(itemId: any): string {
		let color: TicketColor = this.mapItem2Color(itemId);
		return ColorUtilities.colorNumber2cssRgbaString(color);
	}

}
