
import { english, englishDatepicker } from "./en";
import { norwegian, norwegianDatepicker } from "./no";
import { Language } from '@/models/datepicker-language';

export * from './languages';

export let localizations: Dictionary<Dictionary<string>> = {};
localizations["en"] = english;
localizations["no"] = norwegian;
localizations["nb"] = norwegian;

export let datepickerLocalizations: Dictionary<Language> = {};
datepickerLocalizations["en"] = englishDatepicker;
datepickerLocalizations["no"] = norwegianDatepicker;
datepickerLocalizations["nb"] = norwegianDatepicker;
