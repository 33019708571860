















import { Vue, Component, Emit, Inject, Model, Prop, Provide, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';

@Component({})
export default class ContentFooter extends BaseComponent {
}
