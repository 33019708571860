
























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, ColorUtilities, TimeSpan, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import GamePicker from './GamePicker.vue';
import { IGamePlan as GamePlan, IGame as Game, IPrizePatternGroup as PrizePatternGroup, ISheetType as SheetType, IGridType as GridType, IGridSize as GridSize } from '@/services/api';
import Sortable from 'sortablejs';

let tinycolor = require("tinycolor2");


@Component({
	components: {
		Card,
		"game-picker": GamePicker
	},
})
export default class GameList extends BaseList {
	private showModal: boolean = false;
	private sortableList: Array<number> = new Array<number>();
	private startTime: string = "00:00:00";

	@Prop({ type: Array, default: new Array<number>() })
	public gameIds: Array<number>;

	@Watch("gameIds")
	public reapplySortable(val: Array<number>, oldVal: Array<number>): void {
		if(this.sortableList.length <= 0) this.sortableList = val;
	}

	public mounted() {
		this.sortableList = this.gameIds.filter((item: number) => item);	// clone gameIds
		let listElement: HTMLElement = document.getElementById('gameList').querySelector('tbody');
		let self = this;
		self.$nextTick(() => {
			let sortable = Sortable.create(listElement, { onEnd: self.moveItem });
		});
	}

	private fields: Array<any> = [
		{
			key: "startTime",
			label: this.localize("Start"),
			sortable: false
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: false
		},
		{
			key: "color",
			label: this.localize("Color"),
			sortable: false
		},
		{
			key: "type",
			label: this.localize("Type"),
			formatter: this.typeFormatter,
			sortable: false
		},
		{
			key: "drawDelay",
			label: this.localize("Draw Delay"),
			formatter: this.drawDelayFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private typeFormatter(value: any, key: string, item: number): string {
		let game: Game = this.gameList.find((element: Game) => element.id == item);
		if(!game) return "";
		let sheet: SheetType = this.sheetTypeList.find((elemenmt: SheetType) => elemenmt.id == game.sheetTypeIds[0]);
		if(!sheet) return "";
		let type: GridType = this.gridTypeList.find((element: GridType) => element.id == sheet.gridTypeId);
		if(!type || !type.gridSize) return "";
		return this.numberOfBallsEnum[type.gridSize.numberOfBalls];
	}

	private drawDelayFormatter(value: any, key: string, item: number): string {
		let dur: TimeSpan = new TimeSpan();
		let game: Game = this.gameList.find((element: Game) => element.id == item);
		if(game && game.drawInfo && game.drawInfo.normalDrawDelay) dur = TimeSpan.parse(game.drawInfo.normalDrawDelay, "HH:MM:SS.FF");
		return dur.toString("S.f");
	}

	private getStartTime(value: any, index: number): string {
		let startTime = TimeSpan.parse(this.startTime);
		for(let i: number = 0; i < index; i++) {
			let game: Game = this.gameList.find((element: Game) => element.id == this.gameIds[i]);
			if(game) startTime = startTime.add(TimeSpan.parse(game.maxDuration));
		}
		return startTime.toString("HH:MM");	// Dropping seconds in this presentation
	}

	private getName(value: number): string {
		let game: Game = this.gameList.find((element: Game) => element.id == value);
		if(!game) return "";
		return game.name;
	}

	private getGameColor(value: number): string {
		let game: Game = this.gameList.find((element: Game) => element.id == value);
		if(!game) return "";
		let tColor = tinycolor(ColorUtilities.argbToVueColor(game.color).rgba);
		return tColor.toHexString();
	}

	/** @method addItem Navigates the user to a form to create a new item for the current list. */
	protected addItem(): void {
		this.showModal = true;
	}

	/** @method deleteItem Deletes the item in the list
	 * @param index The list index of the item to delete. This is used remove the item from locally cached data.
	 * @param item The item to delete. */
	protected deleteItem(index: number, item: { id: number}, type: string = ""): void {
		if (!confirm(this.localize("Are you sure you want to delete this item?"))) return;
		this.gameIds.splice(index, 1);
		Logger.log(LogLevel.Info, "User removed " + (<Game>item).name + " to the GamePlan.");
	}

	private moveItem(e: { newIndex: number, oldIndex:number }): void {
		let clonedItems: Array<number> = new Array<number>(...this.sortableList);	// clone array
		clonedItems.splice(e.newIndex, 0, clonedItems.splice(e.oldIndex, 1)[0]);
		this.sortableList = new Array<number>();
		this.$nextTick(() => {
			this.sortableList = clonedItems;
			this.$emit("changed", clonedItems);
		});		
		Logger.log(LogLevel.Info, "User moved a Game in the Game Plan.");
	}

	private addGame(value: Game): void {
		this.gameIds.push(value.id);
		this.showModal = false;
		Logger.log(LogLevel.Info, "User added " + value.name + " to the GamePlan.");
	}

}
