

























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { ISheetType as SheetType, IGridType as GridType, IGame as Game } from '@/services/api';


@Component({
	name: 'game-picker',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"fg-input": fgInput
	}
})
export default class GamePicker extends BaseForm {
	private quantity: Array<number> = new Array<number>();


	public created() {
		this.gameList.forEach((element: Game, index: number, arr: Array<Game>): void => { this.quantity[element.id] = 1 });
	}

	protected get loadItem(): (itemId: number) => void {
		return ((itemId: number) => {});
	}

	private fields: Array<any> = [
		{
			key: "name",
			label: this.localize("Name"),
			sortable: false
		},
		{
			key: "type",
			label: this.localize("Type"),
			formatter: this.typeFormatter,
			sortable: false
		},
		{
			key: "drawDelay",
			label: this.localize("Draw Delay"),
			sortable: false
		},
		{
			key: "quantity",
			label: this.localize("Quantity"),
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private typeFormatter(value: any, key: string, item: Game): string {
		let sheet: SheetType = this.sheetTypeList.find((elemenmt: SheetType) => elemenmt.id == item.sheetTypeIds[0]);
		if(!sheet) return "";
		let type: GridType = this.gridTypeList.find((element: GridType) => element.id == sheet.gridTypeId);
		if(!type || !type.gridSize) return "";
		return this.numberOfBallsEnum[type.gridSize.numberOfBalls]
	}

	/** @method saveItem Saves the the item operated on by this form, then navigates back to the list for this item. */
	protected saveItem() : void{
		if(!this.quantity[this.item.id] || this.quantity[this.item.id] <= 0) {
			this.$emit("addGame", this.item);
			return;
		}
		for(let i = this.quantity[this.item.id]; i > 0; i--) {
			this.$emit("addGame", this.item);
		}
	}

	private pickItem(index: number, item: Game): void {
		this.item = item;
		this.saveItem();
	}

}
