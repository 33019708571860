
































import { Vue, Component, Prop, Watch, Model } from "vue-property-decorator";
import Checkbox from "@/components/lbd/Inputs/Checkbox.vue";
import { Logger, handleError, Localizer } from "@/utilities";
import { LogLevel, Matrix } from "@/models";
import SelectionJs, { SelectionOptions, SelectionEvent } from "@simonwep/selection-js";


@Component({
	name: "grid-designer"
})
export default class GridDesigner extends Vue {
	private matrix: Matrix = new Matrix();
	private selection: SelectionJs;
	private canvasRows: number = 10;
	private canvasColumns: number = 10;
	private isDirty: boolean = false;
	private freePlayToggle: boolean = false;

	@Prop({ type: Number, default: 0 })
	public rows: number;

	@Prop({ type: Number, default: 0 })
	public columns: number;

	@Prop({ type: Array, default: (): Array<number> => [] })
	public freeplayFields: Array<number>;

	@Prop({ type: Number, default: 0 })
	public blanksPerRow!: number;

	@Watch("rows")
	private reconfigureMatrixRows(val: number, oldVal: number) {
		this.matrix.rows = val;
		this.matrix.pattern = this.freeplayFields; //Reapply freeplay fields after Matrix change
		if (oldVal > 0) this.$emit("freeplayFieldsChanged", this.matrix.pattern); // Don't update freeplay fields when initializing
		Logger.log(LogLevel.Trace, "GridDesigner.reconfigureMatrixRows(" + val + ", " + oldVal + ") -> Matrix reconfigured! (" + this.matrix.rows + " x " + this.matrix.columns + ")");
	}

	@Watch("columns")
	private reconfigureMatrixColumns(val: number, oldVal: number) {
		this.matrix.columns = val;
		this.matrix.pattern = this.freeplayFields; //Reapply freeplay fields after Matrix change
		if (oldVal > 0) this.$emit("freeplayFieldsChanged", this.matrix.pattern); // Don't update freeplay fields when initializing
		Logger.log(LogLevel.Trace, "GridDesigner.reconfigureMatrixColumns(" + val + ", " + oldVal + ") -> Matrix reconfigured! (" + this.matrix.rows + " x " + this.matrix.columns + ")");
	}

	@Watch("freeplayFields")
	private updateFreeplayFields(val: Array<number>, oldVal: Array<number>) {
		Logger.log(LogLevel.Trace, "GridDesigner.updateFreeplayFields(" + val + ") -> Repaint freeplay fields!", this.matrix);
		this.matrix.pattern = val;
	}

	created() {
		//let component = this;
		this.selection = SelectionJs.create({	// SelectionOptions
			// Class for the selection-area-element
			class: "selection-area",
			startThreshold: 10,	// px, how many pixels the point should move before starting the selection
			disableTouch: false,
			mode: "touch",
			singleClick: true,	// Enable single-click selection

			// Query selectors from elements from which the siblings can be selected
			//containers: [],

			// Query selectors from elements which can be selected
			selectables: ["div"],
			startareas: [".box-wrap"],	// Query selectors for elements from where a selection can be start
			boundaries: [".box-wrap"],	// Query selectors for elements which will be used as boundaries for the selection
			selectionAreaContainer: 'body',
			scrollSpeedDivider: 10
		});
		this.selection.on("start",(evt: SelectionEvent) => {
			if(this.freePlayToggle) return;
			//if(evt.selected.length > 0 && evt.oe.type != "mousedown") return;
			this.isDirty = true;
			evt.selected.forEach((s: Element) => s.classList.remove('selected'));
			evt.inst.clearSelection();
		});
		this.selection.on("move", (evt: SelectionEvent) => {
			if(this.freePlayToggle) return;
			let minRow: number = this.canvasRows;
			let maxRow: number = 0;
			let minColumn: number = this.canvasColumns;
			let maxColumn: number = 0;
			evt.selected.forEach((s: Element) => s.classList.add('selected'));
			evt.changed.removed.forEach((s: Element) => s.classList.remove('selected'));
			evt.selected.forEach((element: Element) => {
				if(element && element.id && element.id.indexOf('field-') == 0) {
					let coordinate: Array<string> = element.id.split('-');
					let row: number = parseInt(coordinate[1]);
					let column: number = parseInt(coordinate[2]);
					if(row < minRow) minRow = row;
					if(row > maxRow) maxRow = row;
					if(column < minColumn) minColumn = column;
					if(column > maxColumn) maxColumn = column;
				};
			});
			this.matrix.rows = maxRow - minRow + 1;
			this.matrix.columns = maxColumn - minColumn + 1;
		});
		this.selection.on("stop", (evt: SelectionEvent) => {
			if(this.freePlayToggle) return;
			evt.selected.forEach((s: Element) => s.classList.remove('selected'));
			evt.inst.clearSelection();
			this.isDirty = false;
			this.$emit('gridChanged', this.matrix);
		});
	}

	private setFreePlay(evt: { target: HTMLElement }): void {
		const selected = evt.target.classList.contains('selected');
		const freeplay = evt.target.classList.contains('freeplay');
		if (selected) {
			if (!freeplay) {
				evt.target.classList.add('freeplay');
			} else {
				evt.target.classList.remove('freeplay');
			}
			if(evt.target && evt.target.id && evt.target.id.indexOf('field-') == 0) {
				let coordinate: Array<string> = evt.target.id.split('-');
				let row: number = parseInt(coordinate[1]);
				let column: number = parseInt(coordinate[2]);
				Logger.log(LogLevel.Trace, "GridDesigner.created() - Selection.onSelect() -> clicked: " + row + " x " + column );
				this.matrix.toggleValueAt(row, column);
			};
			this.$emit('gridChanged', this.matrix);
		}
	}

	/** @method */
	protected localize(key: string, count: number = 0, hasDecimal: boolean = false): string {
		let isPlural: boolean = (count > 1) ? true : false;
		return(Localizer.get(key, isPlural, hasDecimal));
	}

}
