






import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';
import Card from '@/components/lbd/Cards/Card.vue';

@Component({
  components: {
    Card
  }
})
export default class NotFound extends BaseComponent {}
