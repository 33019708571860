import Vue from 'vue';
import Vuex from 'vuex';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import CasheStore from '@/store/modules/cache';
import Actions from './actions';


Vue.use(Vuex);

let store = new Vuex.Store({
	strict: true,
	modules: {
		cacheStore: new CasheStore()
	},
	actions: Actions,
	plugins: [
	]
});

export default store;
