






















































































































import { Vue, Component, Emit, Inject, Model, Prop, Provide, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';

@Component({})
export default class NavItems extends BaseComponent {

	minimizeSidebar() {
		(<any>this).$sidebar.toggleMinimize();
	}

}
