




















import { Vue, Component, Emit, Inject, Model, Prop, Provide, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';
import ContentFooter from '@/components/layout/ContentFooter.vue';
import DashboardContent from '@/components/layout/Content.vue';
import NavItems from '@/components/layout/NavItems.vue';

let image = require('@/assets/img/icon.svg');


@Component({
	components: {
		ContentFooter,
		DashboardContent,
		NavItems
	}
})
export default class DashboardLayout extends BaseComponent {

	toggleSidebar() {
		if ((<any>this).$sidebar.showSidebar) {
			(<any>this).$sidebar.displaySidebar(false);
		}
	}

	get logo() {
		return image;
	}

}
