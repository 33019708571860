
















import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component({
	name: "l-radio"
})
export default class Radio extends Vue {
	cbId: string = '';

	@Prop({ type: [String, Number] })
	label: string | number;

	@Prop({ type: [Boolean, String] })
	disabled: boolean | string;

	@Model('input', { type: [String, Boolean] })
	@Prop({ type: [String, Boolean] })
	value: string | boolean;

	@Prop({ type: Boolean })
	inline: boolean;

	get model() {
		return this.value;
	}
	set model(value) {
		this.$emit("input", value);
	}

	get inlineClass() {
		if (this.inline) {
			return "radio-inline";
		}
		return "";
	}

	created () {
		this.cbId = Math.random().toString(16).slice(2);
	}
}
