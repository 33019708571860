




































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel, TicketColor } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { ISheetType, SheetType, IGridType as GridType, IGridSize as GridSize } from '@/services/api';


@Component({
	name: 'SheetTypeForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"fg-input": fgInput
	}
})
export default class SheetTypeForm extends BaseForm {

	public get title(): string {
		let title: string = this.isNew ? "New Sheet Type" : "Edit Sheet Type";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			return true;
		});
	}

//#endregion

	private getGridTypeName(type: GridType): string {
		let name: string = "";
		if(!type) return name;
		let size = type.gridSize;
		if(size) name = this.numberOfBallsEnum[size.numberOfBalls] + " " + " (" + size.rows + " x " + size.columns + ")";
		if(type.freeplayFields && Array.isArray(type.freeplayFields)) name += " " + this.localize("with Freeplay");
		if(type.blanksPerRow && type.blanksPerRow > 0) name += " " + this.localize("with") + " " + type.blanksPerRow + " " +  this.localize("blanks / row");
		return name;
	}

	private get maxGrids(): number {
		if(!this.item || !this.item.gridTypeId) return 0;
		let type: GridType = this.gridTypeList.find((item: GridType) => item.id == this.item.gridTypeId);
		if(!type || !type.gridSize) return 0;
		return type.maxGridsPerSheet;
	}

}
