










































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Select, Option, Input } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError, Localizer } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';


@Component({
	name: 'RoomForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"el-input": Input,
		"fg-input": fgInput
	}
})
export default class RoomForm extends BaseForm {

	public get title(): string {
		let title: string = this.isNew ? "New Room" : "Edit Room";
		return this.localize(title);
	}

}
