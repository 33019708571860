
/** @description The severity of the unexpected events to be logged. */
export enum LogLevel {

	/** @description Any error that is forcing a shutdown of the service or application to prevent data loss (or further data loss). I reserve these only for the most heinous errors and situations where there is guaranteed to have been data corruption or loss. */
	Fatal = "Fatal",

	/** @description Any error which is fatal to the operation, but not the service or application (can't open a required file, missing data, etc.). These errors will force user (administrator, or direct user) intervention. These are usually reserved (in my apps) for incorrect connection strings, missing services, etc. */
	Error = "Error",

	/** @description Anything that can potentially cause application oddities, but for which I am automatically recovering. (Such as switching from a primary to backup server, retrying an operation, missing secondary data, etc.) */
	Warn = "Warn",

	/** @description Generally useful information to log (service start/stop, configuration assumptions, etc). Info I want to always have available but usually don't care about under normal circumstances. This is my out-of-the-box config level. */
	Info = "Info",

	/** @description Information that is diagnostically helpful to people more than just developers (IT, sysadmins, etc.). */
	Debug = "Debug",

	/** @description Only when I would be "tracing" the code and trying to find one part of a function specifically. */
	Trace = "Trace",

	/** @description Dump ToDo's in the log. Mainly used for missing translations. */
	ToDo = "ToDo"

}
