import { VueConstructor } from "vue";
import { SidebarStore } from "./store";
import Sidebar from './SideBar.vue'
import SidebarItem from './SidebarItem.vue'

export class SidebarPlugin {
	sidebarStore: SidebarStore = new SidebarStore();

	install(Vue: VueConstructor, options: any) {
		if (options && options.sidebarLinks) {
			this.sidebarStore.sidebarLinks = options.sidebarLinks;
		}
		let sidebarStore = this.sidebarStore;
		Vue.mixin({
			data() {
				return {
					sidebarStore: sidebarStore
				};
			}
		});

		Object.defineProperty(Vue.prototype, '$sidebar', {
			get() {
				return this.$root.sidebarStore;
			}
		});
		Vue.component('side-bar', Sidebar);
		Vue.component('sidebar-item', SidebarItem);
	}
}
