








import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
	name: "breadcrumb"
})
export default class Switch extends Vue {

}
