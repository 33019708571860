import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, IGameSchedule, ResponseOfIEnumerableOfGameSchedule, ResponseOfGameSchedule, IResponseOfGameSchedule, GameSchedule, ResponseOfInt32, Response } from './api';


/**
 * @description Service for handling Schedules on the server.
 * @extends BaseWebSocketService
 */
export class ScheduleService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<IGameSchedule>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfGameSchedule> => {
				return hub.getSchedules();
			})
			.then((result: ResponseOfIEnumerableOfGameSchedule): Array<IGameSchedule> => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "ScheduleService.getAll() - No Schedules found!");
					return new Array<IGameSchedule>();
				}
				Logger.log(LogLevel.Debug, "ScheduleService.getAll() - Server Response: " + result.result.length, result.result);
				return result.result;
			});
	}

	public getItem(id: number): Promise<IGameSchedule | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfGameSchedule> => {
				return hub.getSchedule(id);
			}).then((result: IResponseOfGameSchedule) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "ScheduleService.getItem(" + id + ") - No Schedule with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "ScheduleService.getItem(" + id + ") - Server Response: ", result.result);
				return result.result;
			});
	}

	public createItem(item: GameSchedule): Promise<number> {
		Logger.log(LogLevel.Debug, "ScheduleService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createSchedule(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "ScheduleService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "ScheduleService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: GameSchedule): Promise<void> {
		Logger.log(LogLevel.Debug, "ScheduleService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updateSchedule(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "ScheduleService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "ScheduleService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteSchedule(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "ScheduleService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "ScheduleService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}

}
