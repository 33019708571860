export class Color {

	constructor(type: string) {
		this.type = type;
	}

	type: string;

	validator(value: string) {
		return ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black'].includes(value);
	}
}
