import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, ISheetType, ResponseOfIEnumerableOfSheetType, ResponseOfSheetType, IResponseOfSheetType, SheetType, ResponseOfInt32, Response } from './api';


/**
 * @description Service for handling SheetTypes on the server.
 * @extends BaseWebSocketService
 */
export class SheetTypeService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<ISheetType>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfSheetType> => {
				return hub.getSheetTypes();
			})
			.then((result: ResponseOfIEnumerableOfSheetType): Array<ISheetType> => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "SheetTypeService.getAll() - No SheetTypes found!");
					return new Array<ISheetType>();
				}
				Logger.log(LogLevel.Debug, "SheetTypeService.getAll() - Server Response: " + result.result.length, result.result);
				return result.result;
			});
	}

	public getItem(id: number): Promise<ISheetType | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfSheetType> => {
				return hub.getSheetType(id);
			}).then((result: IResponseOfSheetType) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "SheetTypeService.getItem(" + id + ") - No SheetType with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "SheetTypeService.getItem(" + id + ") - Server Response: ", result.result);
				return result.result;
			});
	}

	public createItem(item: SheetType): Promise<number> {
		Logger.log(LogLevel.Debug, "SheetTypeService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createSheetType(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "SheetTypeService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "SheetTypeService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: SheetType): Promise<void> {
		Logger.log(LogLevel.Debug, "SheetTypeService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updateSheetType(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "SheetTypeService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "SheetTypeService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteSheetType(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "SheetTypeService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "SheetTypeService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}
}
