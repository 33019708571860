import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, IScheduledGamePlan, ResponseOfIEnumerableOfScheduledGamePlan, ResponseOfScheduledGamePlan, IResponseOfScheduledGamePlan, ScheduledGamePlan, ResponseOfInt32, Response, ResponseOfInt32Of } from './api';


/**
 * @description Service for handling ScheduledGamePlans on the server.
 * @extends BaseWebSocketService
 */
export class ScheduledGamePlanService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<IScheduledGamePlan>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfScheduledGamePlan> => {
				return hub.getScheduledGamePlans();
			})
			.then((result: ResponseOfIEnumerableOfScheduledGamePlan): Array<IScheduledGamePlan> => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "ScheduledGamePlanService.getAll() - No ScheduledGamePlans found!");
					return new Array<IScheduledGamePlan>();
				}
				Logger.log(LogLevel.Debug, "ScheduledGamePlanService.getAll() - Server Response: " + result.result.length, result.result);
				return result.result;
			});
	}

	public getItem(id: number): Promise<IScheduledGamePlan | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfScheduledGamePlan> => {
				return hub.getScheduledGamePlan(id);
			}).then((result: IResponseOfScheduledGamePlan) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "ScheduledGamePlanService.getItem(" + id + ") - No ScheduledGamePlan with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "ScheduledGamePlanService.getItem(" + id + ") - Server Response: ", result.result);
				return result.result;
			});
	}

	public createItem(item: ScheduledGamePlan): Promise<number> {
		Logger.log(LogLevel.Debug, "ScheduledGamePlanService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createScheduledGamePlan(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "ScheduledGamePlanService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "ScheduledGamePlanService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: ScheduledGamePlan): Promise<void> {
		Logger.log(LogLevel.Debug, "ScheduledGamePlanService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updateScheduledGamePlan(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "ScheduledGamePlanService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "ScheduledGamePlanService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteScheduledGamePlan(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "ScheduledGamePlanService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "ScheduledGamePlanService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}

	public createItems(items: Array<ScheduledGamePlan>): Promise<Array<number>> {
		Logger.log(LogLevel.Debug, "ScheduledGamePlanService.createItems(items) - Items: ", items);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32Of> => {
				return hub.createScheduledGamePlans(items);
			}).then((result: ResponseOfInt32Of) => {
				if(!result.result || !Array.isArray(result.result)) {
					Logger.log(LogLevel.Error, "ScheduledGamePlanService.createItems(items) - Failed to create items!", result);
				}
				Logger.log(LogLevel.Debug, "ScheduledGamePlanService.createItems(items) - Server Response: " + result.result.length, result);
				return result.result;
			});
	}

	public deleteItems(items: Array<number>): Promise<void> {
		Logger.log(LogLevel.Debug, "ScheduledGamePlanService.deleteScheduledGamePlans(items) - Items: ", items);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteScheduledGamePlans(items);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "ScheduledGamePlanService.deleteScheduledGamePlans(items) - Failed to delete items!", result);
				}
				Logger.log(LogLevel.Debug, "ScheduledGamePlanService.deleteScheduledGamePlans(items) - Server Response: ", result);
			});
	}

}
