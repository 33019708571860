import { render, staticRenderFns } from "./PrizePatternList.vue?vue&type=template&id=1b8ba600&scoped=true&"
import script from "./PrizePatternList.vue?vue&type=script&lang=ts&"
export * from "./PrizePatternList.vue?vue&type=script&lang=ts&"
import style0 from "./PrizePatternList.vue?vue&type=style&index=0&id=1b8ba600&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b8ba600",
  null
  
)

export default component.exports