import { IRoom, Room, RoomStatus, RoomState } from '@/services/api';


export interface IRoomWithStatus extends IRoom {

	status: RoomStatus;

}

export class RoomWithStatus extends Room {

	constructor(room?: Room) {
		super(room);
		this.status = new RoomStatus();
		this.status.id = room? room.id: undefined;
		this.status.state = RoomState.Closed;
		this.status.nextGameId = undefined;
		this.status.nextGameStartTime = undefined;
		this.status.currentGameId = undefined;
		this.status.currentGameState = undefined;
		this.status.gamePlanStartTime = undefined;
		this.status.gamePlanEndTime = undefined;
		this.status.currentIndexInGameplan = undefined;
		this.status.gamesInGameplan = undefined;
	}

	status: RoomStatus = new RoomStatus();

}
