






















































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError, ColorUtilities, TimeSpan } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { IGamePlan as GamePlan, IGame as Game, LinkedRoomBlock, BlockId } from '@/services/api';

let tinycolor = require("tinycolor2");


@Component({
	name: 'LinkedRoomBlockList',
	components: {
		Card
	}
})
export default class LinkedRoomBlockList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			formatter: this.idFormatter,
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: false
		},
		{
			key: "color",
			label: this.localize("Color"),
			sortable: false
		},
		{
			key: "duration",
			label: this.localize("Duration"),
			formatter: this.durationFormatter,
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private idFormatter(value: BlockId, key: string, item: LinkedRoomBlock): string {
		return value.id + "";
	}

	private durationFormatter(value: Array<number>, key: string, item: LinkedRoomBlock): string {
		let dur: TimeSpan = new TimeSpan();
		if(item && item.duration) dur = TimeSpan.parse(item.duration);
		return dur.toString("HH:MM:SS");
	}

	private getBlockColor(value: number): string {
		let tColor = tinycolor(ColorUtilities.argbToVueColor(value).rgba);
		return tColor.toHexString();
	}

}
