





































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option, Input } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import BlockList from './BlockList.vue';
import { IGamePlan, GamePlan, IGame as Game, BlockId } from '@/services/api';


@Component({
	name: 'GamePlanForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"el-input": Input,
		"fg-input": fgInput,
		BlockList
	}
})
export default class GamePlanForm extends BaseForm {
	private blockIds: Array<BlockId> = new Array<BlockId>();	//Reactive property for item property that is not a value type (and are not automatically reactive).

	public get title(): string {
		let title: string = this.isNew ? "New Game Plan" : "Edit Game Plan";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get itemReactivePropertyExtractor(): (item: any) => void {
		return ((item: GamePlan) => {
			this.blockIds =  item.blockIds;
		});
	}

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			return true;
		});
	}

	protected get itemReactivePropertyInserter(): (item: any) => void {
		return ((item: GamePlan) => {
			item.blockIds = this.blockIds;
		});
	}

//#endregion

	private listChanged(value: Array<BlockId>): void {
		this.blockIds = value;
	}

}
