











































import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent.vue';
import CollapseTransition from '@/components/lbd/Transitions/collapse-transition';

let image = require('@/assets/img/icon.svg');

@Component({
	components: {
		[CollapseTransition.name]: CollapseTransition
	}
})
export default class AuthLayout extends BaseComponent {
	public showMenu: boolean = false;

	@Prop({ type: String, default: "login-page" })
	public pageClass: string;

	get logo() {
		return image;
	}

}
