import { Link } from "./link";

export class SidebarStore {
	showSidebar: boolean = false;
	sidebarLinks: Array<Link> = new Array<Link>();
	isMinimized: boolean = false;

	displaySidebar(value: boolean) {
		this.showSidebar = value;
	}

	toggleMinimize() {
		document.body.classList.toggle('sidebar-mini')
		// we simulate the window Resize so the charts will get updated in realtime.
		const simulateWindowResize = setInterval(() => {
				window.dispatchEvent(new Event('resize'))
			},
			180
		);

		// we stop the simulation of Window Resize after the animations are completed
		setTimeout(() => {
			clearInterval(simulateWindowResize);
		}, 1000)

		this.isMinimized = !this.isMinimized;
	}
}
