import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, IPot, ResponseOfIEnumerableOfPot, ResponseOfPot, IResponseOfPot, Pot, ResponseOfInt32, Response } from './api';


/**
 * @description Service for handling Pots on the server.
 * @extends BaseWebSocketService
 */
export class PotService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<IPot>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfPot> => {
				return hub.getPots();
			})
			.then((result: ResponseOfIEnumerableOfPot): Array<IPot> => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "PotService.getAll() - No Pots found!");
					return new Array<IPot>();
				}
				Logger.log(LogLevel.Debug, "PotService.getAll() - Server Response: " + result.result.length, result.result);
				return result.result;
			});
	}

	public getItem(id: number): Promise<IPot | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfPot> => {
				return hub.getPot(id);
			}).then((result: IResponseOfPot) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "PotService.getItem(" + id + ") - No Pot with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "PotService.getItem(" + id + ") - Server Response: ", result.result);
				return result.result;
			});
	}

	public createItem(item: Pot): Promise<number> {
		Logger.log(LogLevel.Debug, "PotService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createPot(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "PotService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "PotService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: Pot): Promise<void> {
		Logger.log(LogLevel.Debug, "PotService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updatePot(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "PotService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "PotService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deletePot(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "PotService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "PotService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}
}
