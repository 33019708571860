import { BvTableField, BvTableVariant, BvTableSortDirection, BvTableFormatterCallback } from 'bootstrap-vue';


export class TableField implements BvTableField {
	key: string;
	label?: string;
	headerTitle?: string;
	headerAbbr?: string;
	class?: string | Array<string>;
	formatter?: string | BvTableFormatterCallback;
	sortable?: boolean;
	sortDirection?: BvTableSortDirection;
	sortByFormatted?: boolean | BvTableFormatterCallback;
	filterByFormatted?: boolean | BvTableFormatterCallback;
	tdClass?: string | Array<string> | ((value: any, key: string, item: any) => any);
	thClass?: string | Array<string>;
	thStyle?: any;
	variant?: BvTableVariant | string;
	tdAttr?: any | ((value: any, key: string, item: any) => any);
	thAttr?: any | ((value: any, key: string, item: any, type: string) => any);
	stickyColumn?: boolean;
	isRowHeader?: boolean;
}