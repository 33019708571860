












































































import { Vue, Component, Watch } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel, RoomWithStatus } from '@/models';
import { RoomService } from '@/services';
import Card from '@/components/lbd/Cards/Card.vue';
import { IRoom as Room, IGameSchedule as Schedule, RoomStatus, RoomState } from '@/services/api';


@Component({
	name: 'RoomList',
	components: {
		Card
	}
})
export default class RoomList extends BaseList {
	private roomService: RoomService = new RoomService();

	public created() {
		this.getRoomStatuses();
	}

	@Watch("roomList")
	private getRoomStatuses() {
		this.roomList.forEach((element: RoomWithStatus) => {
			this.roomService.roomStatus(element.id)
				.then((status: RoomStatus): void =>{
					this.$store.commit("cacheStore/setRoomStatus", status);
				})
		});
	}

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: true
		},
		{
			key: "scheduleIds",
			label: this.localize("Schedule"),
			formatter: this.scheduleFormatter
		},
		{
			key: "status",
			label: this.localize("Status")
		},
		{
			key: "actions",
			label: this.localize("Actions")
		}
	];

	private scheduleFormatter(value: Array<number>, key: string, item: Room): string {
		let name: string = this.localize("undefined");
		let schedule = this.scheduleList.find((element: Schedule) => element.id == item.scheduleId);
		if(schedule) name = schedule.name;
		return name;
	}

	private open(room: RoomWithStatus): void {
		if(!room.status) return;
		if(room.status.state != RoomState.Closed) return;
		this.roomService.openRoom(room.id)
			.then(() => {
				this.getRoomStatuses();
			});
	}

	private close(room: RoomWithStatus): void {
		if(!room.status) return;
		if(room.status.state != RoomState.Open) return;
		this.roomService.closeRoom(room.id)
			.then(() => {
				this.getRoomStatuses();
			});
	}

	private forceClose(room: RoomWithStatus): void {
		if(!room.status) return;
		this.roomService.forceCloseRoom(room.id)
			.then(() => {
				this.getRoomStatuses();
			});
	}

	private restart(room: RoomWithStatus): void {
		if(!room.status) return;
		if(room.status.state != RoomState.Open && room.status.state != RoomState.Closed) return;
		this.roomService.restartRoom(room.id)
			.then(() => {
				this.getRoomStatuses();
			});
	}

}
