





























































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel, Matrix } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { IPrizePattern as PrizePattern, IGridType as GridType, IGridSize as GridSize } from '@/services/api';


@Component({
	name: 'PrizePatternList',
	components: {
		Card
	}
})
export default class PrizePatternList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: false
		},
		{
			key: "gridTypeId",
			label: this.localize("Sheet Type"),
			formatter: this.gridTypeIdFormatter,
			sortable: false
		},
		{
			key: "pattern",
			label: this.localize("Pattern"),
			sortable: false
		},
		{
			key: "isExact",
			label: this.localize("Exact"),
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private gridTypeIdFormatter(value: number, key: string, item: PrizePattern): string {
		let name: string = "" + value;
		if(!this.gridTypeList || ! Array.isArray(this.gridTypeList)) return name;
		let type: GridType = this.gridTypeList.find((item: GridType) => item.id == value);
		if(!type) return name;
		let size: GridSize =  type.gridSize;
		if(size) name = this.numberOfBallsEnum[size.numberOfBalls] + " " + " (" + size.rows + " x " + size.columns + ")";
		if(type.freeplayFields && Array.isArray(type.freeplayFields)) name += " " + this.localize("with Freeplay");
		if(type.blanksPerRow && type.blanksPerRow > 0) name += " " + this.localize("with") + " " + type.blanksPerRow + " " +  this.localize("blanks / row");
		return name;
	}

	private getNumberOfRows(pattern: PrizePattern) : number {
		if(!pattern) throw new TypeError("PrizePattern undefined!");
		if(!this.gridTypeList || ! Array.isArray(this.gridTypeList)) return 0;
		let type = this.gridTypeList.find((item: GridType) => item.id == pattern.gridTypeId);
		if(!type) return 0;
		let size: GridSize =  type.gridSize;
		if(!size) return 0;
		return size.rows;
	}

	private getNumberOfColumns(pattern: PrizePattern) : number {
		if(!pattern) throw new TypeError("PrizePattern undefined!");
		if(!this.gridTypeList || ! Array.isArray(this.gridTypeList)) return 0;
		let type = this.gridTypeList.find((item: GridType) => item.id == pattern.gridTypeId);
		if(!type) return 0;
		let size: GridSize =  type.gridSize;
		if(!size) return 0;
		return size.columns;
	}

	private getValueAt(pattern: PrizePattern, row: number, column: number): boolean {
		if(!pattern) throw new TypeError("PrizePattern undefined!");
		if(!this.gridTypeList || ! Array.isArray(this.gridTypeList)) throw new TypeError("GridTypes not loaded!");
		let type = this.gridTypeList.find((item: GridType) => item.id == pattern.gridTypeId);
		if(!type) throw new TypeError("Can't find GridType (id: " + pattern.gridTypeId + ") for this PrizePattern (id: " + pattern.id + ")!");
		let size: GridSize =  type.gridSize;
		if(!size) throw new TypeError("Can't find GridSize for this PrizePattern's (id: " + pattern.id + ") with GridType (id: " + type.id + ")!");
		let matrix: Matrix = new Matrix(size.rows, size.columns, pattern.pattern);
		//Logger.log(LogLevel.Debug, "PrizePatternList.getValueAt(" + pattern.id + ", " + row + ", " + column + ")", matrix);
		return matrix.valueAt(row, column);
	}

}
