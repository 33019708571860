import { Store, Action, ActionTree, ActionContext } from 'vuex';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { State } from './state'


export function resetStateForNewCustomer (store: ActionContext<State, any>) {
	store.commit('resetStateForNewCustomer');
}

/** @description Clear all data and set application in known state */
export function resetStateToRecoverFromError (store: ActionContext<State, any>) {
	store.commit('resetStateToRecoverFromError');
}


export default <ActionTree<State, any>> {
	resetStateForNewCustomer,
	resetStateToRecoverFromError
}
