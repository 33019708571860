import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, ResponseOfInt32, Response, ResponseOfIEnumerableOfBingoBoosterRoom, IResponseOfBingoBoosterRoom, ResponseOfBingoBoosterRoom, BingoBoosterRoom } from './api';


/**
 * @description Service for handling Rooms on the server.
 * @extends BaseWebSocketService
 */
export class BingoBoosterRoomService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<BingoBoosterRoom>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfBingoBoosterRoom> => {
				return hub.getBingoBoosterRooms();
			})
			.then((result: ResponseOfIEnumerableOfBingoBoosterRoom): Array<BingoBoosterRoom> => {
				let returnValue = new Array<BingoBoosterRoom>();
				if(!result.result) {
					Logger.log(LogLevel.Debug, "BingoBoosterRoomService.getAll() - No BingoBooster Rooms found!");
					return returnValue;
				}
				returnValue = result.result.map((element: BingoBoosterRoom): BingoBoosterRoom => new BingoBoosterRoom(element));
				Logger.log(LogLevel.Debug, "BingoBoosterRoomService.getAll() - Server Response: " + result.result.length, returnValue);
				return returnValue;
			});
	}

	public getItem(id: number): Promise<BingoBoosterRoom | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfBingoBoosterRoom> => {
				return hub.getBingoBoosterRoom(id);
			}).then((result: IResponseOfBingoBoosterRoom) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "BingoBoosterRoomService.getItem(" + id + ") - No BingoBooster Room with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "BingoBoosterRoomService.getItem(" + id + ") - Server Response: ", result.result);
				return new BingoBoosterRoom(result.result);
			});
	}

	public createItem(item: BingoBoosterRoom): Promise<number> {
		Logger.log(LogLevel.Debug, "BingoBoosterRoomService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createBingoBoosterRoom(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "BingoBoosterRoomService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "BingoBoosterRoomService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: BingoBoosterRoom): Promise<void> {
		Logger.log(LogLevel.Debug, "BingoBoosterRoomService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updateBingoBoosterRoom(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "BingoBoosterRoomService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "BingoBoosterRoomService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteBingoBoosterRoom(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "BingoBoosterRoomService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "BingoBoosterRoomService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
		return;
	}

}
