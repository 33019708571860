












































import { Vue, Component, Watch } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import { BingoBoosterHallService } from '@/services';
import Card from '@/components/lbd/Cards/Card.vue';
import { BingoBoosterRoom, IBingoBoosterHall as BingoBoosterHall } from '@/services/api';


@Component({
	name: 'BingoBoosterHallList',
	components: {
		Card
	}
})
export default class BingoBoosterHallList extends BaseList {
	private bingoBoosterHallService: BingoBoosterHallService = new BingoBoosterHallService();

	public created() {
	}

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: true
		},
		{
			key: "bingoBoosterRoomId",
			label: this.localize("BingoBooster Room"),
			formatter: this.bingoBoosterRoomIdFormatter
		},
		{
			key: "mapping",
			label: this.localize("Mapping"),
			formatter: this.mappingFormatter
		},
		{
			key: "externalBBHallId",
			label: this.localize("Hall Id in BingoBooster")
		},
		{
			key: "actions",
			label: this.localize("Actions")
		}
	];

	private bingoBoosterRoomIdFormatter(value: number, key: string, item: BingoBoosterHall): string {
		if(!value) return undefined;
		let room = (this.bingoBoosterRoomList || new Array<BingoBoosterRoom>())
			.find(room => room.id == value);
		if(room) return room.name;
		return undefined;
	}

	private mappingFormatter(value: any, key: string, item: BingoBoosterHall): string {
		return `${this.localize("Hall")} ${item.egsHallId} ${this.localize("maps to BB hall")} ${item.externalBBHallId}`;
	}

}
