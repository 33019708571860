import { Logger, handleError } from "@/utilities";
import { LogLevel, IUser, User, RoomWithStatus, IRoomWithStatus } from "@/models";
import { BingoBoosterRoomService, BingoBoosterHallService, GridTypeService, SheetTypeService, RoomService, ScheduleService, ScheduledGamePlanService, PrizePatternService, PrizePatternGroupService, PotService, GameService, GameBlockService, LoopingGamesBlockService, GamePlanService, LinkedRoomBlockService, UserService } from '@/services';
import { IPot, Pot, IGridType, GridType, ISheetType, SheetType, IGameSchedule as Schedule, GameSchedule, IScheduledGamePlan, ScheduledGamePlan, IPrize, Prize, IPrizePattern, PrizePattern, IPrizePatternGroup, PrizePatternGroup, IGame, Game, IGamePlan, GamePlan, GameBlock, IGameBlock, LinkedRoomBlock, ILinkedRoomBlock, ILoopingGamesBlock, LoopingGamesBlock, BlockType, BlockId, RoomStatus, BingoBoosterRoom, BingoBoosterHall, IBingoBoosterRoom, IBingoBoosterHall } from '@/services/api';
import store from '../..';


export class State {
	private roomService: RoomService = new RoomService();
	private bingoBoosterRoomService: BingoBoosterRoomService = new BingoBoosterRoomService();
	private bingoBoosterHallService: BingoBoosterHallService = new BingoBoosterHallService();
	private scheduleService: ScheduleService = new ScheduleService();
	private scheduledGamePlanService: ScheduledGamePlanService = new ScheduledGamePlanService();
	private gridTypeService: GridTypeService = new GridTypeService();
	private sheetTypeService: SheetTypeService = new SheetTypeService();
	private prizePatternService: PrizePatternService = new PrizePatternService();
	private prizePatternGroupService: PrizePatternGroupService = new PrizePatternGroupService();
	private potService: PotService = new PotService();
	private gameService: GameService = new GameService();
	private gamePlanService: GamePlanService = new GamePlanService();
	private gameBlockService: GameBlockService = new GameBlockService();
	private loopingGamesBlockService: LoopingGamesBlockService = new LoopingGamesBlockService();
	private linkedRoomBlockService: LinkedRoomBlockService = new LinkedRoomBlockService();
	private userService: UserService = new UserService();
	private _bingoBoosterRoomList: Array<BingoBoosterRoom> = new Array<BingoBoosterRoom>();
	private _bingoBoosterHallList: Array<BingoBoosterHall> = new Array<BingoBoosterHall>();
	private _gridTypeList: Array<GridType> = new Array<GridType>();
	private _sheetTypeList: Array<SheetType> = new Array<SheetType>();
	private _roomList: Array<RoomWithStatus> = new Array<RoomWithStatus>();
	private _scheduleList: Array<Schedule> = new Array<Schedule>();
	private _scheduledGamePlanList: Array<ScheduledGamePlan> = new Array<ScheduledGamePlan>();
	private _prizePatternList: Array<PrizePattern> = new Array<PrizePattern>();
	private _prizePatternGroupList: Array<PrizePatternGroup> = new Array<PrizePatternGroup>();
	private _potList: Array<Pot> = new Array<Pot>();
	private _gameList: Array<Game> = new Array<Game>();
	private _gameBlockList: Array<GameBlock> = new Array<GameBlock>();
	private _loopingGamesBlockList: Array<LoopingGamesBlock> = new Array<LoopingGamesBlock>();
	private _linkedRoomBlockList: Array<LinkedRoomBlock> = new Array<LinkedRoomBlock>();
	private _gamePlanList: Array<GamePlan> = new Array<GamePlan>();
	private _userList: Array<User> = new Array<User>();
	private isDataLoading: Array<boolean> = new Array<boolean>();	// Used to prevent parallel service calls to load data.
	private isBingoBoosterRoomDataLoaded = false;
	private isBingoBoosterHallDataLoaded = false;
	private isUserDataLoaded = false;
	private isGridTypeDataLoaded = false;
	private isSheetTypeDataLoaded = false;
	private isRoomDataLoaded = false;
	private isScheduleDataLoaded = false;
	private isScheduledGamePlanDataLoaded = false;
	private isPrizePatternDataLoaded = false;
	private isPrizePatternGroupDataLoaded = false;
	private isPotDataLoaded = false;
	private isGameDataLoaded = false;
	private isGameBlockDataLoaded = false;
	private isLoopingGamesBlockDataLoaded = false;
	private isLinkedRoomBlockDataLoaded = false;
	private isGamePlanDataLoaded = false;


	public constructor() {
	}

	public reset(): void {
		this.reloadBingoBoosterRoomList();
		this.reloadBingoBoosterHallList();
		this.reloadGamePlanList();
		this.reloadGameBlockList();
		this.reloadLoopingGamesBlockList();
		this.reloadLinkedRoomBlockList();
		this.reloadGameList();
		this.reloadPotList();
		this.reloadPrizePatternGroupList();
		this.reloadPrizePatternList();
		this.reloadRoomList();
		this.reloadScheduleList();
		this.reloadScheduledGamePlanList();
		this.reloadSheetTypeList();
		this.reloadGridTypeList();
		this.reloadUserList();
	}

	public reloadBingoBoosterRoomList(): void {
		this.bingoBoosterRoomService.getAll()
			.then((items: Array<IBingoBoosterRoom>) => store.commit('cacheStore/setBingoBoosterRoomList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - BingoBoosterRoomService.getAll() - Error: ", error));
		this.isDataLoading[<any>"BingoBoosterRoom"] = true;
	}

	public reloadBingoBoosterHallList(): void {
		this.bingoBoosterHallService.getAll()
			.then((items: Array<IBingoBoosterHall>) => store.commit('cacheStore/setBingoBoosterHallList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - BingoBoosterHallService.getAll() - Error: ", error));
		this.isDataLoading[<any>"BingoBoosterHall"] = true;
	}

	public reloadGamePlanList(): void {
		this.gamePlanService.getAll()
			.then((items: Array<IGamePlan>) => store.commit('cacheStore/setGamePlanList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - GamePlanService.getAll() - Error: ", error));
		this.isDataLoading[<any>"GamePlan"] = true;
	}

	public reloadGameBlockList(): void {
		this.gameBlockService.getAll()
			.then((items: Array<IGameBlock>) => store.commit('cacheStore/setGameBlockList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - GameBlockService.getAll() - Error: ", error));
		this.isDataLoading[<any>"GameBlock"] = true;
	}

	public reloadLoopingGamesBlockList(): void {
		this.loopingGamesBlockService.getAll()
			.then((items: Array<ILoopingGamesBlock>) => store.commit('cacheStore/setLoopingGamesBlockList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - LoopingGamesBlockService.getAll() - Error: ", error));
		this.isDataLoading[<any>"LoopingGamesBlock"] = true;
	}

	public reloadLinkedRoomBlockList(): void {
		this.linkedRoomBlockService.getAll()
			.then((items: Array<ILinkedRoomBlock>) => store.commit('cacheStore/setLinkedRoomBlockList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - LinkedRoomBlockService.getAll() - Error: ", error));
		this.isDataLoading[<any>"LinkedRoomBlock"] = true;
	}

	public reloadGameList(): void {
		this.gameService.getAll()
			.then((items: Array<IGame>) => store.commit('cacheStore/setGameList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - GameService.getAll() - Error: ", error));
		this.isDataLoading[<any>"Game"] = true;
	}

	public reloadPotList(): void {
		this.potService.getAll()
			.then((items: Array<IPot>) => store.commit('cacheStore/setPotList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - PotService.getAll() - Error: ", error));
		this.isDataLoading[<any>"Pot"] = true;
	}

	public reloadPrizePatternGroupList(): void {
		this.prizePatternGroupService.getAll()
			.then((items: Array<IPrizePatternGroup>) => store.commit('cacheStore/setPrizePatternGroupList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - PrizePatternGroupService.getAll() - Error: ", error));
		this.isDataLoading[<any>"PrizePatternGroup"] = true;
	}

	public reloadPrizePatternList(): void {
		this.prizePatternService.getAll()
			.then((items: Array<IPrizePattern>) => store.commit('cacheStore/setPrizePatternList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - PrizePatternService.getAll() - Error: ", error));
		this.isDataLoading[<any>"PrizePattern"] = true;
	}

	public reloadRoomList(): void {
		this.roomService.getAll()
			.then((items: Array<IRoomWithStatus>) => store.commit('cacheStore/setRoomList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - RoomService.getAll() - Error: ", error));
		this.isDataLoading[<any>"Room"] = true;
	}

	public reloadScheduleList(): void {
		this.scheduleService.getAll()
			.then((items: Array<Schedule>) => store.commit('cacheStore/setScheduleList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - ScheduleService.getAll() - Error: ", error));
		this.isDataLoading[<any>"Schedule"] = true;
	}

	public reloadScheduledGamePlanList(): void {
		this.scheduledGamePlanService.getAll()
			.then((items: Array<IScheduledGamePlan>) => store.commit('cacheStore/setScheduledGamePlanList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - ScheduledGamePlanService.getAll() - Error: ", error));
		this.isDataLoading[<any>"ScheduledGamePlan"] = true;
	}

	public reloadSheetTypeList(): void {
		this.sheetTypeService.getAll()
			.then((items: Array<ISheetType>) => store.commit('cacheStore/setSheetTypeList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - SheetTypeService.getAll() - Error: ", error));
		this.isDataLoading[<any>"SheetType"] = true;
	}

	public reloadGridTypeList(): void {
		this.gridTypeService.getAll()
			.then((items: Array<IGridType>) => store.commit('cacheStore/setGridTypeList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - GridTypeService.getAll() - Error: ", error));
		this.isDataLoading[<any>"GridType"] = true;
	}

	public reloadUserList(): void {
		this.userService.getAll()
			.then((items: Array<IUser>) => store.commit('cacheStore/setUserList', items))	// Mutation not allowed here, must call commit.
			.catch((error: Error): void => Logger.log(LogLevel.Debug, "Store.chacheState.reset() - UserService.getAll() - Error: ", error));
		this.isDataLoading[<any>"User"] = true;
	}

	public setRoomStatus(value: RoomStatus) {
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.setRoomStatus - State updated on Room " + value.id + ".");
		let room = this._roomList.find(element => element.id == value.id);
		if(room) room.status = value;
	}

	public get bingoBoosterRoomList(): Array<BingoBoosterRoom> {
		if(!this.isDataLoading[<any>"BingoBoosterRoom"]) this.reloadBingoBoosterRoomList();
		return this._bingoBoosterRoomList;
	}
	public set bingoBoosterRoomList(value: Array<BingoBoosterRoom>) {
		this._bingoBoosterRoomList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.bingoBoosterRoomList - State.bingoBoosterRoomList now consist of " + (this._bingoBoosterRoomList ? this._bingoBoosterRoomList.length : "") + " BingoBooster Rooms.");
		this.isBingoBoosterRoomDataLoaded = true;
	}

	public get bingoBoosterHallList(): Array<BingoBoosterHall> {
		if(!this.isDataLoading[<any>"BingoBoosterHall"]) this.reloadBingoBoosterHallList();
		return this._bingoBoosterHallList;
	}
	public set bingoBoosterHallList(value: Array<BingoBoosterHall>) {
		this._bingoBoosterHallList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.bingoBoosterHallList - State.bingoBoosterHallList now consist of " + (this._bingoBoosterHallList ? this._bingoBoosterHallList.length : "") + " BingoBooster Halls.");
		this.isBingoBoosterHallDataLoaded = true;
	}

	public get userList(): Array<User> {
		if(!this.isDataLoading[<any>"User"]) this.reloadUserList();
		return this._userList;
	}
	public set userList(value: Array<User>) {
		this._userList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.userList - State.userList now consist of " + (this._userList ? this._userList.length : "") + " Users.");
		this.isUserDataLoaded = true;
	}

	public get gridTypeList(): Array<GridType> {
		if(!this.isDataLoading[<any>"GridType"]) this.reloadGridTypeList();
		return this._gridTypeList;
	}
	public set gridTypeList(value: Array<GridType>) {
		this._gridTypeList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.gridTypeList - State.gridTypeList now consist of " + (this._gridTypeList ? this._gridTypeList.length : "") + " GridTypes.");
		this.isGridTypeDataLoaded = true;
	}

	public get sheetTypeList(): Array<SheetType> {
		if(!this.isDataLoading[<any>"SheetType"]) this.reloadSheetTypeList();
		return this._sheetTypeList;
	}
	public set sheetTypeList(value: Array<SheetType>) {
		this._sheetTypeList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.sheetTypeList - State.sheetTypeList now consist of " + (this._sheetTypeList ? this._sheetTypeList.length : "") + " SheetTypes.");
		this.isSheetTypeDataLoaded = true;
	}

	public get roomList(): Array<RoomWithStatus> {
		if(!this.isDataLoading[<any>"Room"]) this.reloadRoomList();
		return this._roomList;
	}
	public set roomList(value: Array<RoomWithStatus>) {
		this._roomList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.roomList - State.roomList now consist of " + (this._roomList ? this._roomList.length : "") + " Rooms.");
		this.isRoomDataLoaded = true;
		this.roomService.subscribeToStateChanges((status: RoomStatus): void => {
			store.commit('cacheStore/setRoomStatus', status);
		});
	}

	public get scheduleList(): Array<Schedule> {
		if(!this.isDataLoading[<any>"Schedule"]) this.reloadScheduleList();
		return this._scheduleList;
	}
	public set scheduleList(value: Array<Schedule>) {
		this._scheduleList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.scheduleList - State.scheduleList now consist of " + (this._scheduleList ? this._scheduleList.length : "") + " Schedules.");
		this.isScheduleDataLoaded = true;
	}

	public get scheduledGamePlanList(): Array<ScheduledGamePlan> {
		if(!this.isDataLoading[<any>"ScheduledGamePlan"]) this.reloadScheduledGamePlanList();
		return this._scheduledGamePlanList;
	}
	public set scheduledGamePlanList(value: Array<ScheduledGamePlan>) {
		this._scheduledGamePlanList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.scheduledGamePlanList - State.scheduledGamePlanList now consist of " + (this._scheduledGamePlanList ? this._scheduledGamePlanList.length : "") + " ScheduledGamePlans.");
		this.isScheduledGamePlanDataLoaded = true;
	}

	public get prizePatternList(): Array<PrizePattern> {
		if(!this.isDataLoading[<any>"PrizePattern"]) this.reloadPrizePatternList();
		return this._prizePatternList;
	}
	public set prizePatternList(value: Array<PrizePattern>) {
		this._prizePatternList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.prizePatternList - State.prizePatternList now consist of " + (this._prizePatternList ? this._prizePatternList.length : "") + " PrizePatterns.");
		this.isPrizePatternDataLoaded = true;
	}

	public get prizePatternGroupList(): Array<PrizePatternGroup> {
		if(!this.isDataLoading[<any>"PrizePatternGroup"]) this.reloadPrizePatternGroupList();
		return this._prizePatternGroupList;
	}
	public set prizePatternGroupList(value: Array<PrizePatternGroup>) {
		this._prizePatternGroupList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.prizePatternGroupList - State.prizePatternGroupList now consist of " + (this._prizePatternGroupList ? this._prizePatternGroupList.length : "") + " PrizePatternGroups.");
		this.isPrizePatternGroupDataLoaded = true;
	}

	public get potList(): Array<Pot> {
		if(!this.isDataLoading[<any>"Pot"]) this.reloadPotList();
		return this._potList;
	}
	public set potList(value: Array<Pot>) {
		this._potList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.potList - State.potList now consist of " + (this._potList ? this._potList.length : "") + " Pots.");
		this.isPotDataLoaded = true;
	}

	public get gameList(): Array<Game> {
		if(!this.isDataLoading[<any>"Game"]) this.reloadGameList();
		return this._gameList;
	}
	public set gameList(value: Array<Game>) {
		this._gameList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.gameList - State.gameList now consist of " + (this._gameList ? this._gameList.length : "") + " Games.");
		this.isGameDataLoaded = true;
	}

	public get gameBlockList(): Array<GameBlock> {
		if(!this.isDataLoading[<any>"GameBlock"]) this.reloadGameBlockList();
		return this._gameBlockList;
	}
	public set gameBlockList(value: Array<GameBlock>) {
		this._gameBlockList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.gameBlockList - State.gameBlockList now consist of " + (this._gameBlockList ? this._gameBlockList.length : "") + " Game Blocks.");
		this.isGameBlockDataLoaded = true;
	}

	public get loopingGamesBlockList(): Array<LoopingGamesBlock> {
		if(!this.isDataLoading[<any>"LoopingGamesBlock"]) this.reloadLoopingGamesBlockList();
		return this._loopingGamesBlockList;
	}
	public set loopingGamesBlockList(value: Array<LoopingGamesBlock>) {
		this._loopingGamesBlockList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.loopingGamesBlockList - State.loopingGamesBlockList now consist of " + (this._loopingGamesBlockList ? this._loopingGamesBlockList.length : "") + " Looping Games Blocks.");
		this.isLoopingGamesBlockDataLoaded = true;
	}

	public get linkedRoomBlockList(): Array<LinkedRoomBlock> {
		if(!this.isDataLoading[<any>"LinkedRoomBlock"]) this.reloadLinkedRoomBlockList();
		return this._linkedRoomBlockList;
	}
	public set linkedRoomBlockList(value: Array<LinkedRoomBlock>) {
		this._linkedRoomBlockList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.linkedRoomBlockList - State.linkedRoomBlockList now consist of " + (this._linkedRoomBlockList ? this._linkedRoomBlockList.length : "") + " Link Blocks.");
		this.isLinkedRoomBlockDataLoaded = true;
	}

	public get gamePlanList(): Array<GamePlan> {
		if(!this.isDataLoading[<any>"GamePlan"]) this.reloadGamePlanList();
		return this._gamePlanList;
	}
	public set gamePlanList(value: Array<GamePlan>) {
		this._gamePlanList = value;
		Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.State.gamePlanList - State.gamePlanList now consist of " + (this._gamePlanList ? this._gamePlanList.length : "") + " GamePlans.");
		this.isGamePlanDataLoaded = true;
	}

	public isDataLoaded(type: string) {
		switch(type) {
			case "BingoBoosterRoom":
				return this.isBingoBoosterRoomDataLoaded;
				break;
			case "BingoBoosterHall":
				return this.isBingoBoosterHallDataLoaded;
				break;
			case "User":
				return this.isUserDataLoaded;
				break;
			case "GridType":
				return this.isGridTypeDataLoaded;
				break;
			case "SheetType":
				return this.isSheetTypeDataLoaded;
				break;
			case "Room":
				return this.isRoomDataLoaded;
				break;
			case "Schedule":
				return this.isScheduleDataLoaded;
				break;
			case "ScheduledGamePlan":
				return this.isScheduledGamePlanDataLoaded;
				break;
			case "PrizePattern":
				return this.isPrizePatternDataLoaded;
				break;
			case "PrizePatternGroup":
				return this.isPrizePatternGroupDataLoaded;
				break;
			case "Pot":
				return this.isPotDataLoaded;
				break;
			case "Game":
				return this.isGameDataLoaded;
				break;
			case "GameBlock":
				return this.isGameBlockDataLoaded;
				break;
			case "LoopingGamesBlock":
				return this.isLoopingGamesBlockDataLoaded;
				break;
			case "LinkedRoomBlock":
				return this.isLinkedRoomBlockDataLoaded;
				break;
			case "GamePlan":
				return this.isGamePlanDataLoaded;
				break;
		}
		return false;
	}

	public getItem(itemType: string, itemId: number): Promise<any> {
		let list: Array<{id: number | BlockId}> = [];
		let item: any = {};
		let service: { getAll: () => Promise<Array<any>>};
		switch(itemType){
			case "BingoBoosterRoom":
				list = this.bingoBoosterRoomList;
				item = new BingoBoosterRoom();
				service = this.bingoBoosterRoomService;
				break;
			case "BingoBoosterHall":
				list = this.bingoBoosterHallList;
				item = new BingoBoosterHall();
				service = this.bingoBoosterHallService;
				break;
			case "User":
				list = this.userList;
				item = new User();
				service = this.userService;
				break;
			case "GridType":
				list = this.gridTypeList;
				item = new GridType();
				service = this.gridTypeService;
				break;
			case "SheetType":
				list = this.sheetTypeList;
				item = new SheetType();
				service = this.sheetTypeService;
				break;
			case "Room":
				list = this.roomList;
				item = new RoomWithStatus();
				service = this.roomService;
				break;
			case "Schedule":
				list = this.scheduleList;
				item = new GameSchedule();
				service = this.scheduleService;
				break;
			case "ScheduledGamePlan":
				list = this.scheduledGamePlanList;
				item = new ScheduledGamePlan();
				service = this.scheduledGamePlanService;
				break;
			case "PrizePattern":
				list = this.prizePatternList;
				item = new PrizePattern();
				service = this.prizePatternService;
				break;
			case "PrizePatternGroup":
				list = this.prizePatternGroupList;
				item = new PrizePatternGroup();
				service = this.prizePatternGroupService;
				break;
			case "Pot":
				list = this.potList;
				item = new Pot();
				service = this.potService;
				break;
			case "Game":
				list = this.gameList;
				item = new Game();
				service = this.gameService;
				break;
			case "GameBlock":
				list = this.gameBlockList;
				item = new GameBlock();
				service = this.gameBlockService;
				break;
			case "LoopingGamesBlock":
				list = this.loopingGamesBlockList;
				item = new LoopingGamesBlock();
				service = this.loopingGamesBlockService;
				break;
			case "LinkedRoomBlock":
				list = this.linkedRoomBlockList;
				item = new LinkedRoomBlock();
				service = this.linkedRoomBlockService;
				break;
			case "GamePlan":
				list = this.gamePlanList;
				item = new GamePlan();
				service = this.gamePlanService;
				break;
		}
		if(!service) throw new Error("Implementation Error -> Vuex.CacheState.State.getItem() is missing service mapping for type " + itemType + ".");
		if(!itemId || itemId <= 0) return Promise.resolve(JSON.parse(JSON.stringify(item)));	//Can't return object from Vuex Store bacause it can't be manipulated directly!
		item = list.find((element: {id: number | BlockId}): boolean => {
			return (typeof element.id == "number") ? element.id == itemId : element.id.id == itemId;
		});
		if(item) return Promise.resolve(JSON.parse(JSON.stringify(item)));						//Can't return object from Vuex Store bacause it can't be manipulated directly!
		return service.getAll()
			.then((itemList: Array<any>) => {
				store.commit('cacheStore/set' + itemType + 'List', itemList);					// Mutation not allowed here, must call commit.
				item = itemList.find((element: {id: number | BlockId}): boolean => {
					return (typeof element.id == "number") ? element.id == itemId : element.id.id == itemId;
				});
				if(!item) return item;
				return JSON.parse(JSON.stringify(item));										//Can't return object from Vuex Store bacause it can't be manipulated directly!
			});
	}

	public saveItem(itemType: string, item: { id: number | BlockId }): Promise<void> {
		let service: {
			createItem: (item: any, options?: any) => Promise<number>,
			updateItem: (item: any, options?: any) => Promise<void>
		};
		switch(itemType){
			case "BingoBoosterRoom":
				service = this.bingoBoosterRoomService;
				break;
			case "BingoBoosterHall":
				service = this.bingoBoosterHallService;
				break;
			case "User":
				service = this.userService;
				break;
			case "GridType":
				service = this.gridTypeService;
				break;
			case "SheetType":
				service = this.sheetTypeService;
				break;
			case "Room":
				service = this.roomService;
				break;
			case "Schedule":
				service = this.scheduleService;
				break;
			case "ScheduledGamePlan":
				service = this.scheduledGamePlanService;
				break;
			case "PrizePattern":
				service = this.prizePatternService;
				break;
			case "PrizePatternGroup":
				service = this.prizePatternGroupService;
				break;
			case "Pot":
				service = this.potService;
				break;
			case "Game":
				service = this.gameService;
				break;
			case "GameBlock":
				service = this.gameBlockService;
				break;
			case "LoopingGamesBlock":
				service = this.loopingGamesBlockService;
				break;
			case "LinkedRoomBlock":
				service = this.linkedRoomBlockService;
				break;
			case "GamePlan":
				service = this.gamePlanService;
				break;
		}
		if(!service) throw new Error("Implementation Error -> Vuex.CacheState.State.saveItem() is missing service mapping for type " + itemType + ".");
		let promise: Promise<number | void> = (!item.id || item.id <= 0) ?
			service.createItem(item):
			service.updateItem(item);
		return promise.then(() => {
			store.commit('cacheStore/reload' + itemType + 'List');
		});
	}

	public deleteItem(itemType: string, itemId: number): Promise<void> {
		let service: {
			deleteItem: (id: number) => Promise<void>
		};
		switch(itemType){
			case "BingoBoosterRoom":
				service = this.bingoBoosterRoomService;
				break;
			case "BingoBoosterHall":
				service = this.bingoBoosterHallService;
				break;
			case "User":
				service = this.userService;
				break;
			case "GridType":
				service = this.gridTypeService;
				break;
			case "SheetType":
				service = this.sheetTypeService;
				break;
			case "Room":
				service = this.roomService;
				break;
			case "Schedule":
				service = this.scheduleService;
				break;
			case "ScheduledGamePlan":
				service = this.scheduledGamePlanService;
				break;
			case "PrizePattern":
				service = this.prizePatternService;
				break;
			case "PrizePatternGroup":
				service = this.prizePatternGroupService;
				break;
			case "Pot":
				service = this.potService;
				break;
			case "Game":
				service = this.gameService;
				break;
			case "GameBlock":
				service = this.gameBlockService;
				break;
			case "LoopingGamesBlock":
				service = this.loopingGamesBlockService;
				break;
			case "LinkedRoomBlock":
				service = this.linkedRoomBlockService;
				break;
			case "GamePlan":
				service = this.gamePlanService;
				break;
		}
		if(!service) throw new Error("Implementation Error -> Vuex.CacheState.State.deleteItem() is missing service mapping for type " + itemType + ".");
		return service.deleteItem(itemId)
			.then(() => {
				store.commit('cacheStore/reload' + itemType + 'List');
			});
	}


}
