















































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { IPot as Pot } from '@/services/api';


@Component({
	name: 'PotList',
	components: {
		Card
	}
})
export default class PotList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name"),
			sortable: false
		},
		{
			key: "buildPercent",
			label: this.localize("Builds"),
			formatter: this.buildPercentFormatter,
			sortable: false
		},
		{
			key: "prizeCap",
			label: this.localize("Prize Cap"),
			sortable: false
		},
		{
			key: "isJackpot",
			label: this.localize("Jackpot"),
			sortable: false
		},
		{
			key: "isGlobal",
			label: this.localize("Global"),
			sortable: false
		},
		{
			key: "potValue",
			label: this.localize("Current Size"),
			sortable: false
		},
		{
			key: "actions",
			label: this.localize("Actions"),
			sortable: false
		}
	];

	private buildPercentFormatter(value: number, key: string, item: Pot): string {
		return value + "%";
	}

}
