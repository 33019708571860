



import { Vue, Component, Prop } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import BaseComponent from '@/components/BaseComponent.vue';
import { Logger, handleError, ColorUtilities } from '@/utilities';
import { LogLevel, TicketColor, NumberOfBalls, IUser as User } from '@/models';
import { IPot as Pot, ExtraRuleType, IGridSize as GridSize, IGridType as GridType, ISheetType as SheetType, IGameSchedule as Schedule, IScheduledGamePlan as ScheduledGamePlan, IPrize as Prize, IPrizePattern as PrizePattern, IPrizePatternGroup as PrizePatternGroup, IGame as Game, IGamePlan as GamePlan, GameBlock, LinkedRoomBlock, RoomState, Room, LoopingGamesBlock, BingoBoosterHall, BingoBoosterRoom  } from '@/services/api';
import { Route } from 'vue-router';


@Component({})
export default class BaseForm extends BaseComponent {
//#region Enum Fields

	/** @field numberOfBallsEnum is used to access the NumberOfBalls enum in child templates */
	protected numberOfBallsEnum: typeof NumberOfBalls = NumberOfBalls;

	/** @field ticketColorEnum is used to access the TicketColor enum in child templates */
	protected ticketColorEnum: typeof TicketColor = TicketColor;

	/** @field extraRuleTypeEnum is used to access the ExtraRuleType enum in child templates */
	protected extraRuleTypeEnum: typeof ExtraRuleType = ExtraRuleType;

	/** @field roomStateEnum is used to access the ExtraRuleType enum in child templates */
	protected roomStateEnum: typeof RoomState = RoomState;

//#endregion

//#region Fields

	/** field itemType identifies the type og object being handled by the child form. */
	protected itemType: string = this.$options.name.replace("Form", "");

	/** @field itemId identifies the item being edited in child templates */
	protected itemId!: number;

	/** @field item is the item being edited in child templates */
	protected item: any = {};

	/** @field isDataLoaded indicates when data is loaded, used in child templates where operations depend upon loaded data. */
	protected isDataLoaded: boolean = false;

//#endregion

//#region Component Life Cycle Hooks

	public created() {
		this.itemId = parseInt(this.$route.params.id);
		this.loadItem(this.itemId);
	}

	public mounted() {
		this.$nextTick(() => {
			window.addEventListener("keydown", this.closeFormWithEsc);
			window.addEventListener("keydown", this.submitFormWithCtrlS, false);
			window.addEventListener("keydown", this.submitFormWithEnter);
		});
	}

	public beforeDestroy() {
		this.$nextTick(() => {
			window.removeEventListener("keydown", this.closeFormWithEsc);
			window.removeEventListener("keydown", this.submitFormWithCtrlS);
			window.removeEventListener("keydown", this.submitFormWithEnter);
		});
	}

//#endregion

	/** @method mapItem2Color Override in child Components to map paramter sent to getRgba into a color number.
	 * This method is used in getRgba to map number value into CSS rgba() property. */
	protected mapItem2Color(itemId: any): TicketColor {
		return itemId;
	}

	/** @method getRgba Used to map (aRGB) color number value into CSS rgba() property. */
	protected getRgba(itemId: any): string {
		let color: TicketColor = this.mapItem2Color(itemId);
		return ColorUtilities.colorNumber2cssRgbaString(color);
	}

//#region Overridable Form life cycle methods 

	/** @method loadItem alows child components to control how item is loaded into the form */
	protected get loadItem(): (itemId: number) => void {
		return ((itemId: number) => {
			if(!itemId || itemId <= 0) return;
			this.$store.state.cacheStore.getItem(this.itemType, itemId)
				.then((item: any): void => {
					this.item = item;
					this.itemReactivePropertyExtractor(this.item);	// Extract property objects into reactive properties in child component.
					this.isDataLoaded = true;
			});
		});
	}

	/** @method itemReactivePropertyExtractor alows child components to inject property extractors used when loading the item into the form */
	protected get itemReactivePropertyExtractor(): (item: any) => void {
		return ((item: any) => {});
	}

	/** @method saveItem Saves the the item operated on by this form, then navigates back to the list for this item. */
	protected saveItem() : void{
		if(!this.validateForm()) return;
		this.itemReactivePropertyInserter(this.item);	//Update the item from reactive fields in the child component (form) before saving.
		this.$store.state.cacheStore.saveItem(this.itemType, this.item)
			.then(() => this.itemSavedCallback())
			.catch((error: Error) => {
				this.$notify({
					title: this.localize("Error"),
					message: this.localize("Failed to save data!"),
					type: "warning"
				});
				handleError(error, this, "BaseForm.saveItem() -> " + this.itemType + "Service." + ((this.isNew) ? "createItem": "updateItem") + "()");
			});
	}

	/** @method validateForm alows child components to add validation to the form */
	protected get validateForm(): () => boolean {
		return (() => true);
	}

	/** @method itemReactivePropertyInserter alows child components to inject property initializers used when saving the item from the form */
	protected get itemReactivePropertyInserter(): (item: any) => void {
		return ((item: any) => {});
	}

	/** @method itemSavedCallback alows child components to inject callback function used when item is saved */
	protected get itemSavedCallback(): () => void {
		return (() => {this.$router.push(this.listRoute);});
	}

	/** @method closeForm alows child components to inject close function */
	protected get closeForm(): () => void {
		return (() => {this.$router.push(this.listRoute);});
	}

	/** @property listRoute Referes to the route for the list that lists the items that this form operates on.
	 * Used by saveItem() when saving the form item to navigate back to the list after form operations complete.
	 * This route is derived from the iItemType property, wich again is derived from Component.name.
	 * The route can be overridden in cases where Component.name does not translate to the route of its list. */
	protected get listRoute(): string {
		let route: string = "/";
		route += this.itemType
			.replace(/([a-z])([A-Z])/g, "$1-$2")
			.replace(/\s+/g, '-')
			.toLowerCase();
		route += "s";
		return route;
	}

//#endregion

//#region Browser Event Handlers (Esc, Enter, Ctrl + S)

	public closeFormWithEsc(event: KeyboardEvent): void {
		if(event.keyCode === 27){
			this.closeForm();
		}
	}

	public submitFormWithCtrlS(event: KeyboardEvent): void {
		if ((window.navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey) && event.keyCode == 83) {
			event.preventDefault();
			let button: HTMLButtonElement = <HTMLButtonElement>document.querySelector('button[type="submit"]');
			if(!button) return Logger.log(LogLevel.Warn, this.$options.name + ".submitFormWithCtrlS() - Could not find button of type submit!");
			button.click();	// Form.submit() and this.saveItem() does not trigger Browser form validation!
		}
	}

	public submitFormWithEnter(event: KeyboardEvent): void {
		//TODO: check if any dropdowns are open (as Enter shall select option)
		let textArea: HTMLTextAreaElement = <HTMLTextAreaElement>document.querySelector('textarea');
		if(textArea) return;	//Allow newline in textaraes.
		if(event.keyCode === 13){
			let button: HTMLButtonElement = <HTMLButtonElement>document.querySelector('button[type="submit"]');
			if(!button) return Logger.log(LogLevel.Warn, this.$options.name + ".submitFormWithEnter() - Could not find button of type submit!");
			button.click();	// Form.submit() and this.saveItem() does not trigger Browser form validation!
		}
	}

//#endregion

//#region Properties

	/** @property isNew identifies whether or not the item operated on is newly created or har been retrieved from server. */
	protected get isNew(): boolean {
		return !this.itemId || this.itemId <= 0;
	}

	/** @property emptyResultText reactive property displaying no content text after (no) data loading is complete. */
	protected get emptyResultText(): string {
		return this.localize('There are no items to show');
	}

	protected get bingoBoosterRoomList(): Array<BingoBoosterRoom> {
		return this.$store.state.cacheStore.bingoBoosterRoomList;
	}

	protected get bingoBoosterHallList(): Array<BingoBoosterHall> {
		return this.$store.state.cacheStore.bingoBoosterHallList;
	}

	protected get gamePlanList(): Array<GamePlan> {
		return this.$store.state.cacheStore.gamePlanList;
	}

	protected get linkedRoomBlockList(): Array<LinkedRoomBlock> {
		return this.$store.state.cacheStore.linkedRoomBlockList;
	}

	protected get gameBlockList(): Array<GameBlock> {
		return this.$store.state.cacheStore.gameBlockList;
	}

	protected get loopingGamesBlockList(): Array<LoopingGamesBlock> {
		return this.$store.state.cacheStore.loopingGamesBlockList;
	}

	protected get potList(): Array<Pot> {
		return this.$store.state.cacheStore.potList;
	}

	protected get gameList(): Array<Game> {
		return this.$store.state.cacheStore.gameList;
	}

	protected get prizePatternGroupList(): Array<PrizePatternGroup> {
		return this.$store.state.cacheStore.prizePatternGroupList;
	}

	protected get prizePatternList(): Array<PrizePattern> {
		return this.$store.state.cacheStore.prizePatternList;
	}

	protected get prizeList(): Array<Prize> {
		return this.$store.state.cacheStore.prizeList;
	}

	protected get scheduleList(): Array<SheetType> {
		return this.$store.state.cacheStore.scheduleList;
	}

	protected get roomList(): Array<Room> {
		return this.$store.state.cacheStore.roomList;
	}

	protected get scheduledGamePlanList(): Array<ScheduledGamePlan> {
		return this.$store.state.cacheStore.scheduledGamePlanList;
	}

	protected get sheetTypeList(): Array<SheetType> {
		return this.$store.state.cacheStore.sheetTypeList;
	}

	protected get gridTypeList(): Array<GridType> {
		return this.$store.state.cacheStore.gridTypeList;
	}

	protected get userList(): Array<User> {
		return this.$store.state.cacheStore.userList;
	}

//#endregion

}
