import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, ILoopingGamesBlock, ResponseOfInt32, Response, ResponseOfIEnumerableOfLoopingGamesBlock, IResponseOfLoopingGamesBlock, ResponseOfLoopingGamesBlock, LoopingGamesBlock } from './api';


/**
 * @description Service for handling LoopingGamesBlocks on the server.
 * @extends BaseWebSocketService
 */
export class LoopingGamesBlockService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<ILoopingGamesBlock>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfLoopingGamesBlock> => {
				return hub.getLoopingGamesBlocks();
			})
			.then((result: ResponseOfIEnumerableOfLoopingGamesBlock): Array<ILoopingGamesBlock> => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "LoopingGamesBlockService.getAll() - No Looping Game Blocks found!");
					return new Array<ILoopingGamesBlock>();
				}
				Logger.log(LogLevel.Debug, "LoopingGamesBlockService.getAll() - Server Response: " + result.result.length, result.result);
				return result.result;
			});
	}

	public getItem(id: number): Promise<ILoopingGamesBlock | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfLoopingGamesBlock> => {
				return hub.getLoopingGamesBlock(id);
			}).then((result: IResponseOfLoopingGamesBlock) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "LoopingGamesBlockService.getItem(" + id + ") - No Looping Game Block with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "LoopingGamesBlockService.getItem(" + id + ") - Server Response: ", result.result);
				return result.result;
			});
	}

	public createItem(item: LoopingGamesBlock): Promise<number> {
		Logger.log(LogLevel.Debug, "LoopingGamesBlockService.createItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createLoopingGamesBlock(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "LoopingGamesBlockService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "LoopingGamesBlockService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: LoopingGamesBlock): Promise<void> {
		Logger.log(LogLevel.Debug, "LoopingGamesBlockService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updateLoopingGamesBlock(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "LoopingGamesBlockService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "LoopingGamesBlockService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteLoopingGamesBlock(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "LoopingGamesBlockService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "LoopingGamesBlockService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}
}
