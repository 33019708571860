






















































import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/BaseList.vue';
import { Logger, handleError, ColorUtilities, TimeSpan } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import { IGamePlan as GamePlan, IGame as Game, LoopingGamesBlock, BlockId } from '@/services/api';

let tinycolor = require("tinycolor2");


@Component({
	name: 'LoopingGamesBlockList',
	components: {
		Card
	}
})
export default class LoopingGamesBlockList extends BaseList {

	private fields: Array<any> = [
		{
			key: "id",
			label: this.localize("Id"),
			formatter: this.idFormatter,
			sortable: true
		},
		{
			key: "name",
			label: this.localize("Name")
		},
		{
			key: "color",
			label: this.localize("Color")
		},
		{
			key: "duration",
			label: this.localize("Duration"),
			formatter: this.durationFormatter
		},
		{
			key: "gameIds",
			label: this.localize("Games"),
			formatter: this.gameIdsFormatter
		},
		{
			key: "actions",
			label: this.localize("Actions")
		}
	];

	private idFormatter(value: BlockId, key: string, item: LoopingGamesBlock): string {
		return value.id + "";
	}

	private durationFormatter(value: Array<number>, key: string, item: LoopingGamesBlock): string {
		let dur: TimeSpan = new TimeSpan();
		if(item && item.duration) dur = TimeSpan.parse(item.duration);
		return dur.toString("HH:MM:SS");
	}

	private gameIdsFormatter(value: Array<number>, key: string, item: LoopingGamesBlock): string {
		if(!value || !Array.isArray(value)) return this.localize("undefined");
		let name: string = this.gameList.filter((item: Game) => value.includes(item.id)).map((item: Game): string => item.name).join(', ');
		if(!name) name = value.join(', ');
		return name;
	}

	private getBlockColor(value: number): string {
		let tColor = tinycolor(ColorUtilities.argbToVueColor(value).rgba);
		return tColor.toHexString();
	}

}
