import { Logger } from '@/utilities';
import { LogLevel } from '@/models';


export class Matrix {
	private _rows: number = 0;
	private _columns: number = 0;
	private _matrix: Array<boolean> = new Array<boolean>();

	constructor(rows: number = 0, columns: number = 0, pattern: Array<number> = []) {
		this._matrix = Array<boolean>(rows * columns).fill(false);
		//Logger.log(LogLevel.Trace, "Matrix.constructor(" + rows + ", " + columns + ", " + pattern + ") - matrix.length: " + this._matrix.length);
		this._rows = rows;
		this._columns = columns;
		if(pattern.length > 0) this.pattern = pattern;
	}

	// [index: number]: Array<boolean>;

	// index(value: number) {
	// 	return this._matrix[value];
	// }

	public getRow(row: number): Array<boolean> {
		if(row > this.rows) throw new RangeError("Out of range!");
		let index : number = (row * this.columns) - this.columns - 1;
		return this._matrix.slice(index, this.columns);
	}

	public getColumn(column: number): Array<boolean> {
		if(column > this.columns) throw new RangeError("Out of range!");
		return this._matrix.filter((value: boolean, index: number) => (index + 1) % column == 0);
	}

	public valueAt(row: number, column: number): boolean {
		if(row > this.rows || column > this.columns) throw new RangeError("Out of range!");
		let index : number = (row * this.columns) - this.columns + column - 1;
		return this._matrix[index];
	}

	public toggleValueAt(row: number, column: number): void {
		if(row > this.rows || column > this.columns) throw new RangeError("Out of range!");
		let index : number = (row * this.columns) - this.columns + column - 1;
		let message: string = "Matrix.toggleValueAt(" + row + ", " + column + ") - toggled value from " + this._matrix[index];
		this._matrix[index] = !this._matrix[index];
		message += " to " + this._matrix[index];
		Logger.log(LogLevel.Trace, message);
	}

	public get rows(): number {
		return this._rows;
	}
	public set rows(value: number) {
		if(this._rows < value){
			for(let i = (value - this._rows) * this.columns; i > 0; i--){
				this._matrix[this._matrix.length] = false;
			}
		}
		if(this._rows > value){
			let overflow: number = ((value - this._rows) * this._columns) - this._columns;
			if(this._matrix.length >= overflow) this._matrix.splice(overflow - 1);
		}
		this._rows = value;
	}

	public get columns(): number {
		return this._columns;
	}
	public set columns(value: number) {
		if(this._rows == 0) return;
		if(this._columns < value){
			for(let row = this._rows; row > 0; row--) {	// Have to work in revers in order not to alter index of next splice.
				this._matrix.splice((row * this._columns), 0, ...(new Array<boolean>(value - this._columns).fill(false)));
			}
		}
		if(this._columns > value){
			for(let row = this._rows; row > 0; row--) {	// Have to work in revers in order not to alter index of next splice.
				this._matrix.splice((row * this._columns), this._columns - value);
			}
		}
		//Logger.log(LogLevel.Trace, "Matrix.columns - Set -> new value: " + value + ", old value: " + this._columns + ", actual value: " + this._matrix[0]!.length);
		this._columns = value;
	}

	public get patternAsBoolean(): Array<boolean> {
		return this._matrix;
	}
	public set patternAsBoolean(value: Array<boolean>) {
		this._matrix = value;
	}

	public get pattern(): Array<number> {
		return this._matrix.map((item: boolean, index: number) => {
			if(item) return index;
			return -1;
		})
		.filter((item: number) => item >= 0);
	}
	public set pattern(value: Array<number>) {
		if(!value) {
			this._matrix.fill(false);
			return;
		}
		this._matrix.forEach((item: boolean, index: number) => {
			this._matrix[index] = (value.includes(index)) ? true: false;
		});
		//Logger.log(LogLevel.Trace, "Matrix.pattern Set - patten: " + value + " -> matrix: ", this._matrix);
	}

}
