import { VueColor, rgbaColor } from '@/models';


let tinycolor = require("tinycolor2");

export class ColorUtilities {

	public static colorNumber2cssRgbaString(color: number): string {
		let argbArray: Uint8Array = new Uint8Array([
			(color & 0xff000000) >> 24,
			(color & 0x00ff0000) >> 16,
			(color & 0x0000ff00) >> 8,
			(color & 0x000000ff)
		]);
		return "rgba(" + argbArray[1] + ", " + argbArray[2] + ", " + argbArray[3] + ", " + argbArray[0] / 255 + ")";
	}

	public static toVueColor(value: any): VueColor {
		let returnColor: VueColor = new VueColor();
		let tinyColor = tinycolor(value);
		returnColor.a = tinyColor.toHex();
		returnColor.hex = tinyColor.toHex();
		returnColor.hex8 = tinyColor.toHex8();
		returnColor.hsl = tinyColor.toHsl();
		returnColor.hsv = tinyColor.toHsv();
		returnColor.rgba = tinyColor.toRgb();
		returnColor.source = tinyColor.getFormat();
		return returnColor;
	}

	public static argbToVueColor(value: number): VueColor {
		let returnColor: VueColor = new VueColor();
		let rgb: rgbaColor = new rgbaColor();
		rgb.a = ((value & 0xff000000) >> 24) + "";
		rgb.r = (value & 0x00ff0000) >> 16;
		rgb.g = (value & 0x0000ff00) >> 8;
		rgb.b = (value & 0x000000ff);
		let tinyColor = tinycolor(rgb);
		returnColor.a = tinyColor.toHex();
		returnColor.hex = tinyColor.toHex();
		returnColor.hex8 = tinyColor.toHex8();
		returnColor.hsl = tinyColor.toHsl();
		returnColor.hsv = tinyColor.toHsv();
		returnColor.rgba = tinyColor.toRgb();
		returnColor.source = tinyColor.getFormat();
		return returnColor;
	}

	public static toArgb(value: any): number {
		let tinyColor = tinycolor(value);
		let rgba: rgbaColor = tinyColor.toRgb();
		return (parseInt(rgba.a) << 24)  + (rgba.r << 16) + (rgba.g  << 8) + (rgba.b);
	}

}