import Axios, { AxiosResponse } from 'axios';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';


/** @description Service for handling Authentication with the server. */
export class AuthenticationService {

	public logIn(email: string, password: string): Promise<string> {
		return Axios.post<string>(process.env.VUE_APP_API_URL + "api/auth?username=" + encodeURIComponent(email) + "&password=" + encodeURIComponent(password))
			.then((response: AxiosResponse<string>): string => {
				Logger.log(LogLevel.Debug, "AccountService.logIn(\"" + email + "\", \"***********\") - Server response:", response);
				return response.data;
			});
	}

}
