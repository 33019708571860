






























































































import { Vue, Component } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError } from '@/utilities';
import { LogLevel } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import { IPrizePatternGroup, PrizePatternGroup, IPrizePattern as PrizePattern } from '@/services/api';


@Component({
	name: 'PrizePatternGroupForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"fg-input": fgInput
	}
})
export default class PrizePatternGroupForm extends BaseForm {
	private requiredPrizePatternsToTrigger: number = 0;	//Reactive property for item property that is not a value type (and are not automatically reactive).

	public created() {
		this.requiredPrizePatternsToTrigger = undefined;
	}

	public get title(): string {
		let title: string = this.isNew ? "New Prize Pattern Group" : "Edit Prize Pattern Group";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get itemReactivePropertyExtractor(): (item: any) => void {
		return ((item: PrizePatternGroup) => {
			this.requiredPrizePatternsToTrigger = item.requiredPrizePatternsToTrigger;
		});
	}

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			return true;
		});
	}

	protected get itemReactivePropertyInserter(): (item: any) => void {
		return ((item: PrizePatternGroup) => {
			item.requiredPrizePatternsToTrigger = this.requiredPrizePatternsToTrigger;
		});
	}

//#endregion

	private getRequiredName(value: number): string {
		return value + ' ' + this.localize("of") + " " + this.numberOfSelectedPrizePatterns + " " + this.localize(this.numberOfSelectedPrizePatterns > 1 ? "patterns" : "pattern");
	}

	private get numberOfSelectedPrizePatterns() {
		if(!this.item || !this.item.prizePatternIds || !this.item.prizePatternIds.length) return 0;
		return this.item.prizePatternIds.length;
	}

	public patternsChanged(value: Array<number>): void {
		if(this.numberOfSelectedPrizePatterns == 0) {
			this.requiredPrizePatternsToTrigger = undefined;
			this.item.requiredPrizePatternsToTrigger = undefined;
		}
		if(this.numberOfSelectedPrizePatterns == 1) {
			this.requiredPrizePatternsToTrigger = 1;
			this.item.requiredPrizePatternsToTrigger = 1;
		}
		if(this.requiredPrizePatternsToTrigger > this.numberOfSelectedPrizePatterns) this.requiredPrizePatternsToTrigger = this.numberOfSelectedPrizePatterns;
	}

}
