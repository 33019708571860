import { Mutation, MutationTree } from 'vuex'
import { Logger } from '@/utilities';
import { LogLevel, User, RoomWithStatus } from '@/models';
import { Pot, GridType, SheetType, IGameSchedule as Schedule, ScheduledGamePlan, PrizePattern, PrizePatternGroup, Game, GamePlan, GameBlock, LinkedRoomBlock, LoopingGamesBlock, RoomStatus, BingoBoosterRoom, BingoBoosterHall } from '@/services/api';
import { State } from './state'


export function setBingoBoosterRoomList(state: State, value: Array<BingoBoosterRoom>): void {
	state.bingoBoosterRoomList = value;
}

export function reloadBingoBoosterRoomList(state: State, value: number): void {
	state.reloadBingoBoosterRoomList();
}

export function setBingoBoosterHallList(state: State, value: Array<BingoBoosterHall>): void {
	state.bingoBoosterHallList = value;
}

export function reloadBingoBoosterHallList(state: State, value: number): void {
	state.reloadBingoBoosterHallList();
}

export function setUserList(state: State, value: Array<User>): void {
	state.userList = value;
}

export function reloadUserList(state: State): void {
	state.reloadUserList();
}

export function setGridTypeList(state: State, value: Array<GridType>): void {
	state.gridTypeList = value;
}

export function reloadGridTypeList(state: State): void {
	state.reloadGridTypeList();
}

export function setSheetTypeList(state: State, value: Array<SheetType>): void {
	state.sheetTypeList = value;
}

export function reloadSheetTypeList(state: State, value: number): void {
	state.reloadSheetTypeList();
}

export function setRoomList(state: State, value: Array<RoomWithStatus>): void {
	state.roomList = value;
}

export function reloadRoomList(state: State, value: number): void {
	state.reloadRoomList();
}

export function setRoomStatus(state: State, value: RoomStatus): void {
	state.setRoomStatus(value);
}

export function setScheduleList(state: State, value: Array<Schedule>): void {
	state.scheduleList = value;
}

export function reloadScheduleList(state: State, value: number): void {
	state.reloadScheduleList();
}

export function setScheduledGamePlanList(state: State, value: Array<ScheduledGamePlan>): void {
	state.scheduledGamePlanList = value.map((element: ScheduledGamePlan) => {
		element.day = new Date(element.day);
		return element;
	});
}

export function reloadScheduledGamePlanList(state: State, value: number): void {
	state.reloadScheduledGamePlanList();
}

export function setPrizePatternList(state: State, value: Array<PrizePattern>): void {
	state.prizePatternList = value;
}

export function reloadPrizePatternList(state: State, value: number): void {
	state.reloadPrizePatternList();
}

export function setPrizePatternGroupList(state: State, value: Array<PrizePatternGroup>): void {
	state.prizePatternGroupList = value;
}

export function reloadPrizePatternGroupList(state: State, value: number): void {
	state.reloadPrizePatternGroupList();
}

export function setPotList(state: State, value: Array<Pot>): void {
	state.potList = value;
}

export function reloadPotList(state: State, value: number): void {
	state.reloadPotList();
}

export function setGameList(state: State, value: Array<Game>): void {
	state.gameList = value;
}

export function reloadGameList(state: State, value: number): void {
	state.reloadGameList();
}

export function setGameBlockList(state: State, value: Array<GameBlock>): void {
	state.gameBlockList = value;
}

export function setLoopingGamesBlockList(state: State, value: Array<LoopingGamesBlock>): void {
	state.loopingGamesBlockList = value;
}

export function setLinkedRoomBlockList(state: State, value: Array<LinkedRoomBlock>): void {
	state.linkedRoomBlockList = value;
}

export function reloadGameBlockList(state: State, value: number): void {
	state.reloadGameBlockList();
}

export function reloadLoopingGamesBlockList(state: State, value: number): void {
	state.reloadLoopingGamesBlockList();
}

export function reloadLinkedRoomBlockList(state: State, value: number): void {
	state.reloadLinkedRoomBlockList();
}

export function setGamePlanList(state: State, value: Array<GamePlan>): void {
	state.gamePlanList = value;
}

export function reloadGamePlanList(state: State, value: number): void {
	state.reloadGamePlanList();
}

export function resetStateForNewCustomer(state: State): void {
	//Don't touch the cache when serving new customers.
	Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.Mutations.resetStateForNewCustomer() - Cache untouched!");
}

/** @description Clear all data and set application in known state */
export function resetStateToRecoverFromError(state: State): void {
	state.reset();
	Logger.log(LogLevel.Trace, "Vuex.Store.CacheStore.Mutations.resetStateToRecoverFromError() - State reset!");
}


export default <MutationTree<State>> {
	setBingoBoosterRoomList,
	reloadBingoBoosterRoomList,
	setBingoBoosterHallList,
	reloadBingoBoosterHallList,
	setUserList,
	reloadUserList,
	setGridTypeList,
	reloadGridTypeList,
	setSheetTypeList,
	reloadSheetTypeList,
	setRoomList,
	reloadRoomList,
	setRoomStatus,
	setScheduleList,
	reloadScheduleList,
	setScheduledGamePlanList,
	reloadScheduledGamePlanList,
	setPrizePatternList,
	reloadPrizePatternList,
	setPrizePatternGroupList,
	reloadPrizePatternGroupList,
	setPotList,
	reloadPotList,
	setGameList,
	reloadGameList,
	setGameBlockList,
	reloadGameBlockList,
	setLoopingGamesBlockList,
	reloadLoopingGamesBlockList,
	setLinkedRoomBlockList,
	reloadLinkedRoomBlockList,
	setGamePlanList,
	reloadGamePlanList,
	resetStateForNewCustomer,
	resetStateToRecoverFromError
}
