










































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
	name: "fg-input"
})
export default class FormGroupInput extends Vue {
	inheritAttrs: boolean = false;

	@Prop(String)
	label: string;

	@Prop(String)
	error: string;

	@Prop(String)
	labelClasses: string;

	@Prop(String)
	inputClasses: string;

	@Prop(String)
	help: string;

	@Prop([String, Number])
	value: string | number;

	@Prop(String)
	addonRightIcon: string;

	@Prop(String)
	addonLeftIcon: string;

	get hasIcon() {
		const {addonRight, addonLeft} = this.$slots
		return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
	}
}
