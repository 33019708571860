























import { Vue, Component, Prop } from 'vue-property-decorator';
import Card from './Card.vue';

@Component({
	name: "stats-card",
	components: {
		Card
	}
})
export default class StatsCard extends Vue {

	@Prop(String)
	title: string;

	@Prop(String)
	subTitle: string;

}
