/* tslint:disable */
export class AdminHub {
    public constructor(private connection: any) {
    }

    public giveMeSomeMoneyPlz(airDiceSessionId: string): Promise<number> {
        return this.connection.invoke('GiveMeSomeMoneyPlz', airDiceSessionId);
    }

    public airDicePlatformSaldo(airDiceSessionId: string): Promise<number> {
        return this.connection.invoke('AirDicePlatformSaldo', airDiceSessionId);
    }

    public forceDisconnectBingoBooster(roomId: number): Promise<Void> {
        return this.connection.invoke('ForceDisconnectBingoBooster', roomId);
    }

    public createTestBingoBoosterSettigns(): Promise<Void> {
        return this.connection.invoke('CreateTestBingoBoosterSettigns');
    }

    public createRoom(room: Room): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateRoom', room);
    }

    public getRoom(roomId: number): Promise<ResponseOfRoom> {
        return this.connection.invoke('GetRoom', roomId);
    }

    public getRooms(): Promise<ResponseOfIEnumerableOfRoom> {
        return this.connection.invoke('GetRooms');
    }

    public updateRoom(room: Room): Promise<Response> {
        return this.connection.invoke('UpdateRoom', room);
    }

    public deleteRoom(roomId: number): Promise<Response> {
        return this.connection.invoke('DeleteRoom', roomId);
    }

    public createSchedule(schedule: GameSchedule): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateSchedule', schedule);
    }

    public getSchedule(scheduleId: number): Promise<ResponseOfGameSchedule> {
        return this.connection.invoke('GetSchedule', scheduleId);
    }

    public getSchedules(): Promise<ResponseOfIEnumerableOfGameSchedule> {
        return this.connection.invoke('GetSchedules');
    }

    public updateSchedule(schedule: GameSchedule): Promise<Response> {
        return this.connection.invoke('UpdateSchedule', schedule);
    }

    public deleteSchedule(scheduleId: number): Promise<Response> {
        return this.connection.invoke('DeleteSchedule', scheduleId);
    }

    /** Adds ScheduledGamePlan
If element.ScheduledGames[].StartTime is null it will be autocalculated */
    public createScheduledGamePlan(element: ScheduledGamePlan): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateScheduledGamePlan', element);
    }

    /** Adds multiple ScheduledGamePlans
Insert ScheduledGamePlan all days between from and to date (inclusive)
Date part of element.StartTimes and .EndTime will be ignored
If element.ScheduledGames[].StartTime is null it will be autocalculated */
    public createScheduledGamePlans(elements: ScheduledGamePlan[]): Promise<ResponseOfInt32Of> {
        return this.connection.invoke('CreateScheduledGamePlans', elements);
    }

    public getScheduledGamePlan(id: number): Promise<ResponseOfScheduledGamePlan> {
        return this.connection.invoke('GetScheduledGamePlan', id);
    }

    public getScheduledGamePlans(): Promise<ResponseOfIEnumerableOfScheduledGamePlan> {
        return this.connection.invoke('GetScheduledGamePlans');
    }

    public updateScheduledGamePlan(element: ScheduledGamePlan): Promise<Response> {
        return this.connection.invoke('UpdateScheduledGamePlan', element);
    }

    public deleteScheduledGamePlan(id: number): Promise<Response> {
        return this.connection.invoke('DeleteScheduledGamePlan', id);
    }

    public deleteScheduledGamePlans(ids: number[]): Promise<Response> {
        return this.connection.invoke('DeleteScheduledGamePlans', ids);
    }

    /** Creates GridType, default Patterns, default PatternGroups and generates grids
Warning: This operation is slow */
    public createGridType(element: GridType): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateGridType', element);
    }

    public getGridType(id: number): Promise<ResponseOfGridType> {
        return this.connection.invoke('GetGridType', id);
    }

    public getGridTypes(): Promise<ResponseOfIEnumerableOfGridType> {
        return this.connection.invoke('GetGridTypes');
    }

    /** Deletes all Patterns and PatternGroups relating to this GridType
Warning: This operation is slow */
    public updateGridType(element: GridType): Promise<Response> {
        return this.connection.invoke('UpdateGridType', element);
    }

    /** Deletes all Patterns and PatternGroups relating to this GridType
Warning: This operation is slow */
    public deleteGridType(id: number): Promise<Response> {
        return this.connection.invoke('DeleteGridType', id);
    }

    public createSheetType(element: SheetType): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateSheetType', element);
    }

    public getSheetType(id: number): Promise<ResponseOfSheetType> {
        return this.connection.invoke('GetSheetType', id);
    }

    public getSheetTypes(): Promise<ResponseOfIEnumerableOfSheetType> {
        return this.connection.invoke('GetSheetTypes');
    }

    public updateSheetType(element: SheetType): Promise<Response> {
        return this.connection.invoke('UpdateSheetType', element);
    }

    public deleteSheetType(id: number): Promise<Response> {
        return this.connection.invoke('DeleteSheetType', id);
    }

    /** Creates a gameplan.
Each StartTime in ScheduledGames will be override and set to StartTime + sum of previous games's MaxDuration */
    public createGamePlan(element: GamePlan): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateGamePlan', element);
    }

    public getGamePlan(id: number): Promise<ResponseOfGamePlan> {
        return this.connection.invoke('GetGamePlan', id);
    }

    public getGamePlans(): Promise<ResponseOfIEnumerableOfGamePlan> {
        return this.connection.invoke('GetGamePlans');
    }

    public updateGamePlan(element: GamePlan): Promise<Response> {
        return this.connection.invoke('UpdateGamePlan', element);
    }

    public deleteGamePlan(id: number): Promise<Response> {
        return this.connection.invoke('DeleteGamePlan', id);
    }

    public createGame(element: Game): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateGame', element);
    }

    public getGame(id: number): Promise<ResponseOfGame> {
        return this.connection.invoke('GetGame', id);
    }

    public getGames(): Promise<ResponseOfIEnumerableOfGame> {
        return this.connection.invoke('GetGames');
    }

    public updateGame(element: Game): Promise<Response> {
        return this.connection.invoke('UpdateGame', element);
    }

    public deleteGame(id: number): Promise<Response> {
        return this.connection.invoke('DeleteGame', id);
    }

    public createPrizePattern(element: PrizePattern): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreatePrizePattern', element);
    }

    public getPrizePattern(id: number): Promise<ResponseOfPrizePattern> {
        return this.connection.invoke('GetPrizePattern', id);
    }

    public getPrizePatterns(): Promise<ResponseOfIEnumerableOfPrizePattern> {
        return this.connection.invoke('GetPrizePatterns');
    }

    public updatePrizePattern(element: PrizePattern): Promise<Response> {
        return this.connection.invoke('UpdatePrizePattern', element);
    }

    public deletePrizePattern(id: number): Promise<Response> {
        return this.connection.invoke('DeletePrizePattern', id);
    }

    public createPrizePatternGroup(element: PrizePatternGroup): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreatePrizePatternGroup', element);
    }

    public getPrizePatternGroup(id: number): Promise<ResponseOfPrizePatternGroup> {
        return this.connection.invoke('GetPrizePatternGroup', id);
    }

    public getPrizePatternGroups(): Promise<ResponseOfIEnumerableOfPrizePatternGroup> {
        return this.connection.invoke('GetPrizePatternGroups');
    }

    public updatePrizePatternGroup(element: PrizePatternGroup): Promise<Response> {
        return this.connection.invoke('UpdatePrizePatternGroup', element);
    }

    public deletePrizePatternGroup(id: number): Promise<Response> {
        return this.connection.invoke('DeletePrizePatternGroup', id);
    }

    /** Obsolete */
    public createColorPrize(element: ColorPrize): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateColorPrize', element);
    }

    /** Obsolete */
    public getColorPrize(id: number): Promise<ResponseOfColorPrize> {
        return this.connection.invoke('GetColorPrize', id);
    }

    /** Obsolete */
    public getColorPrizes(): Promise<ResponseOfIEnumerableOfColorPrize> {
        return this.connection.invoke('GetColorPrizes');
    }

    /** Obsolete */
    public updateColorPrize(element: ColorPrize): Promise<Response> {
        return this.connection.invoke('UpdateColorPrize', element);
    }

    /** Obsolete */
    public deleteColorPrize(id: number): Promise<Response> {
        return this.connection.invoke('DeleteColorPrize', id);
    }

    public createPot(element: Pot): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreatePot', element);
    }

    public getPot(id: number): Promise<ResponseOfPot> {
        return this.connection.invoke('GetPot', id);
    }

    public getPots(): Promise<ResponseOfIEnumerableOfPot> {
        return this.connection.invoke('GetPots');
    }

    public updatePot(element: Pot): Promise<Response> {
        return this.connection.invoke('UpdatePot', element);
    }

    public deletePot(id: number): Promise<Response> {
        return this.connection.invoke('DeletePot', id);
    }

    public createGameBlock(element: GameBlock): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateGameBlock', element);
    }

    public getGameBlock(id: number): Promise<ResponseOfGameBlock> {
        return this.connection.invoke('GetGameBlock', id);
    }

    public getGameBlocks(): Promise<ResponseOfIEnumerableOfGameBlock> {
        return this.connection.invoke('GetGameBlocks');
    }

    public updateGameBlock(element: GameBlock): Promise<Response> {
        return this.connection.invoke('UpdateGameBlock', element);
    }

    public deleteGameBlock(id: number): Promise<Response> {
        return this.connection.invoke('DeleteGameBlock', id);
    }

    public createLinkedRoomBlock(element: LinkedRoomBlock): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateLinkedRoomBlock', element);
    }

    public getLinkedRoomBlock(id: number): Promise<ResponseOfLinkedRoomBlock> {
        return this.connection.invoke('GetLinkedRoomBlock', id);
    }

    public getLinkedRoomBlocks(): Promise<ResponseOfIEnumerableOfLinkedRoomBlock> {
        return this.connection.invoke('GetLinkedRoomBlocks');
    }

    public updateLinkedRoomBlock(element: LinkedRoomBlock): Promise<Response> {
        return this.connection.invoke('UpdateLinkedRoomBlock', element);
    }

    public deleteLinkedRoomBlock(id: number): Promise<Response> {
        return this.connection.invoke('DeleteLinkedRoomBlock', id);
    }

    public createLoopingGamesBlock(element: LoopingGamesBlock): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateLoopingGamesBlock', element);
    }

    public getLoopingGamesBlock(id: number): Promise<ResponseOfLoopingGamesBlock> {
        return this.connection.invoke('GetLoopingGamesBlock', id);
    }

    public getLoopingGamesBlocks(): Promise<ResponseOfIEnumerableOfLoopingGamesBlock> {
        return this.connection.invoke('GetLoopingGamesBlocks');
    }

    public updateLoopingGamesBlock(element: LoopingGamesBlock): Promise<Response> {
        return this.connection.invoke('UpdateLoopingGamesBlock', element);
    }

    public deleteLoopingGamesBlock(id: number): Promise<Response> {
        return this.connection.invoke('DeleteLoopingGamesBlock', id);
    }

    public getWonPrizes(from: Date, to: Date, offset: number, count: number, includeOverriden: boolean): Promise<ResponseOfWonPrizeOf> {
        return this.connection.invoke('GetWonPrizes', from, to, offset, count, includeOverriden);
    }

    public getAppSetting(id: number): Promise<ResponseOfAppSetting> {
        return this.connection.invoke('GetAppSetting', id);
    }

    public getAppSettings(): Promise<ResponseOfIEnumerableOfAppSetting> {
        return this.connection.invoke('GetAppSettings');
    }

    public updateAppSetting(element: AppSetting): Promise<Response> {
        return this.connection.invoke('UpdateAppSetting', element);
    }

    public createBingoBoosterRoom(element: BingoBoosterRoom): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateBingoBoosterRoom', element);
    }

    public getBingoBoosterRoom(id: number): Promise<ResponseOfBingoBoosterRoom> {
        return this.connection.invoke('GetBingoBoosterRoom', id);
    }

    public getBingoBoosterRooms(): Promise<ResponseOfIEnumerableOfBingoBoosterRoom> {
        return this.connection.invoke('GetBingoBoosterRooms');
    }

    public updateBingoBoosterRoom(element: BingoBoosterRoom): Promise<Response> {
        return this.connection.invoke('UpdateBingoBoosterRoom', element);
    }

    public deleteBingoBoosterRoom(id: number): Promise<Response> {
        return this.connection.invoke('DeleteBingoBoosterRoom', id);
    }

    public createBingoBoosterHall(element: BingoBoosterHall): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateBingoBoosterHall', element);
    }

    public getBingoBoosterHall(id: number): Promise<ResponseOfBingoBoosterHall> {
        return this.connection.invoke('GetBingoBoosterHall', id);
    }

    public getBingoBoosterHalls(): Promise<ResponseOfIEnumerableOfBingoBoosterHall> {
        return this.connection.invoke('GetBingoBoosterHalls');
    }

    public updateBingoBoosterHall(element: BingoBoosterHall): Promise<Response> {
        return this.connection.invoke('UpdateBingoBoosterHall', element);
    }

    public deleteBingoBoosterHall(id: number): Promise<Response> {
        return this.connection.invoke('DeleteBingoBoosterHall', id);
    }

    public createAirDicePlatformRoom(element: AirDicePlatformRoom): Promise<ResponseOfInt32> {
        return this.connection.invoke('CreateAirDicePlatformRoom', element);
    }

    public getAirDicePlatformRoom(id: number): Promise<ResponseOfAirDicePlatformRoom> {
        return this.connection.invoke('GetAirDicePlatformRoom', id);
    }

    public getAirDicePlatformRooms(): Promise<ResponseOfIEnumerableOfAirDicePlatformRoom> {
        return this.connection.invoke('GetAirDicePlatformRooms');
    }

    public updateAirDicePlatformRoom(element: AirDicePlatformRoom): Promise<Response> {
        return this.connection.invoke('UpdateAirDicePlatformRoom', element);
    }

    public deleteAirDicePlatformRoom(id: number): Promise<Response> {
        return this.connection.invoke('DeleteAirDicePlatformRoom', id);
    }

    public reconnectBingoBooster(roomId: number): Promise<Response> {
        return this.connection.invoke('ReconnectBingoBooster', roomId);
    }

    public getGrid(gridTypeId: number, id: number): Promise<ResponseOfIEnumerableOfGrid> {
        return this.connection.invoke('GetGrid', gridTypeId, id);
    }

    public getGrids(gridTypeId: number, ids: number[]): Promise<ResponseOfIEnumerableOfGrid> {
        return this.connection.invoke('GetGrids', gridTypeId, ids);
    }

    public openRoom(roomId: number): Promise<Response> {
        return this.connection.invoke('OpenRoom', roomId);
    }

    public closeRoom(roomId: number): Promise<Response> {
        return this.connection.invoke('CloseRoom', roomId);
    }

    public forceCloseRoom(roomId: number): Promise<Response> {
        return this.connection.invoke('ForceCloseRoom', roomId);
    }

    public restartRoom(roomId: number): Promise<Response> {
        return this.connection.invoke('RestartRoom', roomId);
    }

    public roomStatus(roomId: number): Promise<ResponseOfRoomStatus> {
        return this.connection.invoke('RoomStatus', roomId);
    }

    /** Obsolete. Use OpenRoom instead */
    public startRoom(roomId: number): Promise<Response> {
        return this.connection.invoke('StartRoom', roomId);
    }

    /** Obsolete. Use CloseRoom instead */
    public stopRoom(roomId: number): Promise<Response> {
        return this.connection.invoke('StopRoom', roomId);
    }

    /** Obsolete. Use ForceCloseRoom instead */
    public forceStopRoom(roomId: number): Promise<Response> {
        return this.connection.invoke('ForceStopRoom', roomId);
    }

    public createAccount(account: Account): Promise<ResponseOfAccount> {
        return this.connection.invoke('CreateAccount', account);
    }

    public getAccount(id: number): Promise<ResponseOfAccount> {
        return this.connection.invoke('GetAccount', id);
    }

    public getAccounts(): Promise<ResponseOfIEnumerableOfAccount> {
        return this.connection.invoke('GetAccounts');
    }

    public updateAccount(account: Account): Promise<ResponseOfAccount> {
        return this.connection.invoke('UpdateAccount', account);
    }

    /** Deletes the account to the user currently signed in */
    public deleteMe(): Promise<Response> {
        return this.connection.invoke('DeleteMe');
    }

    public deleteAccount(id: number): Promise<Response> {
        return this.connection.invoke('DeleteAccount', id);
    }

    public deleteDatabase(): Promise<Void> {
        return this.connection.invoke('DeleteDatabase');
    }

    public addScheduledGameplanNextXDays(days: number, gameplanId: number, scheduleId: number): Promise<Response> {
        return this.connection.invoke('AddScheduledGameplanNextXDays', days, gameplanId, scheduleId);
    }

    public getScheduledGameplansForToday(scheduledGamePlanId: number): Promise<number[]> {
        return this.connection.invoke('GetScheduledGameplansForToday', scheduledGamePlanId);
    }

    public getGameStartTime(scheduledGamePlanId: number): Promise<GameStartTime[]> {
        return this.connection.invoke('GetGameStartTime', scheduledGamePlanId);
    }

    public getGameState(runningGameId: number): Promise<RunningGame> {
        return this.connection.invoke('GetGameState', runningGameId);
    }

    public getConnectedHallMonitoringInfo(): Promise<HallInformation[]> {
        return this.connection.invoke('GetConnectedHallMonitoringInfo');
    }

    public getHallMonitoringInfo(): Promise<HallInformation[]> {
        return this.connection.invoke('GetHallMonitoringInfo');
    }

    public getRunningGames(): Promise<number[]> {
        return this.connection.invoke('GetRunningGames');
    }

    public getRunningRooms(): Promise<number[]> {
        return this.connection.invoke('GetRunningRooms');
    }

    public getScheduledGamePlansFromRange(scheduleId: number, day: Date, from: string, to: string): Promise<ScheduledGamePlan> {
        return this.connection.invoke('GetScheduledGamePlansFromRange', scheduleId, day, from, to);
    }

    public createTestDataFast(data: CreateTestData): Promise<string> {
        return this.connection.invoke('CreateTestDataFast', data);
    }

    public createTickets(count: number): Promise<string> {
        return this.connection.invoke('CreateTickets', count);
    }

    public registerCallbacks(implementation: IAdminHubCallbacks) {
        this.connection.on('RoomStateChanged', (roomStatus: RoomStatus) => implementation.roomStateChanged(roomStatus));
        this.connection.on('GameStateUpdate', (gameStateUpdate: GameStateUpdate) => implementation.gameStateUpdate(gameStateUpdate));
        this.connection.on('GameStarted', (id: number) => implementation.gameStarted(id));
        this.connection.on('DomainEvent', (eventData: any) => implementation.domainEvent(eventData));
    }
}

export interface IAdminHubCallbacks {
    roomStateChanged(roomStatus: RoomStatus): void;
    gameStateUpdate(gameStateUpdate: GameStateUpdate): void;
    gameStarted(id: number): void;
    domainEvent(eventData: any): void;
}

export class Void implements IVoid {
    public static fromJS(data: any): Void {
        data = typeof data === 'object' ? data : {};
        let result = new Void();
        result.init(data);
        return result;
    }



    constructor(data?: IVoid) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        return data;
    }
}

export interface IVoid {
}

export class ResponseOfInt32 implements IResponseOfInt32 {
    public static fromJS(data: any): ResponseOfInt32 {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfInt32();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: number;


    constructor(data?: IResponseOfInt32) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result;
        return data;
    }
}

export interface IResponseOfInt32 {
    success: boolean;
    errorMessage: string | undefined;
    result: number;
}

export class Room implements IRoom {
    public static fromJS(data: any): Room {
        data = typeof data === 'object' ? data : {};
        let result = new Room();
        result.init(data);
        return result;
    }

    public id!: number;
    public name!: string | undefined;
    public description!: string | undefined;
    public scheduleId!: number;


    constructor(data?: IRoom) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.name = data["name"];
            this.description = data["description"];
            this.scheduleId = data["scheduleId"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["description"] = this.description;
        data["scheduleId"] = this.scheduleId;
        return data;
    }
}

export interface IRoom {
    id: number;
    name: string | undefined;
    description: string | undefined;
    scheduleId: number;
}

export class ResponseOfRoom implements IResponseOfRoom {
    public static fromJS(data: any): ResponseOfRoom {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfRoom();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: Room | undefined;


    constructor(data?: IResponseOfRoom) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? Room.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfRoom {
    success: boolean;
    errorMessage: string | undefined;
    result: Room | undefined;
}

export class ResponseOfIEnumerableOfRoom implements IResponseOfIEnumerableOfRoom {
    public static fromJS(data: any): ResponseOfIEnumerableOfRoom {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfRoom();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: Room[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfRoom) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(Room.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfRoom {
    success: boolean;
    errorMessage: string | undefined;
    result: Room[] | undefined;
}

export class Response implements IResponse {
    public static fromJS(data: any): Response {
        data = typeof data === 'object' ? data : {};
        let result = new Response();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;


    constructor(data?: IResponse) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        return data;
    }
}

export interface IResponse {
    success: boolean;
    errorMessage: string | undefined;
}

export class GameSchedule implements IGameSchedule {
    public static fromJS(data: any): GameSchedule {
        data = typeof data === 'object' ? data : {};
        let result = new GameSchedule();
        result.init(data);
        return result;
    }

    public id!: number;
    public name!: string | undefined;
    public description!: string | undefined;
    public scheduledGamePlansIds!: number[] | undefined;


    constructor(data?: IGameSchedule) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.name = data["name"];
            this.description = data["description"];
            if (data["scheduledGamePlansIds"] && data["scheduledGamePlansIds"].constructor === Array) {
                this.scheduledGamePlansIds = [];
                for (let item of data["scheduledGamePlansIds"])
                    this.scheduledGamePlansIds.push(item);
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["description"] = this.description;
        if (this.scheduledGamePlansIds && this.scheduledGamePlansIds.constructor === Array) {
            data["scheduledGamePlansIds"] = [];
            for (let item of this.scheduledGamePlansIds)
                data["scheduledGamePlansIds"].push(item);
        }
        return data;
    }
}

export interface IGameSchedule {
    id: number;
    name: string | undefined;
    description: string | undefined;
    scheduledGamePlansIds: number[] | undefined;
}

export class ResponseOfGameSchedule implements IResponseOfGameSchedule {
    public static fromJS(data: any): ResponseOfGameSchedule {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfGameSchedule();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: GameSchedule | undefined;


    constructor(data?: IResponseOfGameSchedule) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? GameSchedule.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfGameSchedule {
    success: boolean;
    errorMessage: string | undefined;
    result: GameSchedule | undefined;
}

export class ResponseOfIEnumerableOfGameSchedule implements IResponseOfIEnumerableOfGameSchedule {
    public static fromJS(data: any): ResponseOfIEnumerableOfGameSchedule {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfGameSchedule();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: GameSchedule[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfGameSchedule) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(GameSchedule.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfGameSchedule {
    success: boolean;
    errorMessage: string | undefined;
    result: GameSchedule[] | undefined;
}

export class ScheduledGamePlan implements IScheduledGamePlan {
    public static fromJS(data: any): ScheduledGamePlan {
        data = typeof data === 'object' ? data : {};
        let result = new ScheduledGamePlan();
        result.init(data);
        return result;
    }

    public id!: number;
    public gamePlanId!: number;
    public day!: Date;


    constructor(data?: IScheduledGamePlan) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.gamePlanId = data["gamePlanId"];
            this.day = data["day"] ? new Date(data["day"].toString()) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["gamePlanId"] = this.gamePlanId;
        data["day"] = this.day ? this.day.toISOString() : <any>undefined;
        return data;
    }
}

export interface IScheduledGamePlan {
    id: number;
    gamePlanId: number;
    day: Date;
}

export class ResponseOfInt32Of implements IResponseOfInt32Of {
    public static fromJS(data: any): ResponseOfInt32Of {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfInt32Of();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: number[] | undefined;


    constructor(data?: IResponseOfInt32Of) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(item);
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item);
        }
        return data;
    }
}

export interface IResponseOfInt32Of {
    success: boolean;
    errorMessage: string | undefined;
    result: number[] | undefined;
}

export class ResponseOfScheduledGamePlan implements IResponseOfScheduledGamePlan {
    public static fromJS(data: any): ResponseOfScheduledGamePlan {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfScheduledGamePlan();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: ScheduledGamePlan | undefined;


    constructor(data?: IResponseOfScheduledGamePlan) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? ScheduledGamePlan.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfScheduledGamePlan {
    success: boolean;
    errorMessage: string | undefined;
    result: ScheduledGamePlan | undefined;
}

export class ResponseOfIEnumerableOfScheduledGamePlan implements IResponseOfIEnumerableOfScheduledGamePlan {
    public static fromJS(data: any): ResponseOfIEnumerableOfScheduledGamePlan {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfScheduledGamePlan();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: ScheduledGamePlan[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfScheduledGamePlan) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(ScheduledGamePlan.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfScheduledGamePlan {
    success: boolean;
    errorMessage: string | undefined;
    result: ScheduledGamePlan[] | undefined;
}

export class GridType implements IGridType {
    public static fromJS(data: any): GridType {
        data = typeof data === 'object' ? data : {};
        let result = new GridType();
        result.init(data);
        return result;
    }

    public id!: number;
    public gridSize!: GridSize | undefined;
    public maxGridsPerSheet!: number;
    public firstId!: number;
    public freeplayFields!: number[] | undefined;
    public blanksPerRow!: number;


    constructor(data?: IGridType) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.gridSize = data["gridSize"] ? GridSize.fromJS(data["gridSize"]) : <any>undefined;
            this.maxGridsPerSheet = data["maxGridsPerSheet"];
            this.firstId = data["firstId"];
            if (data["freeplayFields"] && data["freeplayFields"].constructor === Array) {
                this.freeplayFields = [];
                for (let item of data["freeplayFields"])
                    this.freeplayFields.push(item);
            }
            this.blanksPerRow = data["blanksPerRow"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["gridSize"] = this.gridSize ? this.gridSize.toJSON() : <any>undefined;
        data["maxGridsPerSheet"] = this.maxGridsPerSheet;
        data["firstId"] = this.firstId;
        if (this.freeplayFields && this.freeplayFields.constructor === Array) {
            data["freeplayFields"] = [];
            for (let item of this.freeplayFields)
                data["freeplayFields"].push(item);
        }
        data["blanksPerRow"] = this.blanksPerRow;
        return data;
    }
}

export interface IGridType {
    id: number;
    gridSize: GridSize | undefined;
    maxGridsPerSheet: number;
    firstId: number;
    freeplayFields: number[] | undefined;
    blanksPerRow: number;
}

export class GridSize implements IGridSize {
    public static fromJS(data: any): GridSize {
        data = typeof data === 'object' ? data : {};
        let result = new GridSize();
        result.init(data);
        return result;
    }

    public rows!: number;
    public columns!: number;
    public numberOfBalls!: number;


    constructor(data?: IGridSize) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.rows = data["rows"];
            this.columns = data["columns"];
            this.numberOfBalls = data["numberOfBalls"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["rows"] = this.rows;
        data["columns"] = this.columns;
        data["numberOfBalls"] = this.numberOfBalls;
        return data;
    }
}

export interface IGridSize {
    rows: number;
    columns: number;
    numberOfBalls: number;
}

export class ResponseOfGridType implements IResponseOfGridType {
    public static fromJS(data: any): ResponseOfGridType {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfGridType();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: GridType | undefined;


    constructor(data?: IResponseOfGridType) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? GridType.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfGridType {
    success: boolean;
    errorMessage: string | undefined;
    result: GridType | undefined;
}

export class ResponseOfIEnumerableOfGridType implements IResponseOfIEnumerableOfGridType {
    public static fromJS(data: any): ResponseOfIEnumerableOfGridType {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfGridType();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: GridType[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfGridType) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(GridType.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfGridType {
    success: boolean;
    errorMessage: string | undefined;
    result: GridType[] | undefined;
}

export class SheetType implements ISheetType {
    public static fromJS(data: any): SheetType {
        data = typeof data === 'object' ? data : {};
        let result = new SheetType();
        result.init(data);
        return result;
    }

    public id!: number;
    public name!: string | undefined;
    public gridTypeId!: number;
    public color!: number;
    public price!: number;
    public gridsPerSheet!: number;


    constructor(data?: ISheetType) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.name = data["name"];
            this.gridTypeId = data["gridTypeId"];
            this.color = data["color"];
            this.price = data["price"];
            this.gridsPerSheet = data["gridsPerSheet"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["gridTypeId"] = this.gridTypeId;
        data["color"] = this.color;
        data["price"] = this.price;
        data["gridsPerSheet"] = this.gridsPerSheet;
        return data;
    }
}

export interface ISheetType {
    id: number;
    name: string | undefined;
    gridTypeId: number;
    color: number;
    price: number;
    gridsPerSheet: number;
}

export class ResponseOfSheetType implements IResponseOfSheetType {
    public static fromJS(data: any): ResponseOfSheetType {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfSheetType();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: SheetType | undefined;


    constructor(data?: IResponseOfSheetType) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? SheetType.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfSheetType {
    success: boolean;
    errorMessage: string | undefined;
    result: SheetType | undefined;
}

export class ResponseOfIEnumerableOfSheetType implements IResponseOfIEnumerableOfSheetType {
    public static fromJS(data: any): ResponseOfIEnumerableOfSheetType {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfSheetType();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: SheetType[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfSheetType) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(SheetType.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfSheetType {
    success: boolean;
    errorMessage: string | undefined;
    result: SheetType[] | undefined;
}

export class GamePlan implements IGamePlan {
    public static fromJS(data: any): GamePlan {
        data = typeof data === 'object' ? data : {};
        let result = new GamePlan();
        result.init(data);
        return result;
    }

    public id!: number;
    public name!: string | undefined;
    public description!: string | undefined;
    public startTime!: string;
    public blockIds!: BlockId[] | undefined;


    constructor(data?: IGamePlan) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.name = data["name"];
            this.description = data["description"];
            this.startTime = data["startTime"];
            if (data["blockIds"] && data["blockIds"].constructor === Array) {
                this.blockIds = [];
                for (let item of data["blockIds"])
                    this.blockIds.push(BlockId.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["description"] = this.description;
        data["startTime"] = this.startTime;
        if (this.blockIds && this.blockIds.constructor === Array) {
            data["blockIds"] = [];
            for (let item of this.blockIds)
                data["blockIds"].push(item.toJSON());
        }
        return data;
    }
}

export interface IGamePlan {
    id: number;
    name: string | undefined;
    description: string | undefined;
    startTime: string;
    blockIds: BlockId[] | undefined;
}

export class BlockId implements IBlockId {
    public static fromJS(data: any): BlockId {
        data = typeof data === 'object' ? data : {};
        let result = new BlockId();
        result.init(data);
        return result;
    }

    public id!: number;
    public type!: BlockType;


    constructor(data?: IBlockId) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.type = data["type"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["type"] = this.type;
        return data;
    }
}

export interface IBlockId {
    id: number;
    type: BlockType;
}

export enum BlockType {
    Link = 0,
    Game = 1,
    Looping = 2,
}

export class ResponseOfGamePlan implements IResponseOfGamePlan {
    public static fromJS(data: any): ResponseOfGamePlan {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfGamePlan();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: GamePlan | undefined;


    constructor(data?: IResponseOfGamePlan) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? GamePlan.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfGamePlan {
    success: boolean;
    errorMessage: string | undefined;
    result: GamePlan | undefined;
}

export class ResponseOfIEnumerableOfGamePlan implements IResponseOfIEnumerableOfGamePlan {
    public static fromJS(data: any): ResponseOfIEnumerableOfGamePlan {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfGamePlan();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: GamePlan[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfGamePlan) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(GamePlan.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfGamePlan {
    success: boolean;
    errorMessage: string | undefined;
    result: GamePlan[] | undefined;
}

export class Game implements IGame {
    public static fromJS(data: any): Game {
        data = typeof data === 'object' ? data : {};
        let result = new Game();
        result.init(data);
        return result;
    }

    public id!: number;
    public name!: string | undefined;
    public description!: string | undefined;
    public defaultPurchaseTime!: string;
    public prizes!: Prize[] | undefined;
    public sheetTypeIds!: number[] | undefined;
    public gridTypeId!: number;
    public color!: number;
    public bulkSendGameResult!: boolean;
    public drawInfo!: DrawInfo | undefined;
    public isProbabilityGame!: boolean;
    public completeGameDelay!: string;
    public gameType!: GameType;
    public maxDuration!: string;


    constructor(data?: IGame) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.name = data["name"];
            this.description = data["description"];
            this.defaultPurchaseTime = data["defaultPurchaseTime"];
            if (data["prizes"] && data["prizes"].constructor === Array) {
                this.prizes = [];
                for (let item of data["prizes"])
                    this.prizes.push(Prize.fromJS(item));
            }
            if (data["sheetTypeIds"] && data["sheetTypeIds"].constructor === Array) {
                this.sheetTypeIds = [];
                for (let item of data["sheetTypeIds"])
                    this.sheetTypeIds.push(item);
            }
            this.gridTypeId = data["gridTypeId"];
            this.color = data["color"];
            this.bulkSendGameResult = data["bulkSendGameResult"];
            this.drawInfo = data["drawInfo"] ? DrawInfo.fromJS(data["drawInfo"]) : <any>undefined;
            this.isProbabilityGame = data["isProbabilityGame"];
            this.completeGameDelay = data["completeGameDelay"];
            this.gameType = data["gameType"];
            this.maxDuration = data["maxDuration"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["description"] = this.description;
        data["defaultPurchaseTime"] = this.defaultPurchaseTime;
        if (this.prizes && this.prizes.constructor === Array) {
            data["prizes"] = [];
            for (let item of this.prizes)
                data["prizes"].push(item.toJSON());
        }
        if (this.sheetTypeIds && this.sheetTypeIds.constructor === Array) {
            data["sheetTypeIds"] = [];
            for (let item of this.sheetTypeIds)
                data["sheetTypeIds"].push(item);
        }
        data["gridTypeId"] = this.gridTypeId;
        data["color"] = this.color;
        data["bulkSendGameResult"] = this.bulkSendGameResult;
        data["drawInfo"] = this.drawInfo ? this.drawInfo.toJSON() : <any>undefined;
        data["isProbabilityGame"] = this.isProbabilityGame;
        data["completeGameDelay"] = this.completeGameDelay;
        data["gameType"] = this.gameType;
        data["maxDuration"] = this.maxDuration;
        return data;
    }
}

export interface IGame {
    id: number;
    name: string | undefined;
    description: string | undefined;
    defaultPurchaseTime: string;
    prizes: Prize[] | undefined;
    sheetTypeIds: number[] | undefined;
    gridTypeId: number;
    color: number;
    bulkSendGameResult: boolean;
    drawInfo: DrawInfo | undefined;
    isProbabilityGame: boolean;
    completeGameDelay: string;
    gameType: GameType;
    maxDuration: string;
}

export class Prize implements IPrize {
    public static fromJS(data: any): Prize {
        data = typeof data === 'object' ? data : {};
        let result = new Prize();
        result.init(data);
        return result;
    }

    public id!: number;
    public extraRule!: ExtraRule | undefined;
    public turnoverPayoutPercent!: number;
    public fixedPayoutValue!: number;
    public prizePatternGroupId!: number;
    public uniqueForEachGrid!: boolean;
    public onlyWinHighestPatternPrize!: boolean;


    constructor(data?: IPrize) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.extraRule = data["extraRule"] ? ExtraRule.fromJS(data["extraRule"]) : <any>undefined;
            this.turnoverPayoutPercent = data["turnoverPayoutPercent"];
            this.fixedPayoutValue = data["fixedPayoutValue"];
            this.prizePatternGroupId = data["prizePatternGroupId"];
            this.uniqueForEachGrid = data["uniqueForEachGrid"];
            this.onlyWinHighestPatternPrize = data["onlyWinHighestPatternPrize"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["extraRule"] = this.extraRule ? this.extraRule.toJSON() : <any>undefined;
        data["turnoverPayoutPercent"] = this.turnoverPayoutPercent;
        data["fixedPayoutValue"] = this.fixedPayoutValue;
        data["prizePatternGroupId"] = this.prizePatternGroupId;
        data["uniqueForEachGrid"] = this.uniqueForEachGrid;
        data["onlyWinHighestPatternPrize"] = this.onlyWinHighestPatternPrize;
        return data;
    }
}

export interface IPrize {
    id: number;
    extraRule: ExtraRule | undefined;
    turnoverPayoutPercent: number;
    fixedPayoutValue: number;
    prizePatternGroupId: number;
    uniqueForEachGrid: boolean;
    onlyWinHighestPatternPrize: boolean;
}

export class ExtraRule implements IExtraRule {
    public static fromJS(data: any): ExtraRule {
        data = typeof data === 'object' ? data : {};
        let result = new ExtraRule();
        result.init(data);
        return result;
    }

    public type!: ExtraRuleType;
    public value!: number;
    public potId!: number;
    public potPayoutPercent!: number;
    public minimumPotPayout!: number;
    public fixedPayoutValue!: number;
    public fixedPayoutSource!: PayoutSource;


    constructor(data?: IExtraRule) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.type = data["type"];
            this.value = data["value"];
            this.potId = data["potId"];
            this.potPayoutPercent = data["potPayoutPercent"];
            this.minimumPotPayout = data["minimumPotPayout"];
            this.fixedPayoutValue = data["fixedPayoutValue"];
            this.fixedPayoutSource = data["fixedPayoutSource"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["type"] = this.type;
        data["value"] = this.value;
        data["potId"] = this.potId;
        data["potPayoutPercent"] = this.potPayoutPercent;
        data["minimumPotPayout"] = this.minimumPotPayout;
        data["fixedPayoutValue"] = this.fixedPayoutValue;
        data["fixedPayoutSource"] = this.fixedPayoutSource;
        return data;
    }
}

export interface IExtraRule {
    type: ExtraRuleType;
    value: number;
    potId: number;
    potPayoutPercent: number;
    minimumPotPayout: number;
    fixedPayoutValue: number;
    fixedPayoutSource: PayoutSource;
}

export enum ExtraRuleType {
    None = 0,
    Column = 1,
    Row = 2,
    DividebleBy = 10,
    ExactNumber = 11,
    NotDividebleBy = 12,
    PrimeNumber = 14,
    NotPrimeNumber = 15,
    PrizeMultiplicator = 16,
    LuckyNumber = 17,
    BallMultiplier = 18,
    ExactDraw = 19,
    GoldenNumber = 20,
    WithinNumber = 22,
}

export enum PayoutSource {
    None = 0,
    Pot = 1,
}

export class DrawInfo implements IDrawInfo {
    public static fromJS(data: any): DrawInfo {
        data = typeof data === 'object' ? data : {};
        let result = new DrawInfo();
        result.init(data);
        return result;
    }

    public totalDraws!: number;
    public normalDrawDelay!: string;
    public firstDraws!: number;
    public firstDrawDelay!: string;
    public lastDraws!: number;
    public lastDrawDelay!: string;
    public extraBallDrawDelay!: string;
    public extraBallDrawProbability!: number[] | undefined;
    public showLetters!: boolean;


    constructor(data?: IDrawInfo) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.totalDraws = data["totalDraws"];
            this.normalDrawDelay = data["normalDrawDelay"];
            this.firstDraws = data["firstDraws"];
            this.firstDrawDelay = data["firstDrawDelay"];
            this.lastDraws = data["lastDraws"];
            this.lastDrawDelay = data["lastDrawDelay"];
            this.extraBallDrawDelay = data["extraBallDrawDelay"];
            if (data["extraBallDrawProbability"] && data["extraBallDrawProbability"].constructor === Array) {
                this.extraBallDrawProbability = [];
                for (let item of data["extraBallDrawProbability"])
                    this.extraBallDrawProbability.push(item);
            }
            this.showLetters = data["showLetters"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["totalDraws"] = this.totalDraws;
        data["normalDrawDelay"] = this.normalDrawDelay;
        data["firstDraws"] = this.firstDraws;
        data["firstDrawDelay"] = this.firstDrawDelay;
        data["lastDraws"] = this.lastDraws;
        data["lastDrawDelay"] = this.lastDrawDelay;
        data["extraBallDrawDelay"] = this.extraBallDrawDelay;
        if (this.extraBallDrawProbability && this.extraBallDrawProbability.constructor === Array) {
            data["extraBallDrawProbability"] = [];
            for (let item of this.extraBallDrawProbability)
                data["extraBallDrawProbability"].push(item);
        }
        data["showLetters"] = this.showLetters;
        return data;
    }
}

export interface IDrawInfo {
    totalDraws: number;
    normalDrawDelay: string;
    firstDraws: number;
    firstDrawDelay: string;
    lastDraws: number;
    lastDrawDelay: string;
    extraBallDrawDelay: string;
    extraBallDrawProbability: number[] | undefined;
    showLetters: boolean;
}

export enum GameType {
    Normal = 0,
    PropMg = 1,
}

export class ResponseOfGame implements IResponseOfGame {
    public static fromJS(data: any): ResponseOfGame {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfGame();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: Game | undefined;


    constructor(data?: IResponseOfGame) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? Game.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfGame {
    success: boolean;
    errorMessage: string | undefined;
    result: Game | undefined;
}

export class ResponseOfIEnumerableOfGame implements IResponseOfIEnumerableOfGame {
    public static fromJS(data: any): ResponseOfIEnumerableOfGame {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfGame();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: Game[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfGame) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(Game.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfGame {
    success: boolean;
    errorMessage: string | undefined;
    result: Game[] | undefined;
}

export class PrizePattern implements IPrizePattern {
    public static fromJS(data: any): PrizePattern {
        data = typeof data === 'object' ? data : {};
        let result = new PrizePattern();
        result.init(data);
        return result;
    }

    public id!: number;
    public name!: string | undefined;
    public gridTypeId!: number;
    public pattern!: number[] | undefined;
    public isExact!: boolean;


    constructor(data?: IPrizePattern) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.name = data["name"];
            this.gridTypeId = data["gridTypeId"];
            if (data["pattern"] && data["pattern"].constructor === Array) {
                this.pattern = [];
                for (let item of data["pattern"])
                    this.pattern.push(item);
            }
            this.isExact = data["isExact"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["gridTypeId"] = this.gridTypeId;
        if (this.pattern && this.pattern.constructor === Array) {
            data["pattern"] = [];
            for (let item of this.pattern)
                data["pattern"].push(item);
        }
        data["isExact"] = this.isExact;
        return data;
    }
}

export interface IPrizePattern {
    id: number;
    name: string | undefined;
    gridTypeId: number;
    pattern: number[] | undefined;
    isExact: boolean;
}

export class ResponseOfPrizePattern implements IResponseOfPrizePattern {
    public static fromJS(data: any): ResponseOfPrizePattern {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfPrizePattern();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: PrizePattern | undefined;


    constructor(data?: IResponseOfPrizePattern) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? PrizePattern.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfPrizePattern {
    success: boolean;
    errorMessage: string | undefined;
    result: PrizePattern | undefined;
}

export class ResponseOfIEnumerableOfPrizePattern implements IResponseOfIEnumerableOfPrizePattern {
    public static fromJS(data: any): ResponseOfIEnumerableOfPrizePattern {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfPrizePattern();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: PrizePattern[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfPrizePattern) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(PrizePattern.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfPrizePattern {
    success: boolean;
    errorMessage: string | undefined;
    result: PrizePattern[] | undefined;
}

export class PrizePatternGroup implements IPrizePatternGroup {
    public static fromJS(data: any): PrizePatternGroup {
        data = typeof data === 'object' ? data : {};
        let result = new PrizePatternGroup();
        result.init(data);
        return result;
    }

    public id!: number;
    public name!: string | undefined;
    public isIndependantOfOtherPrizes!: boolean;
    public requiredPrizePatternsToTrigger!: number;
    public prizePatternIds!: number[] | undefined;


    constructor(data?: IPrizePatternGroup) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.name = data["name"];
            this.isIndependantOfOtherPrizes = data["isIndependantOfOtherPrizes"];
            this.requiredPrizePatternsToTrigger = data["requiredPrizePatternsToTrigger"];
            if (data["prizePatternIds"] && data["prizePatternIds"].constructor === Array) {
                this.prizePatternIds = [];
                for (let item of data["prizePatternIds"])
                    this.prizePatternIds.push(item);
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["isIndependantOfOtherPrizes"] = this.isIndependantOfOtherPrizes;
        data["requiredPrizePatternsToTrigger"] = this.requiredPrizePatternsToTrigger;
        if (this.prizePatternIds && this.prizePatternIds.constructor === Array) {
            data["prizePatternIds"] = [];
            for (let item of this.prizePatternIds)
                data["prizePatternIds"].push(item);
        }
        return data;
    }
}

export interface IPrizePatternGroup {
    id: number;
    name: string | undefined;
    isIndependantOfOtherPrizes: boolean;
    requiredPrizePatternsToTrigger: number;
    prizePatternIds: number[] | undefined;
}

export class ResponseOfPrizePatternGroup implements IResponseOfPrizePatternGroup {
    public static fromJS(data: any): ResponseOfPrizePatternGroup {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfPrizePatternGroup();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: PrizePatternGroup | undefined;


    constructor(data?: IResponseOfPrizePatternGroup) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? PrizePatternGroup.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfPrizePatternGroup {
    success: boolean;
    errorMessage: string | undefined;
    result: PrizePatternGroup | undefined;
}

export class ResponseOfIEnumerableOfPrizePatternGroup implements IResponseOfIEnumerableOfPrizePatternGroup {
    public static fromJS(data: any): ResponseOfIEnumerableOfPrizePatternGroup {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfPrizePatternGroup();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: PrizePatternGroup[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfPrizePatternGroup) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(PrizePatternGroup.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfPrizePatternGroup {
    success: boolean;
    errorMessage: string | undefined;
    result: PrizePatternGroup[] | undefined;
}

export class ColorPrize implements IColorPrize {
    public static fromJS(data: any): ColorPrize {
        data = typeof data === 'object' ? data : {};
        let result = new ColorPrize();
        result.init(data);
        return result;
    }

    public id!: number;
    public color!: number;
    public prizeValue!: number;


    constructor(data?: IColorPrize) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.color = data["color"];
            this.prizeValue = data["prizeValue"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["color"] = this.color;
        data["prizeValue"] = this.prizeValue;
        return data;
    }
}

export interface IColorPrize {
    id: number;
    color: number;
    prizeValue: number;
}

export class ResponseOfColorPrize implements IResponseOfColorPrize {
    public static fromJS(data: any): ResponseOfColorPrize {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfColorPrize();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: ColorPrize | undefined;


    constructor(data?: IResponseOfColorPrize) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? ColorPrize.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfColorPrize {
    success: boolean;
    errorMessage: string | undefined;
    result: ColorPrize | undefined;
}

export class ResponseOfIEnumerableOfColorPrize implements IResponseOfIEnumerableOfColorPrize {
    public static fromJS(data: any): ResponseOfIEnumerableOfColorPrize {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfColorPrize();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: ColorPrize[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfColorPrize) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(ColorPrize.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfColorPrize {
    success: boolean;
    errorMessage: string | undefined;
    result: ColorPrize[] | undefined;
}

export class Pot implements IPot {
    public static fromJS(data: any): Pot {
        data = typeof data === 'object' ? data : {};
        let result = new Pot();
        result.init(data);
        return result;
    }

    public id!: number;
    public name!: string | undefined;
    public description!: string | undefined;
    public isGlobal!: boolean;
    public potValue!: number;
    public buildPercent!: number;
    public prizeCap!: number;
    public isJackpot!: boolean;


    constructor(data?: IPot) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.name = data["name"];
            this.description = data["description"];
            this.isGlobal = data["isGlobal"];
            this.potValue = data["potValue"];
            this.buildPercent = data["buildPercent"];
            this.prizeCap = data["prizeCap"];
            this.isJackpot = data["isJackpot"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["description"] = this.description;
        data["isGlobal"] = this.isGlobal;
        data["potValue"] = this.potValue;
        data["buildPercent"] = this.buildPercent;
        data["prizeCap"] = this.prizeCap;
        data["isJackpot"] = this.isJackpot;
        return data;
    }
}

export interface IPot {
    id: number;
    name: string | undefined;
    description: string | undefined;
    isGlobal: boolean;
    potValue: number;
    buildPercent: number;
    prizeCap: number;
    isJackpot: boolean;
}

export class ResponseOfPot implements IResponseOfPot {
    public static fromJS(data: any): ResponseOfPot {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfPot();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: Pot | undefined;


    constructor(data?: IResponseOfPot) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? Pot.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfPot {
    success: boolean;
    errorMessage: string | undefined;
    result: Pot | undefined;
}

export class ResponseOfIEnumerableOfPot implements IResponseOfIEnumerableOfPot {
    public static fromJS(data: any): ResponseOfIEnumerableOfPot {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfPot();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: Pot[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfPot) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(Pot.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfPot {
    success: boolean;
    errorMessage: string | undefined;
    result: Pot[] | undefined;
}

export class GameBlock implements IGameBlock {
    public static fromJS(data: any): GameBlock {
        data = typeof data === 'object' ? data : {};
        let result = new GameBlock();
        result.init(data);
        return result;
    }

    public id!: BlockId | undefined;
    public name!: string | undefined;
    public description!: string | undefined;
    public color!: number;
    public gameIds!: number[] | undefined;
    public duration!: string;


    constructor(data?: IGameBlock) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"] ? BlockId.fromJS(data["id"]) : <any>undefined;
            this.name = data["name"];
            this.description = data["description"];
            this.color = data["color"];
            if (data["gameIds"] && data["gameIds"].constructor === Array) {
                this.gameIds = [];
                for (let item of data["gameIds"])
                    this.gameIds.push(item);
            }
            this.duration = data["duration"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id ? this.id.toJSON() : <any>undefined;
        data["name"] = this.name;
        data["description"] = this.description;
        data["color"] = this.color;
        if (this.gameIds && this.gameIds.constructor === Array) {
            data["gameIds"] = [];
            for (let item of this.gameIds)
                data["gameIds"].push(item);
        }
        data["duration"] = this.duration;
        return data;
    }
}

export interface IGameBlock {
    id: BlockId | undefined;
    name: string | undefined;
    description: string | undefined;
    color: number;
    gameIds: number[] | undefined;
    duration: string;
}

export class ResponseOfGameBlock implements IResponseOfGameBlock {
    public static fromJS(data: any): ResponseOfGameBlock {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfGameBlock();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: GameBlock | undefined;


    constructor(data?: IResponseOfGameBlock) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? GameBlock.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfGameBlock {
    success: boolean;
    errorMessage: string | undefined;
    result: GameBlock | undefined;
}

export class ResponseOfIEnumerableOfGameBlock implements IResponseOfIEnumerableOfGameBlock {
    public static fromJS(data: any): ResponseOfIEnumerableOfGameBlock {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfGameBlock();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: GameBlock[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfGameBlock) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(GameBlock.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfGameBlock {
    success: boolean;
    errorMessage: string | undefined;
    result: GameBlock[] | undefined;
}

export class LinkedRoomBlock implements ILinkedRoomBlock {
    public static fromJS(data: any): LinkedRoomBlock {
        data = typeof data === 'object' ? data : {};
        let result = new LinkedRoomBlock();
        result.init(data);
        return result;
    }

    public id!: BlockId | undefined;
    public name!: string | undefined;
    public description!: string | undefined;
    public color!: number;
    public roomId!: number;
    public duration!: string;


    constructor(data?: ILinkedRoomBlock) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"] ? BlockId.fromJS(data["id"]) : <any>undefined;
            this.name = data["name"];
            this.description = data["description"];
            this.color = data["color"];
            this.roomId = data["roomId"];
            this.duration = data["duration"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id ? this.id.toJSON() : <any>undefined;
        data["name"] = this.name;
        data["description"] = this.description;
        data["color"] = this.color;
        data["roomId"] = this.roomId;
        data["duration"] = this.duration;
        return data;
    }
}

export interface ILinkedRoomBlock {
    id: BlockId | undefined;
    name: string | undefined;
    description: string | undefined;
    color: number;
    roomId: number;
    duration: string;
}

export class ResponseOfLinkedRoomBlock implements IResponseOfLinkedRoomBlock {
    public static fromJS(data: any): ResponseOfLinkedRoomBlock {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfLinkedRoomBlock();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: LinkedRoomBlock | undefined;


    constructor(data?: IResponseOfLinkedRoomBlock) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? LinkedRoomBlock.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfLinkedRoomBlock {
    success: boolean;
    errorMessage: string | undefined;
    result: LinkedRoomBlock | undefined;
}

export class ResponseOfIEnumerableOfLinkedRoomBlock implements IResponseOfIEnumerableOfLinkedRoomBlock {
    public static fromJS(data: any): ResponseOfIEnumerableOfLinkedRoomBlock {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfLinkedRoomBlock();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: LinkedRoomBlock[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfLinkedRoomBlock) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(LinkedRoomBlock.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfLinkedRoomBlock {
    success: boolean;
    errorMessage: string | undefined;
    result: LinkedRoomBlock[] | undefined;
}

export class LoopingGamesBlock implements ILoopingGamesBlock {
    public static fromJS(data: any): LoopingGamesBlock {
        data = typeof data === 'object' ? data : {};
        let result = new LoopingGamesBlock();
        result.init(data);
        return result;
    }

    public id!: BlockId | undefined;
    public name!: string | undefined;
    public description!: string | undefined;
    public color!: number;
    public gameIds!: number[] | undefined;
    public duration!: string;


    constructor(data?: ILoopingGamesBlock) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"] ? BlockId.fromJS(data["id"]) : <any>undefined;
            this.name = data["name"];
            this.description = data["description"];
            this.color = data["color"];
            if (data["gameIds"] && data["gameIds"].constructor === Array) {
                this.gameIds = [];
                for (let item of data["gameIds"])
                    this.gameIds.push(item);
            }
            this.duration = data["duration"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id ? this.id.toJSON() : <any>undefined;
        data["name"] = this.name;
        data["description"] = this.description;
        data["color"] = this.color;
        if (this.gameIds && this.gameIds.constructor === Array) {
            data["gameIds"] = [];
            for (let item of this.gameIds)
                data["gameIds"].push(item);
        }
        data["duration"] = this.duration;
        return data;
    }
}

export interface ILoopingGamesBlock {
    id: BlockId | undefined;
    name: string | undefined;
    description: string | undefined;
    color: number;
    gameIds: number[] | undefined;
    duration: string;
}

export class ResponseOfLoopingGamesBlock implements IResponseOfLoopingGamesBlock {
    public static fromJS(data: any): ResponseOfLoopingGamesBlock {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfLoopingGamesBlock();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: LoopingGamesBlock | undefined;


    constructor(data?: IResponseOfLoopingGamesBlock) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? LoopingGamesBlock.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfLoopingGamesBlock {
    success: boolean;
    errorMessage: string | undefined;
    result: LoopingGamesBlock | undefined;
}

export class ResponseOfIEnumerableOfLoopingGamesBlock implements IResponseOfIEnumerableOfLoopingGamesBlock {
    public static fromJS(data: any): ResponseOfIEnumerableOfLoopingGamesBlock {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfLoopingGamesBlock();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: LoopingGamesBlock[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfLoopingGamesBlock) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(LoopingGamesBlock.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfLoopingGamesBlock {
    success: boolean;
    errorMessage: string | undefined;
    result: LoopingGamesBlock[] | undefined;
}

export class ResponseOfWonPrizeOf implements IResponseOfWonPrizeOf {
    public static fromJS(data: any): ResponseOfWonPrizeOf {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfWonPrizeOf();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: WonPrize[] | undefined;


    constructor(data?: IResponseOfWonPrizeOf) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(WonPrize.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfWonPrizeOf {
    success: boolean;
    errorMessage: string | undefined;
    result: WonPrize[] | undefined;
}

export class WonPrize implements IWonPrize {
    public static fromJS(data: any): WonPrize {
        data = typeof data === 'object' ? data : {};
        let result = new WonPrize();
        result.init(data);
        return result;
    }

    public id!: number;
    public gridId!: number;
    public sheetTypeId!: number;
    public patterns!: WonPrizePattern[] | undefined;
    public prizeValue!: number;
    public hallId!: number;
    public playerId!: string | undefined;
    public playerName!: string | undefined;
    public prizeId!: number;
    public prizePatternGroupId!: number;
    public isOnline!: boolean;
    public shoutNumber!: number;
    public extraRuleHit!: ExtraRuleType;
    public extraPrizeValue!: number;
    public payoutTime!: Date;
    public isOverriden!: boolean;


    constructor(data?: IWonPrize) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.gridId = data["gridId"];
            this.sheetTypeId = data["sheetTypeId"];
            if (data["patterns"] && data["patterns"].constructor === Array) {
                this.patterns = [];
                for (let item of data["patterns"])
                    this.patterns.push(WonPrizePattern.fromJS(item));
            }
            this.prizeValue = data["prizeValue"];
            this.hallId = data["hallId"];
            this.playerId = data["playerId"];
            this.playerName = data["playerName"];
            this.prizeId = data["prizeId"];
            this.prizePatternGroupId = data["prizePatternGroupId"];
            this.isOnline = data["isOnline"];
            this.shoutNumber = data["shoutNumber"];
            this.extraRuleHit = data["extraRuleHit"];
            this.extraPrizeValue = data["extraPrizeValue"];
            this.payoutTime = data["payoutTime"] ? new Date(data["payoutTime"].toString()) : <any>undefined;
            this.isOverriden = data["isOverriden"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["gridId"] = this.gridId;
        data["sheetTypeId"] = this.sheetTypeId;
        if (this.patterns && this.patterns.constructor === Array) {
            data["patterns"] = [];
            for (let item of this.patterns)
                data["patterns"].push(item.toJSON());
        }
        data["prizeValue"] = this.prizeValue;
        data["hallId"] = this.hallId;
        data["playerId"] = this.playerId;
        data["playerName"] = this.playerName;
        data["prizeId"] = this.prizeId;
        data["prizePatternGroupId"] = this.prizePatternGroupId;
        data["isOnline"] = this.isOnline;
        data["shoutNumber"] = this.shoutNumber;
        data["extraRuleHit"] = this.extraRuleHit;
        data["extraPrizeValue"] = this.extraPrizeValue;
        data["payoutTime"] = this.payoutTime ? this.payoutTime.toISOString() : <any>undefined;
        data["isOverriden"] = this.isOverriden;
        return data;
    }
}

export interface IWonPrize {
    id: number;
    gridId: number;
    sheetTypeId: number;
    patterns: WonPrizePattern[] | undefined;
    prizeValue: number;
    hallId: number;
    playerId: string | undefined;
    playerName: string | undefined;
    prizeId: number;
    prizePatternGroupId: number;
    isOnline: boolean;
    shoutNumber: number;
    extraRuleHit: ExtraRuleType;
    extraPrizeValue: number;
    payoutTime: Date;
    isOverriden: boolean;
}

export class WonPrizePattern implements IWonPrizePattern {
    public static fromJS(data: any): WonPrizePattern {
        data = typeof data === 'object' ? data : {};
        let result = new WonPrizePattern();
        result.init(data);
        return result;
    }

    public wonPrizeId!: number;
    public prizePatternId!: number;


    constructor(data?: IWonPrizePattern) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.wonPrizeId = data["wonPrizeId"];
            this.prizePatternId = data["prizePatternId"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["wonPrizeId"] = this.wonPrizeId;
        data["prizePatternId"] = this.prizePatternId;
        return data;
    }
}

export interface IWonPrizePattern {
    wonPrizeId: number;
    prizePatternId: number;
}

export class ResponseOfAppSetting implements IResponseOfAppSetting {
    public static fromJS(data: any): ResponseOfAppSetting {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfAppSetting();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: AppSetting | undefined;


    constructor(data?: IResponseOfAppSetting) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? AppSetting.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfAppSetting {
    success: boolean;
    errorMessage: string | undefined;
    result: AppSetting | undefined;
}

export class AppSetting implements IAppSetting {
    public static fromJS(data: any): AppSetting {
        data = typeof data === 'object' ? data : {};
        let result = new AppSetting();
        result.init(data);
        return result;
    }

    public name!: string | undefined;
    public value!: string | undefined;
    public type!: AppSettingType;


    constructor(data?: IAppSetting) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.name = data["name"];
            this.value = data["value"];
            this.type = data["type"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        data["value"] = this.value;
        data["type"] = this.type;
        return data;
    }
}

export interface IAppSetting {
    name: string | undefined;
    value: string | undefined;
    type: AppSettingType;
}

export enum AppSettingType {
    TimeSpan = 1,
    Decimal = 2,
}

export class ResponseOfIEnumerableOfAppSetting implements IResponseOfIEnumerableOfAppSetting {
    public static fromJS(data: any): ResponseOfIEnumerableOfAppSetting {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfAppSetting();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: AppSetting[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfAppSetting) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(AppSetting.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfAppSetting {
    success: boolean;
    errorMessage: string | undefined;
    result: AppSetting[] | undefined;
}

export class BingoBoosterRoom implements IBingoBoosterRoom {
    public static fromJS(data: any): BingoBoosterRoom {
        data = typeof data === 'object' ? data : {};
        let result = new BingoBoosterRoom();
        result.init(data);
        return result;
    }

    public id!: number;
    public enabled!: boolean;
    public host!: string | undefined;
    public port!: number;
    public password!: string | undefined;
    public certificateFile!: string | undefined;
    public roomId!: number;
    public name!: string | undefined;
    public bingoBoosterHallIds!: number[] | undefined;


    constructor(data?: IBingoBoosterRoom) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.enabled = data["enabled"];
            this.host = data["host"];
            this.port = data["port"];
            this.password = data["password"];
            this.certificateFile = data["certificateFile"];
            this.roomId = data["roomId"];
            this.name = data["name"];
            if (data["bingoBoosterHallIds"] && data["bingoBoosterHallIds"].constructor === Array) {
                this.bingoBoosterHallIds = [];
                for (let item of data["bingoBoosterHallIds"])
                    this.bingoBoosterHallIds.push(item);
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["enabled"] = this.enabled;
        data["host"] = this.host;
        data["port"] = this.port;
        data["password"] = this.password;
        data["certificateFile"] = this.certificateFile;
        data["roomId"] = this.roomId;
        data["name"] = this.name;
        if (this.bingoBoosterHallIds && this.bingoBoosterHallIds.constructor === Array) {
            data["bingoBoosterHallIds"] = [];
            for (let item of this.bingoBoosterHallIds)
                data["bingoBoosterHallIds"].push(item);
        }
        return data;
    }
}

export interface IBingoBoosterRoom {
    id: number;
    enabled: boolean;
    host: string | undefined;
    port: number;
    password: string | undefined;
    certificateFile: string | undefined;
    roomId: number;
    name: string | undefined;
    bingoBoosterHallIds: number[] | undefined;
}

export class ResponseOfBingoBoosterRoom implements IResponseOfBingoBoosterRoom {
    public static fromJS(data: any): ResponseOfBingoBoosterRoom {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfBingoBoosterRoom();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: BingoBoosterRoom | undefined;


    constructor(data?: IResponseOfBingoBoosterRoom) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? BingoBoosterRoom.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfBingoBoosterRoom {
    success: boolean;
    errorMessage: string | undefined;
    result: BingoBoosterRoom | undefined;
}

export class ResponseOfIEnumerableOfBingoBoosterRoom implements IResponseOfIEnumerableOfBingoBoosterRoom {
    public static fromJS(data: any): ResponseOfIEnumerableOfBingoBoosterRoom {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfBingoBoosterRoom();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: BingoBoosterRoom[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfBingoBoosterRoom) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(BingoBoosterRoom.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfBingoBoosterRoom {
    success: boolean;
    errorMessage: string | undefined;
    result: BingoBoosterRoom[] | undefined;
}

export class BingoBoosterHall implements IBingoBoosterHall {
    public static fromJS(data: any): BingoBoosterHall {
        data = typeof data === 'object' ? data : {};
        let result = new BingoBoosterHall();
        result.init(data);
        return result;
    }

    public id!: number;
    public name!: string | undefined;
    public egsHallId!: number;
    public externalBBHallId!: string | undefined;
    public bingoBoosterRoomId!: number | undefined;


    constructor(data?: IBingoBoosterHall) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.name = data["name"];
            this.egsHallId = data["egsHallId"];
            this.externalBBHallId = data["externalBBHallId"];
            this.bingoBoosterRoomId = data["bingoBoosterRoomId"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["name"] = this.name;
        data["egsHallId"] = this.egsHallId;
        data["externalBBHallId"] = this.externalBBHallId;
        data["bingoBoosterRoomId"] = this.bingoBoosterRoomId;
        return data;
    }
}

export interface IBingoBoosterHall {
    id: number;
    name: string | undefined;
    egsHallId: number;
    externalBBHallId: string | undefined;
    bingoBoosterRoomId: number | undefined;
}

export class ResponseOfBingoBoosterHall implements IResponseOfBingoBoosterHall {
    public static fromJS(data: any): ResponseOfBingoBoosterHall {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfBingoBoosterHall();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: BingoBoosterHall | undefined;


    constructor(data?: IResponseOfBingoBoosterHall) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? BingoBoosterHall.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfBingoBoosterHall {
    success: boolean;
    errorMessage: string | undefined;
    result: BingoBoosterHall | undefined;
}

export class ResponseOfIEnumerableOfBingoBoosterHall implements IResponseOfIEnumerableOfBingoBoosterHall {
    public static fromJS(data: any): ResponseOfIEnumerableOfBingoBoosterHall {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfBingoBoosterHall();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: BingoBoosterHall[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfBingoBoosterHall) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(BingoBoosterHall.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfBingoBoosterHall {
    success: boolean;
    errorMessage: string | undefined;
    result: BingoBoosterHall[] | undefined;
}

export class AirDicePlatformRoom implements IAirDicePlatformRoom {
    public static fromJS(data: any): AirDicePlatformRoom {
        data = typeof data === 'object' ? data : {};
        let result = new AirDicePlatformRoom();
        result.init(data);
        return result;
    }

    public id!: number;
    public customer!: string | undefined;
    public hallId!: number;
    public gameType!: GameType;
    public roomId!: number;


    constructor(data?: IAirDicePlatformRoom) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.customer = data["customer"];
            this.hallId = data["hallId"];
            this.gameType = data["gameType"];
            this.roomId = data["roomId"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["customer"] = this.customer;
        data["hallId"] = this.hallId;
        data["gameType"] = this.gameType;
        data["roomId"] = this.roomId;
        return data;
    }
}

export interface IAirDicePlatformRoom {
    id: number;
    customer: string | undefined;
    hallId: number;
    gameType: GameType;
    roomId: number;
}

export class ResponseOfAirDicePlatformRoom implements IResponseOfAirDicePlatformRoom {
    public static fromJS(data: any): ResponseOfAirDicePlatformRoom {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfAirDicePlatformRoom();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: AirDicePlatformRoom | undefined;


    constructor(data?: IResponseOfAirDicePlatformRoom) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? AirDicePlatformRoom.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfAirDicePlatformRoom {
    success: boolean;
    errorMessage: string | undefined;
    result: AirDicePlatformRoom | undefined;
}

export class ResponseOfIEnumerableOfAirDicePlatformRoom implements IResponseOfIEnumerableOfAirDicePlatformRoom {
    public static fromJS(data: any): ResponseOfIEnumerableOfAirDicePlatformRoom {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfAirDicePlatformRoom();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: AirDicePlatformRoom[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfAirDicePlatformRoom) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(AirDicePlatformRoom.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfAirDicePlatformRoom {
    success: boolean;
    errorMessage: string | undefined;
    result: AirDicePlatformRoom[] | undefined;
}

export class ResponseOfIEnumerableOfGrid implements IResponseOfIEnumerableOfGrid {
    public static fromJS(data: any): ResponseOfIEnumerableOfGrid {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfGrid();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: Grid[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfGrid) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(Grid.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfGrid {
    success: boolean;
    errorMessage: string | undefined;
    result: Grid[] | undefined;
}

export class Grid implements IGrid {
    public static fromJS(data: any): Grid {
        data = typeof data === 'object' ? data : {};
        let result = new Grid();
        result.init(data);
        return result;
    }

    public id!: number;
    public numbers!: string | undefined;
    public numbersAsText!: string | undefined;


    constructor(data?: IGrid) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.numbers = data["numbers"];
            this.numbersAsText = data["numbersAsText"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["numbers"] = this.numbers;
        data["numbersAsText"] = this.numbersAsText;
        return data;
    }
}

export interface IGrid {
    id: number;
    numbers: string | undefined;
    numbersAsText: string | undefined;
}

export class ResponseOfRoomStatus implements IResponseOfRoomStatus {
    public static fromJS(data: any): ResponseOfRoomStatus {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfRoomStatus();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: RoomStatus | undefined;


    constructor(data?: IResponseOfRoomStatus) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? RoomStatus.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfRoomStatus {
    success: boolean;
    errorMessage: string | undefined;
    result: RoomStatus | undefined;
}

export class RoomStatus implements IRoomStatus {
    public static fromJS(data: any): RoomStatus {
        data = typeof data === 'object' ? data : {};
        let result = new RoomStatus();
        result.init(data);
        return result;
    }

    public id!: number;
    public state!: RoomState;
    public nextGameId!: number;
    public nextGameStartTime!: string;
    public currentGameId!: number;
    public currentGameState!: GameState;
    public gamePlanStartTime!: string;
    public gamePlanEndTime!: string;
    public currentIndexInGameplan!: number;
    public gamesInGameplan!: number;


    constructor(data?: IRoomStatus) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.state = data["state"];
            this.nextGameId = data["nextGameId"];
            this.nextGameStartTime = data["nextGameStartTime"];
            this.currentGameId = data["currentGameId"];
            this.currentGameState = data["currentGameState"];
            this.gamePlanStartTime = data["gamePlanStartTime"];
            this.gamePlanEndTime = data["gamePlanEndTime"];
            this.currentIndexInGameplan = data["currentIndexInGameplan"];
            this.gamesInGameplan = data["gamesInGameplan"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["state"] = this.state;
        data["nextGameId"] = this.nextGameId;
        data["nextGameStartTime"] = this.nextGameStartTime;
        data["currentGameId"] = this.currentGameId;
        data["currentGameState"] = this.currentGameState;
        data["gamePlanStartTime"] = this.gamePlanStartTime;
        data["gamePlanEndTime"] = this.gamePlanEndTime;
        data["currentIndexInGameplan"] = this.currentIndexInGameplan;
        data["gamesInGameplan"] = this.gamesInGameplan;
        return data;
    }
}

export interface IRoomStatus {
    id: number;
    state: RoomState;
    nextGameId: number;
    nextGameStartTime: string;
    currentGameId: number;
    currentGameState: GameState;
    gamePlanStartTime: string;
    gamePlanEndTime: string;
    currentIndexInGameplan: number;
    gamesInGameplan: number;
}

export enum RoomState {
    Open = 0,
    Closed = 1,
    Closing = 2,
}

export enum GameState {
    None = 0,
    Buyin = 1,
    BuyinClosed = 2,
    InProgress = 3,
    Bingo = 4,
    ReachedEnd = 5,
    Complete = 6,
    CompletedWithError = 7,
}

export class ResponseOfAccount implements IResponseOfAccount {
    public static fromJS(data: any): ResponseOfAccount {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfAccount();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: Account | undefined;


    constructor(data?: IResponseOfAccount) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            this.result = data["result"] ? Account.fromJS(data["result"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        data["result"] = this.result ? this.result.toJSON() : <any>undefined;
        return data;
    }
}

export interface IResponseOfAccount {
    success: boolean;
    errorMessage: string | undefined;
    result: Account | undefined;
}

export class Account implements IAccount {
    public static fromJS(data: any): Account {
        data = typeof data === 'object' ? data : {};
        let result = new Account();
        result.init(data);
        return result;
    }

    public id!: number;
    public username!: string | undefined;
    public role!: string | undefined;
    public email!: string | undefined;
    public password!: string | undefined;
    public token!: string | undefined;


    constructor(data?: IAccount) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.username = data["username"];
            this.role = data["role"];
            this.email = data["email"];
            this.password = data["password"];
            this.token = data["token"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["username"] = this.username;
        data["role"] = this.role;
        data["email"] = this.email;
        data["password"] = this.password;
        data["token"] = this.token;
        return data;
    }
}

export interface IAccount {
    id: number;
    username: string | undefined;
    role: string | undefined;
    email: string | undefined;
    password: string | undefined;
    token: string | undefined;
}

export class ResponseOfIEnumerableOfAccount implements IResponseOfIEnumerableOfAccount {
    public static fromJS(data: any): ResponseOfIEnumerableOfAccount {
        data = typeof data === 'object' ? data : {};
        let result = new ResponseOfIEnumerableOfAccount();
        result.init(data);
        return result;
    }

    public success!: boolean;
    public errorMessage!: string | undefined;
    public result!: Account[] | undefined;


    constructor(data?: IResponseOfIEnumerableOfAccount) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.success = data["success"];
            this.errorMessage = data["errorMessage"];
            if (data["result"] && data["result"].constructor === Array) {
                this.result = [];
                for (let item of data["result"])
                    this.result.push(Account.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["success"] = this.success;
        data["errorMessage"] = this.errorMessage;
        if (this.result && this.result.constructor === Array) {
            data["result"] = [];
            for (let item of this.result)
                data["result"].push(item.toJSON());
        }
        return data;
    }
}

export interface IResponseOfIEnumerableOfAccount {
    success: boolean;
    errorMessage: string | undefined;
    result: Account[] | undefined;
}

export class GameStartTime implements IGameStartTime {
    public static fromJS(data: any): GameStartTime {
        data = typeof data === 'object' ? data : {};
        let result = new GameStartTime();
        result.init(data);
        return result;
    }

    public gameId!: number;
    public indexInGamePlan!: number;
    public startTime!: string;


    constructor(data?: IGameStartTime) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.gameId = data["gameId"];
            this.indexInGamePlan = data["indexInGamePlan"];
            this.startTime = data["startTime"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["gameId"] = this.gameId;
        data["indexInGamePlan"] = this.indexInGamePlan;
        data["startTime"] = this.startTime;
        return data;
    }
}

export interface IGameStartTime {
    gameId: number;
    indexInGamePlan: number;
    startTime: string;
}

export class RunningGame implements IRunningGame {
    public static fromJS(data: any): RunningGame {
        data = typeof data === 'object' ? data : {};
        let result = new RunningGame();
        result.init(data);
        return result;
    }

    public runningGameId!: number;
    public gameId!: number;
    public state!: GameState2;
    public shoutIndex!: number;
    public shouts!: number[] | undefined;
    public onlineBongs!: number;
    public hallBongs!: number;
    public created!: Date;
    public estimatedBuyInCloseTime!: Date;
    public estimatedRoundEndTime!: Date;
    public payouts!: Win[] | undefined;
    public soldGrids!: SoldGrid[] | undefined;


    constructor(data?: IRunningGame) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.runningGameId = data["runningGameId"];
            this.gameId = data["gameId"];
            this.state = data["state"];
            this.shoutIndex = data["shoutIndex"];
            if (data["shouts"] && data["shouts"].constructor === Array) {
                this.shouts = [];
                for (let item of data["shouts"])
                    this.shouts.push(item);
            }
            this.onlineBongs = data["onlineBongs"];
            this.hallBongs = data["hallBongs"];
            this.created = data["created"] ? new Date(data["created"].toString()) : <any>undefined;
            this.estimatedBuyInCloseTime = data["estimatedBuyInCloseTime"] ? new Date(data["estimatedBuyInCloseTime"].toString()) : <any>undefined;
            this.estimatedRoundEndTime = data["estimatedRoundEndTime"] ? new Date(data["estimatedRoundEndTime"].toString()) : <any>undefined;
            if (data["payouts"] && data["payouts"].constructor === Array) {
                this.payouts = [];
                for (let item of data["payouts"])
                    this.payouts.push(Win.fromJS(item));
            }
            if (data["soldGrids"] && data["soldGrids"].constructor === Array) {
                this.soldGrids = [];
                for (let item of data["soldGrids"])
                    this.soldGrids.push(SoldGrid.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["runningGameId"] = this.runningGameId;
        data["gameId"] = this.gameId;
        data["state"] = this.state;
        data["shoutIndex"] = this.shoutIndex;
        if (this.shouts && this.shouts.constructor === Array) {
            data["shouts"] = [];
            for (let item of this.shouts)
                data["shouts"].push(item);
        }
        data["onlineBongs"] = this.onlineBongs;
        data["hallBongs"] = this.hallBongs;
        data["created"] = this.created ? this.created.toISOString() : <any>undefined;
        data["estimatedBuyInCloseTime"] = this.estimatedBuyInCloseTime ? this.estimatedBuyInCloseTime.toISOString() : <any>undefined;
        data["estimatedRoundEndTime"] = this.estimatedRoundEndTime ? this.estimatedRoundEndTime.toISOString() : <any>undefined;
        if (this.payouts && this.payouts.constructor === Array) {
            data["payouts"] = [];
            for (let item of this.payouts)
                data["payouts"].push(item.toJSON());
        }
        if (this.soldGrids && this.soldGrids.constructor === Array) {
            data["soldGrids"] = [];
            for (let item of this.soldGrids)
                data["soldGrids"].push(item.toJSON());
        }
        return data;
    }
}

export interface IRunningGame {
    runningGameId: number;
    gameId: number;
    state: GameState2;
    shoutIndex: number;
    shouts: number[] | undefined;
    onlineBongs: number;
    hallBongs: number;
    created: Date;
    estimatedBuyInCloseTime: Date;
    estimatedRoundEndTime: Date;
    payouts: Win[] | undefined;
    soldGrids: SoldGrid[] | undefined;
}

export enum GameState2 {
    None = "None",
    Buyin = "Buyin",
    BuyinClosed = "BuyinClosed",
    InProgress = "InProgress",
    Bingo = "Bingo",
    ReachedEnd = "ReachedEnd",
    Complete = "Complete",
    CompletedWithError = "CompletedWithError",
}

export class Win implements IWin {
    public static fromJS(data: any): Win {
        data = typeof data === 'object' ? data : {};
        let result = new Win();
        result.init(data);
        return result;
    }

    public id!: number;
    public gridId!: number;
    public prizeValue!: number;
    public hallId!: string | undefined;
    public playerId!: string | undefined;
    public prizeId!: number;
    public offlineNickname!: string | undefined;
    public patternIds!: number[] | undefined;


    constructor(data?: IWin) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.id = data["id"];
            this.gridId = data["gridId"];
            this.prizeValue = data["prizeValue"];
            this.hallId = data["hallId"];
            this.playerId = data["playerId"];
            this.prizeId = data["prizeId"];
            this.offlineNickname = data["offlineNickname"];
            if (data["patternIds"] && data["patternIds"].constructor === Array) {
                this.patternIds = [];
                for (let item of data["patternIds"])
                    this.patternIds.push(item);
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["gridId"] = this.gridId;
        data["prizeValue"] = this.prizeValue;
        data["hallId"] = this.hallId;
        data["playerId"] = this.playerId;
        data["prizeId"] = this.prizeId;
        data["offlineNickname"] = this.offlineNickname;
        if (this.patternIds && this.patternIds.constructor === Array) {
            data["patternIds"] = [];
            for (let item of this.patternIds)
                data["patternIds"].push(item);
        }
        return data;
    }
}

export interface IWin {
    id: number;
    gridId: number;
    prizeValue: number;
    hallId: string | undefined;
    playerId: string | undefined;
    prizeId: number;
    offlineNickname: string | undefined;
    patternIds: number[] | undefined;
}

export class SoldGrid implements ISoldGrid {
    public static fromJS(data: any): SoldGrid {
        data = typeof data === 'object' ? data : {};
        let result = new SoldGrid();
        result.init(data);
        return result;
    }

    public ticketManagerId!: number;
    public grid!: Grid | undefined;
    public sheetTypeId!: number;
    public playerId!: string | undefined;
    public playerName!: string | undefined;
    public hallId!: number;
    public isOnline!: boolean;


    constructor(data?: ISoldGrid) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.ticketManagerId = data["ticketManagerId"];
            this.grid = data["grid"] ? Grid.fromJS(data["grid"]) : <any>undefined;
            this.sheetTypeId = data["sheetTypeId"];
            this.playerId = data["playerId"];
            this.playerName = data["playerName"];
            this.hallId = data["hallId"];
            this.isOnline = data["isOnline"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["ticketManagerId"] = this.ticketManagerId;
        data["grid"] = this.grid ? this.grid.toJSON() : <any>undefined;
        data["sheetTypeId"] = this.sheetTypeId;
        data["playerId"] = this.playerId;
        data["playerName"] = this.playerName;
        data["hallId"] = this.hallId;
        data["isOnline"] = this.isOnline;
        return data;
    }
}

export interface ISoldGrid {
    ticketManagerId: number;
    grid: Grid | undefined;
    sheetTypeId: number;
    playerId: string | undefined;
    playerName: string | undefined;
    hallId: number;
    isOnline: boolean;
}

export class HallInformation implements IHallInformation {
    public static fromJS(data: any): HallInformation {
        data = typeof data === 'object' ? data : {};
        let result = new HallInformation();
        result.init(data);
        return result;
    }

    public ip!: string | undefined;
    public ipDetails!: IpAddressDetails | undefined;
    public hallId!: number;
    public name!: string | undefined;
    public version!: string | undefined;
    public exceptions!: string[] | undefined;
    public connectionHistory!: HallConnectionInfo[] | undefined;


    constructor(data?: IHallInformation) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.ip = data["ip"];
            this.ipDetails = data["ipDetails"] ? IpAddressDetails.fromJS(data["ipDetails"]) : <any>undefined;
            this.hallId = data["hallId"];
            this.name = data["name"];
            this.version = data["version"];
            if (data["exceptions"] && data["exceptions"].constructor === Array) {
                this.exceptions = [];
                for (let item of data["exceptions"])
                    this.exceptions.push(item);
            }
            if (data["connectionHistory"] && data["connectionHistory"].constructor === Array) {
                this.connectionHistory = [];
                for (let item of data["connectionHistory"])
                    this.connectionHistory.push(HallConnectionInfo.fromJS(item));
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["ip"] = this.ip;
        data["ipDetails"] = this.ipDetails ? this.ipDetails.toJSON() : <any>undefined;
        data["hallId"] = this.hallId;
        data["name"] = this.name;
        data["version"] = this.version;
        if (this.exceptions && this.exceptions.constructor === Array) {
            data["exceptions"] = [];
            for (let item of this.exceptions)
                data["exceptions"].push(item);
        }
        if (this.connectionHistory && this.connectionHistory.constructor === Array) {
            data["connectionHistory"] = [];
            for (let item of this.connectionHistory)
                data["connectionHistory"].push(item.toJSON());
        }
        return data;
    }
}

export interface IHallInformation {
    ip: string | undefined;
    ipDetails: IpAddressDetails | undefined;
    hallId: number;
    name: string | undefined;
    version: string | undefined;
    exceptions: string[] | undefined;
    connectionHistory: HallConnectionInfo[] | undefined;
}

export class IpAddressDetails implements IIpAddressDetails {
    public static fromJS(data: any): IpAddressDetails {
        data = typeof data === 'object' ? data : {};
        let result = new IpAddressDetails();
        result.init(data);
        return result;
    }

    public ip!: string | undefined;
    public type!: string | undefined;
    public continentCode!: string | undefined;
    public continentName!: string | undefined;
    public countryCode!: string | undefined;
    public countryName!: string | undefined;
    public regionCode!: string | undefined;
    public regionName!: string | undefined;
    public city!: string | undefined;
    public zip!: string | undefined;
    public latitude!: number;
    public longitude!: number;
    public location!: Location | undefined;


    constructor(data?: IIpAddressDetails) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.ip = data["ip"];
            this.type = data["type"];
            this.continentCode = data["continentCode"];
            this.continentName = data["continentName"];
            this.countryCode = data["countryCode"];
            this.countryName = data["countryName"];
            this.regionCode = data["regionCode"];
            this.regionName = data["regionName"];
            this.city = data["city"];
            this.zip = data["zip"];
            this.latitude = data["latitude"];
            this.longitude = data["longitude"];
            this.location = data["location"] ? Location.fromJS(data["location"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["ip"] = this.ip;
        data["type"] = this.type;
        data["continentCode"] = this.continentCode;
        data["continentName"] = this.continentName;
        data["countryCode"] = this.countryCode;
        data["countryName"] = this.countryName;
        data["regionCode"] = this.regionCode;
        data["regionName"] = this.regionName;
        data["city"] = this.city;
        data["zip"] = this.zip;
        data["latitude"] = this.latitude;
        data["longitude"] = this.longitude;
        data["location"] = this.location ? this.location.toJSON() : <any>undefined;
        return data;
    }
}

export interface IIpAddressDetails {
    ip: string | undefined;
    type: string | undefined;
    continentCode: string | undefined;
    continentName: string | undefined;
    countryCode: string | undefined;
    countryName: string | undefined;
    regionCode: string | undefined;
    regionName: string | undefined;
    city: string | undefined;
    zip: string | undefined;
    latitude: number;
    longitude: number;
    location: Location | undefined;
}

export class Location implements ILocation {
    public static fromJS(data: any): Location {
        data = typeof data === 'object' ? data : {};
        let result = new Location();
        result.init(data);
        return result;
    }

    public geonameId!: number;
    public capital!: string | undefined;
    public languages!: Language[] | undefined;
    public countryFlag!: string | undefined;
    public countryFlagEmoji!: string | undefined;
    public countryFlagEmojiUnicode!: string | undefined;
    public callingCode!: string | undefined;
    public isEu!: boolean;


    constructor(data?: ILocation) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.geonameId = data["geonameId"];
            this.capital = data["capital"];
            if (data["languages"] && data["languages"].constructor === Array) {
                this.languages = [];
                for (let item of data["languages"])
                    this.languages.push(Language.fromJS(item));
            }
            this.countryFlag = data["countryFlag"];
            this.countryFlagEmoji = data["countryFlagEmoji"];
            this.countryFlagEmojiUnicode = data["countryFlagEmojiUnicode"];
            this.callingCode = data["callingCode"];
            this.isEu = data["isEu"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["geonameId"] = this.geonameId;
        data["capital"] = this.capital;
        if (this.languages && this.languages.constructor === Array) {
            data["languages"] = [];
            for (let item of this.languages)
                data["languages"].push(item.toJSON());
        }
        data["countryFlag"] = this.countryFlag;
        data["countryFlagEmoji"] = this.countryFlagEmoji;
        data["countryFlagEmojiUnicode"] = this.countryFlagEmojiUnicode;
        data["callingCode"] = this.callingCode;
        data["isEu"] = this.isEu;
        return data;
    }
}

export interface ILocation {
    geonameId: number;
    capital: string | undefined;
    languages: Language[] | undefined;
    countryFlag: string | undefined;
    countryFlagEmoji: string | undefined;
    countryFlagEmojiUnicode: string | undefined;
    callingCode: string | undefined;
    isEu: boolean;
}

export class Language implements ILanguage {
    public static fromJS(data: any): Language {
        data = typeof data === 'object' ? data : {};
        let result = new Language();
        result.init(data);
        return result;
    }

    public code!: string | undefined;
    public name!: string | undefined;
    public native!: string | undefined;


    constructor(data?: ILanguage) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.code = data["code"];
            this.name = data["name"];
            this.native = data["native"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["code"] = this.code;
        data["name"] = this.name;
        data["native"] = this.native;
        return data;
    }
}

export interface ILanguage {
    code: string | undefined;
    name: string | undefined;
    native: string | undefined;
}

export class HallConnectionInfo implements IHallConnectionInfo {
    public static fromJS(data: any): HallConnectionInfo {
        data = typeof data === 'object' ? data : {};
        let result = new HallConnectionInfo();
        result.init(data);
        return result;
    }

    public connectionId!: string | undefined;
    public connectedTime!: Date;
    public disconnectTime!: Date;
    public connected!: boolean;
    public subscribingToRooms!: number[] | undefined;


    constructor(data?: IHallConnectionInfo) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.connectionId = data["connectionId"];
            this.connectedTime = data["connectedTime"] ? new Date(data["connectedTime"].toString()) : <any>undefined;
            this.disconnectTime = data["disconnectTime"] ? new Date(data["disconnectTime"].toString()) : <any>undefined;
            this.connected = data["connected"];
            if (data["subscribingToRooms"] && data["subscribingToRooms"].constructor === Array) {
                this.subscribingToRooms = [];
                for (let item of data["subscribingToRooms"])
                    this.subscribingToRooms.push(item);
            }
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["connectionId"] = this.connectionId;
        data["connectedTime"] = this.connectedTime ? this.connectedTime.toISOString() : <any>undefined;
        data["disconnectTime"] = this.disconnectTime ? this.disconnectTime.toISOString() : <any>undefined;
        data["connected"] = this.connected;
        if (this.subscribingToRooms && this.subscribingToRooms.constructor === Array) {
            data["subscribingToRooms"] = [];
            for (let item of this.subscribingToRooms)
                data["subscribingToRooms"].push(item);
        }
        return data;
    }
}

export interface IHallConnectionInfo {
    connectionId: string | undefined;
    connectedTime: Date;
    disconnectTime: Date;
    connected: boolean;
    subscribingToRooms: number[] | undefined;
}

export class CreateTestData implements ICreateTestData {
    public static fromJS(data: any): CreateTestData {
        data = typeof data === 'object' ? data : {};
        let result = new CreateTestData();
        result.init(data);
        return result;
    }

    public roomName!: string | undefined;
    public childRoomName!: string | undefined;
    public childRoomsInLink!: number;
    public createProbabilityGames!: boolean;


    constructor(data?: ICreateTestData) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.roomName = data["roomName"];
            this.childRoomName = data["childRoomName"];
            this.childRoomsInLink = data["childRoomsInLink"];
            this.createProbabilityGames = data["createProbabilityGames"];
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["roomName"] = this.roomName;
        data["childRoomName"] = this.childRoomName;
        data["childRoomsInLink"] = this.childRoomsInLink;
        data["createProbabilityGames"] = this.createProbabilityGames;
        return data;
    }
}

export interface ICreateTestData {
    roomName: string | undefined;
    childRoomName: string | undefined;
    childRoomsInLink: number;
    createProbabilityGames: boolean;
}

export class GameStateUpdate implements IGameStateUpdate {
    public static fromJS(data: any): GameStateUpdate {
        data = typeof data === 'object' ? data : {};
        let result = new GameStateUpdate();
        result.init(data);
        return result;
    }

    public blockIndex!: number;
    public gameIndexInBlock!: number;
    public pots!: Pot[] | undefined;
    public runningGame!: RunningGame | undefined;


    constructor(data?: IGameStateUpdate) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    public init(data?: any) {
        if (data) {
            this.blockIndex = data["blockIndex"];
            this.gameIndexInBlock = data["gameIndexInBlock"];
            if (data["pots"] && data["pots"].constructor === Array) {
                this.pots = [];
                for (let item of data["pots"])
                    this.pots.push(Pot.fromJS(item));
            }
            this.runningGame = data["runningGame"] ? RunningGame.fromJS(data["runningGame"]) : <any>undefined;
        }
    }

    public toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["blockIndex"] = this.blockIndex;
        data["gameIndexInBlock"] = this.gameIndexInBlock;
        if (this.pots && this.pots.constructor === Array) {
            data["pots"] = [];
            for (let item of this.pots)
                data["pots"].push(item.toJSON());
        }
        data["runningGame"] = this.runningGame ? this.runningGame.toJSON() : <any>undefined;
        return data;
    }
}

export interface IGameStateUpdate {
    blockIndex: number;
    gameIndexInBlock: number;
    pots: Pot[] | undefined;
    runningGame: RunningGame | undefined;
}