import { BaseWebSocketService } from './base-websocket.service';
import { Logger } from '@/utilities';
import { LogLevel } from '@/models';
import { AdminHub, IGame, ResponseOfIEnumerableOfGame, ResponseOfGame, IResponseOfGame, Game, ResponseOfInt32, Response } from './api';


/**
 * @description Service for handling Games on the server.
 * @extends BaseWebSocketService
 */
export class GameService extends BaseWebSocketService {

	constructor() {
		super();
	}

	public getAll(): Promise<Array<IGame>> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfIEnumerableOfGame> => {
				return hub.getGames();
			})
			.then((result: ResponseOfIEnumerableOfGame): Array<IGame> => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "GameService.getAll() - No Games found!");
					return new Array<IGame>();
				}
				Logger.log(LogLevel.Debug, "GameService.getAll() - Server Response: " + result.result.length, result.result);
				return result.result;
			});
	}

	public getItem(id: number): Promise<IGame | null> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfGame> => {
				return hub.getGame(id);
			}).then((result: IResponseOfGame) => {
				if(!result.result) {
					Logger.log(LogLevel.Debug, "GameService.getItem(" + id + ") - No Game with Id = " + id + " found!");
					return null;
				}
				Logger.log(LogLevel.Debug, "GameService.getItem(" + id + ") - Server Response: ", result.result);
				return result.result;
			});
	}

	public createItem(item: Game): Promise<number> {
		Logger.log(LogLevel.Debug, "GameService.createItem(item) - Sending Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<ResponseOfInt32> => {
				return hub.createGame(item);
			}).then((result: ResponseOfInt32) => {
				if(!result.result) {
					Logger.log(LogLevel.Error, "GameService.createItem(item) - Failed to create item!", result);
				}
				Logger.log(LogLevel.Debug, "GameService.createItem(item) - Server Response: " + result.result, result);
				return result.result;
			});
	}

	public updateItem(item: Game): Promise<void> {
		Logger.log(LogLevel.Debug, "GameService.updateItem(item) - Item: ", item);
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.updateGame(item);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "GameService.updateItem(item) - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "GameService.updateItem(item) - Server Response: success", result);
				return;
			});
	}

	public deleteItem(id: number): Promise<void> {
		return this.AdminHub
			.then((hub: AdminHub): Promise<Response> => {
				return hub.deleteGame(id);
			}).then((result: Response) => {
				if(!result.success) {
					Logger.log(LogLevel.Error, "GameService.deleteItem(" + id + ") - Failed to update item!", result);
				}
				Logger.log(LogLevel.Debug, "GameService.deleteItem(" + id + ") - Server Response: success", result);
				return;
			});
	}
}
