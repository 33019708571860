

















































import { Vue, Component, Prop, Inject, Provide } from 'vue-property-decorator';
import {Link } from './link';
import CollapseTransition from '../Transitions/collapse-transition';
//import CollapseTransition from 'element-ui/lib/Transitions/collapse-transition';



@Component({
	name: "sidebar-item",
	components: {
		[CollapseTransition.name]: CollapseTransition
	}
})
export default class SidebarItem extends Vue {

	children: Array<SidebarItem> = [];
	collapsed: boolean = true;
	providedLinkAdder: (item: SidebarItem) => {} = null;
	providedLinkRemover: (item: SidebarItem) => {} = null;

	@Prop({ type: Boolean, default: false })
	menu: boolean = false;

	@Prop({
		type: Object,
		default: new Link("", "", [])
	})
	link: Link;

	@Provide("addLink")
	addChild(item: SidebarItem) {
		const index = this.$slots.default.indexOf(item.$vnode);
		this.children.splice(index, 0, item);
	}

	@Provide("removeLink")
	removeChild(item: SidebarItem) {
		const tabs = this.children;
		const index = tabs.indexOf(item);
		tabs.splice(index, 1);
	}

	@Inject({ default: null })
	get addLink(): (item: SidebarItem) => {} {
		return this.providedLinkAdder;
	}
	set addLink(providedLinkAdder: (item: SidebarItem) => {}) {
		this.providedLinkAdder = providedLinkAdder;
	}

	@Inject({ default: null })
	get removeLink(): (item: SidebarItem) => {} {
		return this.providedLinkRemover;
	}
	set removeLink(providedLinkRemover: (item: SidebarItem) => {}) {
		this.providedLinkRemover = providedLinkRemover;
	}

	@Inject({ default: true })
	autoClose: boolean;


	get baseComponent(){
		return this.isMenu || this.link.isRoute ? "li" : "router-link";
	}

	get isMenu() {
		return this.children.length > 0 || this.menu === true;
	}

	get isActive() {
		if (this.$route && this.$route.path) {
			let matchingRoute = this.children.find((c: SidebarItem) =>
				this.$route.path.startsWith(c.link.path)
			);
			if (matchingRoute !== undefined) {
				return true;
			}
		}
		return false;
	}

	mounted() {
		if (this.addLink) {
			this.addLink(this);
		}
		if (this.link.collapsed !== undefined) {
			this.collapsed = this.link.collapsed;
		}
		if (this.isActive && this.isMenu) {
			this.collapsed = false;
		}
	}

	destroyed() {
		if (this.$el && this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el);
		}
		if (this.removeLink) {
			this.removeLink(this);
		}
	}

	elementType(link: Link, isParent: boolean = true) {
		if (link.isRoute === false) {
			return isParent ? "li" : "a";
		} else {
			return "router-link";
		}
	}

	linkAbbreviation(name: string) {
		const matches = name.match(/\b(\w)/g);
		return matches.join("");
	}

	linkClick() {
		if (
			this.autoClose &&
			(<any>this).$sidebar &&
			(<any>this).$sidebar.showSidebar === true
		) {
			(<any>this).$sidebar.displaySidebar(false);
		}
	}

	collapseMenu() {
		this.collapsed = !this.collapsed;
	}

	collapseSubMenu(link: Link) {
		link.collapsed = !link.collapsed;
	}

}

