





























































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Select, Option, Input } from 'element-ui';
import { Swatches } from 'vue-color';
import BaseForm from '@/components/BaseForm.vue';
import { Logger, handleError, ColorUtilities, TimeSpan } from '@/utilities';
import { LogLevel, VueColor } from '@/models';
import Card from '@/components/lbd/Cards/Card.vue';
import Checkbox from '@/components/lbd/Inputs/Checkbox.vue';
import fgInput from '@/components/lbd/Inputs/formGroupInput.vue';
import PrizeList from './PrizeList.vue';
import { IGame, Game, ISheetType as SheetType, IGridSize as GridSize, IGridType as GridType, Prize, IPrizePattern as PrizePattern, IPrizePatternGroup as PrizePatternGroup, DrawInfo } from '@/services/api';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';


@Component({
	name: 'GameForm',
	components: {
		Card,
		Checkbox,
		"el-select": Select,
		"el-option": Option,
		"el-input": Input,
		"fg-input": fgInput,
		'swatches-picker': Swatches,
		"prize-list": PrizeList,
		VueTimepicker
	}
})
export default class GameForm extends BaseForm {
	private sheetTypeIds: Array<number> = new Array<number>();					//Reactive property for item property that is not a value type (and are not automatically reactive).
	private color: VueColor = new VueColor();									//Reactive property for item property that is not a value type (and are not automatically reactive).
	private filteredSheetTypeList: Array<SheetType> = new Array<SheetType>();	//Reactive property for item property that is not a value type (and are not automatically reactive).
	private drawInfo: DrawInfo = new DrawInfo();
	private extraBallDrawProbability = new Array<number>();

	public created() {
		this.item = new Game();
		this.cloneSheetTypeList(null, null);
		if(this.item && (!this.item.sheetTypeIds || Array.isArray(this.item.sheetTypeIds))) this.item.sheetTypeIds = this.sheetTypeIds;
	}

	@Watch("sheetTypeList")
	private cloneSheetTypeList(val: Array<SheetType>, oldVal: Array<SheetType>): void {
		this.filteredSheetTypeList =  new Array<SheetType>(...this.sheetTypeList);
		Logger.log(LogLevel.Trace, "GameForm.cloneSheetTypeList() - Filtered list cloned to " + this.filteredSheetTypeList.length + " SheetTypes.");
		this.filterSheetTypeList(null, null);
	}

	@Watch("item")
	private filterSheetTypeList(val: Game, oldVal: Game): void {
		if(this.item.gridTypeId == 0) this.item.gridTypeId = undefined;
		if(!this.filteredSheetTypeList || !this.filteredSheetTypeList.length || this.filteredSheetTypeList.length <= 0 || !this.item.gridTypeId) return;
		this.filteredSheetTypeList = this.filteredSheetTypeList.filter((element: SheetType) => element.gridTypeId == this.item.gridTypeId);
		Logger.log(LogLevel.Trace, "GameForm.filterSheetTypeList() - Filtered the list down to " + this.filteredSheetTypeList.length + " SheetTypes.");
	}

	private addExtraBall(): void {
		this.extraBallDrawProbability.push(0);
	}

	public get title(): string {
		let title: string = this.isNew ? "New Game" : "Edit Game";
		return this.localize(title);
	}

//#region Overridable Form Life Cycle Methods: 
	//	loadItem(), itemReactivePropertyExtractor(), saveItem(), validateForm(), itemReactivePropertyInserter(), itemSavedCallback(), listRoute(), closeForm()

	protected get itemReactivePropertyExtractor(): (item: any) => void {
		return ((item: Game) => {
			this.drawInfo =  item.drawInfo;
			this.extraBallDrawProbability =  item.drawInfo.extraBallDrawProbability;
			this.sheetTypeIds =  item.sheetTypeIds;
			this.color =  ColorUtilities.argbToVueColor(item.color);
		});
	}

	protected get validateForm(): () => boolean {
		return (() => {
			//TODO: get all required fields and check their value
			return true;
		});
	}

	protected get itemReactivePropertyInserter(): (item: any) => void {
		return ((item: Game) => {
			item.drawInfo = this.drawInfo;
			item.drawInfo.extraBallDrawProbability = this.extraBallDrawProbability;
			item.sheetTypeIds = this.sheetTypeIds;
			item.color = ColorUtilities.toArgb(this.color.rgba);
		});
	}

//#endregion

	private getSheetTypeName(sheet: SheetType): string {
		let name: string = sheet.name;
		if(!sheet) return this.localize("undefined");
		let type: GridType = this.gridTypeList.find((item: GridType) => item.id == sheet.gridTypeId);
		if(!type) name += " (id: " + sheet.gridTypeId + ")";
		name +=  " / " + this.getGridTypeName(type) + ' - ';
		name += ' ' + this.localize("Price") + ': ' + sheet.price + ',-';
		return name;
	}

	private getGridTypeName(type: GridType): string {
		let name: string = "";
		if(!type) return name;
		if(!type || !type.gridSize) return name;
		let size: GridSize =  type.gridSize;
		if(size) name = this.numberOfBallsEnum[size.numberOfBalls] + " " + " (" + size.rows + " x " + size.columns + ")";
		if(type.freeplayFields && Array.isArray(type.freeplayFields)) name += " " + this.localize("with Freeplay");
		if(type.blanksPerRow && type.blanksPerRow > 0) name += " " + this.localize("with") + " " + type.blanksPerRow + " " +  this.localize("blanks / row");
		return name;
	}

	private pickColor(value: VueColor): void {
		this.item.color = ColorUtilities.toArgb(value.rgba);
		Logger.log(LogLevel.Trace, "GameForm.pickColor() - Picked: " + JSON.stringify(value));
	}

	private prizesChanged(value: Array<Prize>): void {
		this.item.prizes = value;
	}

	public get defaultPurchaseTime() : string {
		return TimeSpan.parse(this.item.defaultPurchaseTime || "00:00:00", "HH:mm:ss").toString("mm:ss");
	}
	public set defaultPurchaseTime(value : string) {
		this.item.defaultPurchaseTime = TimeSpan.parse(value || "00:00", "mm:ss").toString("HH:mm:ss");
	}

	private get normalDrawDelay(): number {
		return parseFloat(
			(this.drawInfo.normalDrawDelay + "")
				.split(":")
				.pop()
		);
	}
	private set normalDrawDelay(value: number) {
		this.drawInfo.normalDrawDelay = "00:00:" + ((value < 10) ? "0" + value: value);
	}

	private get firstDrawDelay(): number {
		return parseFloat(
			(this.drawInfo.firstDrawDelay + "")
				.split(":")
				.pop()
		);
	}
	private set firstDrawDelay(value: number) {
		this.drawInfo.firstDrawDelay = "00:00:" + ((value < 10) ? "0" + value: value);
	}

	private get lastDrawDelay(): number {
		return parseFloat(
			(this.drawInfo.lastDrawDelay + "")
				.split(":")
				.pop()
		);
	}
	private set lastDrawDelay(value: number) {
		this.drawInfo.lastDrawDelay = "00:00:" + ((value < 10) ? "0" + value: value);
	}

	private get extraBallDrawDelay(): number {
		return parseFloat(
			(this.drawInfo.extraBallDrawDelay + "")
				.split(":")
				.pop()
		);
	}
	private set extraBallDrawDelay(value: number) {
		this.drawInfo.extraBallDrawDelay = "00:00:" + ((value < 10) ? "0" + value: value);
	}

}
